<script setup lang="ts">
import { ref, nextTick, defineProps, computed } from 'vue'
import type { Ref, ComputedRef } from 'vue'
import gql from 'graphql-tag'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'
import Message from 'primevue/message'
import { email as emailValidator, required, minLength, maxLength, sameAs } from "@/utils/validators"
import { helpers } from '@vuelidate/validators'
import { useVuelidate } from "@vuelidate/core"
import { isBefore } from 'date-fns'

import { CryptoService } from '../../services/index'
import GuestLayout from '@/components/Layouts/Guest.vue'
import InputGroup from '@/components/Form/InputGroup.vue'
import AppLink from '@/components/AppLink.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'
import TermsCheckbox from '@/components/TermsCheckbox.vue'

const rules = {
    firstName: { required, maxLength: maxLength(256) },
    lastName: { required, maxLength: maxLength(256) },
    email: { required, emailValidator, maxLength: maxLength(256) },
    password: { required, minLength: minLength(8), maxLength: maxLength(128) },
    terms: { required, sameAs: helpers.withMessage('Das ist ein Pflichtfeld', sameAs(ref(true))) }
}

const props = defineProps({
    inviteToken:  String,
    emailPlaceholder:  String,
    organizationName: String
})

const success = ref(false)
const email = ref(props.emailPlaceholder)
const password: Ref<string> = ref('')
const firstName = ref(null)
const lastName = ref(null)
const terms = ref(false)
const privateKey: Ref<string | null> = ref(null)
const publicKey: Ref<string | null> = ref(null)
const iv: Ref<string | null> = ref(null)
const salt: Ref<string | null> = ref(null)
const isGeneratingKeyPair: Ref<boolean> = ref(false)
const v$ = useVuelidate(rules, { firstName, lastName, email, password, terms })

const loginUrl: ComputedRef<string> = computed((): string => props.inviteToken ? `/login?invite_token=${props.inviteToken}` : `/login`);

// Crypto
const cryptoService = new CryptoService()

const generateKeys = async (password: string) => {
    const { publicKeyJwk, privateKeyJwk } = await cryptoService.generateUserKeyPair()

    // Salt for AES key
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const iv = window.crypto.getRandomValues(new Uint8Array(12))

    // Generate a secure key off a password
    const key = await cryptoService.getKeyFromPassword(password)
    const aesKey = await cryptoService.getAESKeyFromPBKDF(key, salt)
    const encryptedPrivateKey = await cryptoService.encryptPrivateKey(privateKeyJwk, aesKey, iv)

    return {
        publicKey:btoa(JSON.stringify(publicKeyJwk)),
        privateKey: btoa(String.fromCharCode(...new Uint8Array(encryptedPrivateKey))),
        iv: btoa(String.fromCharCode(...new Uint8Array(iv))),
        salt: btoa(String.fromCharCode(...new Uint8Array(salt)))
    }
}

const beforeSubmit = async (mutate: Function) => {
    isGeneratingKeyPair.value = true
    const wallet = await generateKeys(password.value)
    privateKey.value = wallet.privateKey
    publicKey.value = wallet.publicKey
    iv.value = wallet.iv
    salt.value = wallet.salt

    await nextTick()
    isGeneratingKeyPair.value = false
    await mutate()
}

const mutation = gql`
    mutation register ($firstName: String!, $lastName: String!, $email: String!, $password: String!, $publicKey: String!, $privateKey: String!, $salt: String!, $iv: String! ) {
        register (input: { first_name: $firstName, last_name: $lastName, email: $email, password: $password, password_confirmation: $password, public_key: $publicKey, private_key: $privateKey, salt: $salt, iv: $iv }) {
            status
            message
            user {
                id
                first_name
                last_name
                email
            }
        }
    }
`

const onDone = ({ data: { register } }) => {
    // Attach invite token if available
    const locationHref = props.inviteToken ? `/?invite_token=${props.inviteToken}` : '/'

    if (register.status === 'success' ) {
        success.value = true
        setTimeout(() => document.location.href = locationHref)
    }
}

const trialPeriodLength = computed(() => {
    return isBefore(new Date(), new Date('2024-01-01')) ? '30 Tage' : '14 Tage'
})
</script>

<template>
    <GuestLayout size="max-w-5xl">
        <div class="sm:flex">
            <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0">
                <ApolloMutation
                    :mutation="mutation"
                    :variables="{ email, password, firstName, lastName, iv, salt, publicKey, privateKey }"
                    @done="onDone">
                    <template v-slot="{ mutate, loading, error }">
                        <ValidationMessage v-if="error" :response="error" />
                        <Message v-if="success" severity="success">{{
                            'Sie werden jeden Moment weitergeleitet...'
                        }}</Message>
                        <form method="post" autocomplete="on" @submit.prevent="beforeSubmit(mutate)">
                            <h3 class="text-2xl font-bold text-gray-600 text-center mb-4">Konto erstellen</h3>
                            <InputGroup class="sm:flex">
                                <div class="mb-4 sm:mb-0 sm:mr-2 sm:w-1/2">
                                    <InputText v-model="firstName" placeholder="Vorname" name="firstname" type="text" autofocus />
                                </div>
                                <div class="sm:ml-2 sm:w-1/2">
                                    <InputText v-model="lastName" placeholder="Nachname" name="lastname" type="text" />
                                </div>
                            </InputGroup>
                            <InputGroup>
                                <InputText v-model="email" placeholder="E-Mail" name="email" type="email" />
                            </InputGroup>
                            <InputGroup>
                                <Password v-model="password" autocomplete="off" placeholder="Passwort" name="password" :toggle-mask="true" />
                            </InputGroup>
                            <terms-checkbox v-model="terms" />

                            <Button :disabled="v$.$invalid" :loading="loading || isGeneratingKeyPair" type="submit" class="block w-full" label="Weiter" />
                        </form>
                    </template>
                </ApolloMutation>
                <p class="mt-8 text-gray-800 text-center">Sie haben bereits ein Konto? <AppLink :href="loginUrl">Anmelden</AppLink></p>
            </div>
            <div class="sm:w-1/2 sm:pl-4 prose prose-sm">
                <div
                    v-if="inviteToken"
                    class="p-4 bg-blue-100 font-bold text-blue-600">
                    Nach erfolgreicher Registrierung, werden Sie Teil des Beschäftigungsgebers von {{ organizationName }}.
                </div>

                <div v-else>
                    <h3>Entdecken Sie compentum kostenlos</h3>
                    <p>Testen Sie jetzt die Leistungsfähigkeit von compentum für {{ trialPeriodLength }} ganz unverbindlich und ohne Kosten.</p>
                </div>

                <h3>Sicherheit</h3>
                <p>Merken Sie sich Ihr Passwort und notieren Sie es bei Abschluss der Kontoeinrichtung im dafür vorgesehenen Feld im Emergency Kit.</p>
                <p>Ihr Passwort bildet das Fundament einer sicheren Ende-zu-Ende-Verschlüsselung. <a target="_blank" href="https://www.bsi.bund.de/DE/Themen/Verbraucherinnen-und-Verbraucher/Informationen-und-Empfehlungen/Cyber-Sicherheitsempfehlungen/Accountschutz/Sichere-Passwoerter-erstellen/sichere-passwoerter-erstellen_node.html">Wählen und speichern Sie daher Ihr Passwort sorgsam</a>.</p>

                <h3>Konto und Datenwiederherstellung</h3>
                <p>Bei Verlust Ihres Passworts oder der Anmeldeinformationen, gibt es aus Sicherheitsgründen nur limitierte Wiederherstellungsmöglichkeiten. Ziehen Sie daher die Nutzung eines Passwortmanagers in Betracht oder sichern Sie Ihre Zugänge an einem geschützten Platz.</p>
            </div>
        </div>
    </GuestLayout>
</template>
