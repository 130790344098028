<script setup lang="ts">
import type { Ref, ComputedRef } from 'vue'
import { ref, inject, computed } from 'vue'
import type { Healthcheck } from '@/types'
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import gql from 'graphql-tag'
import Button from 'primevue/button'

const toast = useToast()
const getHealthcheck: Function | undefined = inject('getHealthcheck')
const healthcheck: ComputedRef<Healthcheck | null> = computed(() =>
    getHealthcheck ? getHealthcheck() : null
)

const mutation = gql`
    mutation sendVerificationMail {
        sendVerificationMail  {
            status
            message
        }
    }
`

const onDone = ({ data: { sendVerificationMail } }) => {
    console.log('onDone', sendVerificationMail)
    if (sendVerificationMail.status === 'success') {
        console.log('toasting')
        toast.add({ severity: 'success', summary: 'Verschickt', detail: 'E-Mail wurde versendet', life: 3000 });
    }
}
</script>

<template>
    <div
        v-if="healthcheck && healthcheck.hasOwnProperty('email_verified') && !healthcheck.email_verified"
        class="bg-gradient-to-r from-yellow-200 to-yellow-300 border-yellow-200 text-yellow-700 text-xs md:text-sm font-medium flex items-center justify-center border-b px-4 py-1 md:py-2"
        >
        <Toast />
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4 mr-1 md:h-5 md:w-5 md:mr-2"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
            />
        </svg>

        E-Mail muss verifiziert werden.
        <ApolloMutation :mutation="mutation" @done="onDone">
            <template v-slot="{ mutate, loading, error }">
                <Button label="Verifizierung neu anfordern" :loading="loading" @click="mutate" severity="warning" size="small" class="ml-5" />
            </template>
        </ApolloMutation>
    </div>
</template>
