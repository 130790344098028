<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useQuery } from "@vue/apollo-composable"
import DataTable from 'primevue/datatable'
import Button from 'primevue/button'
import Column from 'primevue/column'
import Skeleton from 'primevue/skeleton'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'

import StatusBadge from '@/components/StatusBadge.vue'
import DeadlineStatus from '@/components/DeadlineStatus.vue'
import StatusDropdown from '@/components/StatusDropdown.vue'
import { REPORT_QUERY } from '@/containers/Report/queries'
import {
    dataTableFirst,
    paginationFirst,
    searchTerm,
    statuses,
    filters,
    sortField,
    sortOrder,
    paginationPage,
    buildQueryVariables,
    clearFilter,
    onPage,
} from '@/containers/Report/ReportIndexState'

const router = useRouter()

watch(searchTerm, (value) => onPage(fetchMore))

const formatDate = (value) => {
    return new Date(value).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
};

const onClickRow = (slotProps) => {
    router.push(`/reports/${slotProps.data.id}`)
}

const props = defineProps({
    portal: {
        type: String,
        default: ''
    }
})

watch(() => props.portal, (val) => {
    let queryVars = buildQueryVariables()
    if (val) {
        queryVars = {
            ...queryVars,
            ...{ portal_id: props.portal }
        }
    }

    refetch({ ...queryVars, first: 5 })
})

onMounted(() => {
    const paginatorInfo = result?.value?.reports?.paginatorInfo
    if (paginatorInfo && paginatorInfo.currentPage) {
        dataTableFirst.value = (paginationFirst.value * paginatorInfo.currentPage) - paginationFirst.value
    }
})

let queryVars = buildQueryVariables()

const { result, loading, fetchMore, refetch } = useQuery(REPORT_QUERY, { ...queryVars, first: 5 })
</script>

<template>
    <div class="w-full">
        <h3 class="text-left text-base text-slate-400 font-medium mb-8">Ablaufende Fristen</h3>
        <template v-if="result && result.reports">
            <DataTable v-model:first.sync="dataTableFirst" v-model:filters="filters" v-model:sort-field="sortField"
                v-model:sort-order="sortOrder" :rows="5" :totalRecords="result.reports.paginatorInfo.total"
                :loading="loading" :lazy="true" :value="result.reports.data" :paginator="false" :rowHover="true"
                :scrollable="true" scrollDirection="horizontal" @row-click="onClickRow" @filter="() => onPage(fetchMore)"
                @sort="() => onPage(fetchMore)">
                <Column field="subject" header="Nummer" style="min-width:16rem">
                    <template #body="slotProps">
                        <router-link :to="`/reports/${slotProps.data.id}`"
                            class="font-medium cursor-pointer hover:text-primary-600">
                            {{ `#${slotProps.data.identifier}` }}
                            <span class="text-slate-400 ml-1">
                                (<span class="font-semibold">{{ slotProps.data.messages.count }}</span>)
                            </span>
                        </router-link>
                    </template>
                </Column>
                <Column field="category" header="Kategorie">
                    <template #body="slotProps">
                        {{ slotProps.data.category ? slotProps.data.category.name : '-' }}
                    </template>
                </Column>
                <Column field="deadline" header="Frist">
                    <template #body="slotProps">
                        <DeadlineStatus :remaining-days="slotProps.data.deadline.remaining_days"
                            :type="slotProps.data.deadline.type" />
                    </template>
                </Column>
                <Column field="created_at" header="Eingegangen am" :sortable="false" :show-filter-menu="false"
                    style="min-width:12rem">
                    <template #body="slotProps">
                        {{ formatDate(slotProps.data.created_at) }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Calendar v-model="filterModel.value" @date-select="filterCallback()" placeholder="Zeitraum"
                            :date="new Date()" view="month" dateFormat="mm.yy" />
                    </template>
                </Column>
                <Column field="portal.name" header="Meldeportal" :show-filter-menu="false">
                    <template #body="slotProps">
                        {{ slotProps.data.portal.name }}
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <Dropdown v-model="filterModel.value" optionLabel="name" optionValue="id" :options="result.portals"
                            :show-clear="true" placeholder="Alle Meldeportale" class="p-column-filter"
                            @change="filterCallback()" />
                    </template>
                </Column>
                <Column field="status" header="Status" :sortable="false" :show-filter-menu="false" style="min-width:12rem">
                    <template #body="{ data }">
                        <StatusBadge :type="data.status" />
                    </template>
                    <template #filter="{ filterModel, filterCallback }">
                        <StatusDropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses"
                            placeholder="Alle offenen" class="p-column-filter" :show-clear="true" />
                    </template>
                </Column>
                <template #empty>
                    <p class="text-center text-slate-500">Keine Meldungen vorhanden.</p>
                </template>
            </DataTable>
        </template>
        <DataTable v-else :value="[
            { subject: null, status: null, created_at: null, portal: null },
            { subject: null, status: null, created_at: null, portal: null },
            { subject: null, status: null, created_at: null, portal: null }
        ]">
            <Column field="status" header="Status">
                <template #body>
                    <Skeleton></Skeleton>
                </template>
            </Column>
            <Column field="subject" header="Betreff">
                <template #body>
                    <Skeleton></Skeleton>
                </template>
            </Column>
            <Column field="deadline" header="Frist">
                <template #body>
                    <Skeleton></Skeleton>
                </template>
            </Column>
            <Column field="created_at" header="Eingegangen am">
                <template #body>
                    <Skeleton></Skeleton>
                </template>
            </Column>
            <Column field="portal" header="Meldeportal">
                <template #body>
                    <Skeleton></Skeleton>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
