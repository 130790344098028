<script setup lang="ts">
import type { Ref } from 'vue'

import { ref, defineEmits, nextTick } from 'vue'
import gql from 'graphql-tag'
import Toast from 'primevue/toast'
import Dropdown from 'primevue/dropdown'
import { useToast } from 'primevue/usetoast'

import ValidationMessage from '@/components/ValidationMessage.vue'

interface ReportCategory {
    id: string,
    name: string
}

const props = defineProps<{
    id?: string,
    reportId: string,
    value: ReportCategory | null
}>()

const selectedCategory: Ref<String | null> = ref(props.value?.id ?? null)
const emit = defineEmits(['done'])
const toast = useToast()

const onDone = () => {
    toast.add({
        severity:'success',
        summary: 'Erfolgreich gespeichert',
        life: 3000
    });
    emit('done')
}

const onCategoriesLoaded = (data) => {
    // Remove not visible categories from list
    let updated = data.categories.data.filter((category) => category.is_visible || category.id === selectedCategory.value)

    data = {
        ...data,
        categories: {
            ...data.categories,
            data: updated
        }
    }

    return data
}

defineExpose(props)
</script>

<template>
    <ApolloQuery :query="gql => gql`query categories { categories(first: 999, with_hidden: true) { data { id, name, is_visible } }}`" :update="onCategoriesLoaded">
        <template v-slot="{ result: { data }, isLoading }">
            <ApolloMutation
                :mutation="gql`
                    mutation updateReportCategory ($id: ID!, $category_id: ID!) {
                        updateReportCategory (input: { id: $id, category_id: $category_id }) {
                            id
                            category {
                                id
                                name
                            }
                        }
                    }
                `"
                :variables="{
                    id: reportId,
                    category_id: selectedCategory
                }"
                @done="onDone">
                <template v-slot="{ mutate, loading, error }">
                    <ValidationMessage v-if="error" :response="error" :toast="true" />
                    <Dropdown
                        v-model="selectedCategory"
                        :filter="true"
                        :id="id"
                        :options="data?.categories.data"
                        :loading="loading || isLoading > 0"
                        optionLabel="name"
                        optionValue="id"
                        :optionDisabled="(category) => category.id !== selectedCategory && !category.is_visible"
                        placeholder="Kategorie wählen"
                        class="w-full"
                        @change="async () => {
                            await nextTick()
                            await mutate()
                        }"
                    />
                </template>
            </ApolloMutation>
        </template>
    </ApolloQuery>
    <Toast position="bottom-right" />
</template>
