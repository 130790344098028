<script setup lang="ts">
import type { Ref, ComputedRef } from 'vue'
import type { Healthcheck } from '@/types'

import { ref, onBeforeUnmount, inject, computed } from 'vue'
import { useQuery } from "@vue/apollo-composable"
import gql from 'graphql-tag'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Message from 'primevue/message'
import Button from 'primevue/button'
import InputSwitch from 'primevue/inputswitch'
import Checkbox from 'primevue/checkbox'

import RecryptDialog from '@/containers/Recrypt/RecryptDialog.vue'
import UpdateUserRole from '@/containers/User/UpdateUserRole.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'
import AppConfig from '@/config'

const getHealthcheck: Function | undefined = inject('getHealthcheck')
const healthcheck: ComputedRef<Healthcheck | null> = computed(() => getHealthcheck ? getHealthcheck() : null)
const maxUserLimit = computed(() => healthcheck.value?.user_count_limit || 3)
const currentUserLimit = computed(() => healthcheck.value?.user_and_invite_count || 1)
const defaultUserLimit = computed(() => healthcheck.value?.user_count_limit_default || 3)
const canDowngradePlan = computed(() => currentUserLimit.value > defaultUserLimit.value)
const refreshHealthcheck: Function | undefined = inject('refreshHealthcheck')
const isSaas: Ref<boolean> = ref(AppConfig.isSaas)

const toast = useToast()

const query = gql`
    query members {
        members { id, full_name, email, email_verified_at, is_me, inactive, role { id, name }}
    }
`

const userToBeDeleted: Ref<any> = ref(null)
const userToBeDisabled: Ref<any> = ref(null)
const downgradePlan: Ref<boolean> = ref(false)
const showRecryptModal = ref(false)

const { result, loading, error, refetch } = useQuery(query)

const showDeleteConfirmation = (user) => {
    userToBeDeleted.value = user
    toast.add({
        severity: 'warn',
        summary: `Sind Sie sicher, dass Sie ${userToBeDeleted.value.full_name} dauerhaft aus dem Beschäftigungsgeber entfernen möchten?`,
        group: 'rm-user',
        closable: false,
    });
}

const showDisableConfirmation = (user) => {
    userToBeDisabled.value = user
    toast.removeGroup('disable-user')
    toast.add({
        severity: 'warn',
        summary: !userToBeDisabled.value.inactive
            ? `Sind Sie sicher, dass Sie ${userToBeDisabled.value.full_name} den Zugriff auf den Beschäftigungsgeber entziehen wollen? Sie können den Zugriff jederzeit wiederherstellen.`
            : `Sind Sie sicher, dass Sie ${userToBeDisabled.value.full_name} den Zugriff auf den Beschäftigungsgeber wiederherstellen wollen?`,
        group: 'disable-user',
        closable: false,
    });
}

const onRejectDeletingUser = () => {
    userToBeDeleted.value = null
    downgradePlan.value = false
    toast.removeGroup('rm-user')
}

const onSuccessfullyDeletingUser = () => {
    toast.removeGroup('rm-user')
    userToBeDeleted.value = null
    downgradePlan.value = false
    showRecryptModal.value = true
}

const onRejectDisablingUser = () => {
    userToBeDisabled.value = null
    toast.removeGroup('disable-user')
}

const onSuccessfullyDisablingUser = () => {
    toast.removeGroup('disable-user')
    userToBeDisabled.value = null
}

const onChangeUserRole = async () => {
    await refetch()
    showRecryptModal.value = true
}

onBeforeUnmount(() => {
    onRejectDeletingUser()
    onRejectDisablingUser()
})
</script>

<template>
     <div class="prose mb-8">
        <p>Sie haben <b>{{ currentUserLimit }}</b> von <b>{{  maxUserLimit }}</b> Benutzern eingeladen.</p>
    </div>
    <Message v-if="error" severity="error">There was an error retrieving the invite data.</Message>
    <div class="mb-12 mt-8">
        <DataTable :value="result?.members" :loading="loading">
            <Column field="full_name" header="Name"></Column>
            <Column field="email" header="E-Mail">
                <template #body="slotProps">
                    <div class="flex items-center">
                        <span
                            v-if="slotProps.data.email_verified_at"
                            v-tooltip.right="'E-Mail-Adresse wurde verifiziert'"
                            class="text-green-600 mr-1">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </span>
                        <span
                            v-else
                            class="text-yellow-600 mr-1"
                            v-tooltip.right="'E-Mail-Adresse wurde noch nicht verifiziert'">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                            </svg>
                        </span>
                        {{ slotProps.data.email }}
                    </div>
                </template>
            </Column>
            <Column field="email" header="Rolle">
                <template #body="slotProps">
                    <UpdateUserRole
                        v-if="!slotProps.data.is_me"
                        :current-role="slotProps.data.role"
                        :user-id="slotProps.data.id"
                        :disabled="slotProps.data.inactive"
                        @done="onChangeUserRole" />
                    <span v-else>
                        {{  slotProps.data?.role?.name }}
                    </span>
                </template>
            </Column>
            <Column field="inactive" header="Zugriff gesperrt" bodyStyle="text-align:right" headerClass="column-header-right" style="width:12%;">
                <template #body="{ data }">
                    <div v-tooltip.bottom="{ value: 'Sie können den Zugriff von Inhabern nicht sperren.', disabled: data.role.id !== 'owner' }">
                        <InputSwitch
                            :modelValue="data.inactive"
                            :disabled="data.is_me || data.role.id === 'owner'"
                            @click="() => showDisableConfirmation(data)" />
                    </div>
                </template>
            </Column>
            <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                <template #body="slotProps">
                    <Button
                        type="button"
                        icon="pi pi-trash"
                        class="p-button-rounded p-button-text"
                        v-tooltip.bottom="'Benutzer löschen'"
                        severity="danger"
                        :disabled="slotProps.data.is_me"
                        @click="() => showDeleteConfirmation(slotProps.data)" />
                </template>
            </Column>
        </DataTable>

        <Toast position="bottom-center" group="rm-user">
            <template #message="slotProps">
                <div class="flex flex-col w-full">
                    <div class="text-center">
                        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                        <h4>{{ slotProps.message.summary }}</h4>
                        <p>{{ slotProps.message.detail }}</p>
                        <div class="flex items-center flex-wrap text-left mt-4" v-if="isSaas && canDowngradePlan">
                            <Checkbox v-model="downgradePlan" inputId="downgrade" binary />
                            <label for="downgrade" class="ml-2 text-left basis-5/6">Gebuchten Benutzerplatz aus dem Abonnement entfernen</label>
                            <small class="mt-2 basis-full">Die Änderungen sind nach dem Herunterstufen des Zusatztarifs sofort wirksam. Die restliche Laufzeit wird in Form eines Guthabens Ihrem Kundenkonto gutgeschrieben. Das Guthaben wird bei der nächsten Rechnung automatisch angewendet.</small>
                        </div>
                    </div>
                    <ApolloMutation
                        :mutation="isSaas
                            ? gql`
                                mutation removeMember ($id: ID!, $downgradePlan: Boolean) {
                                    removeMember (input: { id: $id, downgradePlan: $downgradePlan }) { user { id } }
                                }
                            `
                            : gql`
                                mutation removeMember ($id: ID!) {
                                    removeMember (input: { id: $id }) { user { id } }
                                }
                            `
                        "
                        :variables="{ id: userToBeDeleted.id, downgradePlan }"
                        :refetchQueries="() => [
                            { query: gql`query members { members { id, full_name, email, email_verified_at, is_me, inactive, role { id, name }}}` },
                            { query: gql`query organization($id: ID!) { organization (id: $id) { id, users { id, full_name, email, email_verified_at }}}`, variables: { id: AppConfig.tenantSlug } }
                        ]"
                        @done="async () => {
                            refreshHealthcheck?.()
                            await refetch()
                            onSuccessfullyDeletingUser()
                        }"
                    >
                        <template v-slot="{ mutate, loading, error }">
                            <ValidationMessage v-if="error" :response="error" />
                            <div class="flex w-full mt-4 space-x-2">
                                <div class="w-1/2">
                                    <Button
                                        class="p-button-secondary w-full block"
                                        label="Nein"
                                        @click="onRejectDeletingUser" />
                                </div>
                                <div class="w-1/2">
                                    <Button
                                        class="p-button-danger w-full block"
                                        label="Ja"
                                        :loading="loading"
                                        @click="mutate" />
                                </div>
                            </div>
                        </template>
                    </ApolloMutation>
                </div>
            </template>
        </Toast>

        <Toast position="bottom-center" group="disable-user">
            <template #message="slotProps">
                <div class="flex flex-col w-full">
                    <div class="text-center">
                        <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                        <h4>{{ slotProps.message.summary }}</h4>
                        <p>{{ slotProps.message.detail }}</p>
                    </div>
                    <ApolloMutation
                        :mutation="gql`
                            mutation updateMemberInactive ($id: ID!, $inactive: Boolean!) {
                                updateMemberInactive (input: { id: $id, inactive: $inactive }) { user { id, inactive } }
                            }
                        `"
                        :variables="{ id: userToBeDisabled.id, inactive: !userToBeDisabled.inactive  }"
                        @done="async () => {
                            await refetch()
                            onSuccessfullyDisablingUser()
                        }"
                    >
                        <template v-slot="{ mutate, loading, error }">
                            <ValidationMessage v-if="error" :response="error" />
                            <div class="flex w-full mt-4 space-x-2">
                                <div class="w-1/2">
                                    <Button
                                        class="p-button-secondary w-full block"
                                        label="Nein"
                                        @click="onRejectDisablingUser" />
                                </div>
                                <div class="w-1/2">
                                    <Button
                                        class="p-button-danger w-full block"
                                        label="Ja"
                                        :loading="loading"
                                        @click="mutate" />
                                </div>
                            </div>
                        </template>
                    </ApolloMutation>
                </div>
            </template>
        </Toast>

        <RecryptDialog v-if="showRecryptModal" v-model="showRecryptModal" />
    </div>
</template>
