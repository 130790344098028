<script setup lang="ts">
import type { Ref } from 'vue'
import gql from 'graphql-tag'
import { ref, defineProps } from 'vue'

import AppFrame from '@/components/AppFrame.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'
import Password from 'primevue/password'
import Dialog from 'primevue/dialog';
import Button from 'primevue/button'
import Message from 'primevue/message';

const errorMessages: Ref<any> = ref(null)
const password: Ref<string> = ref('')
const visible: Ref<boolean> = ref(false)
const success: Ref<boolean> = ref(false)

const props = defineProps({
    userResult: {
        type: Object,
        required: true,
        default: () => ({})
    }
})

const mutation = gql`
    mutation deleteMe($password: String!) {
        deleteMe(input: { password: $password }) {
            id
        }
    }
`

const confirmDelete = () => {
    visible.value = true
}

const cancel = () => {
    visible.value = false
}

const onDone = (result) => {
    if (result) {
        window.location.href = '/user-removed'
    }
}
</script>

<template>
    <AppFrame :full-width="true">
        <h3 class="text-lg font-bold text-gray-600 mb-4">Benutzerkonto löschen</h3>
        <ValidationMessage v-if="errorMessages" :response="errorMessages" />
           <div class="sm:flex">
                <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0">
                    <Button icon="pi pi-trash" @click="confirmDelete" label="Mein Benutzerkonto löschen" severity="danger" />
                    <Dialog
                        v-model:visible="visible"
                        :header="`Benutzerkonto löschen`"
                        position="top"
                        modal
                        :dismissable-mask="true"
                        :close-on-escape="true"
                        :draggable="false"
                        :style="{ width: '700px' }"
                    >
                        <ApolloMutation
                            :mutation="mutation"
                            :variables="{ password }"
                            @done="onDone"
                        >
                            <template v-slot="{ mutate, loading, error }">
                                <Message v-if="error" severity="error">{{ error.message }}</Message>
                                <form method="post" autocomplete="on" @submit.prevent="mutate">
                                    <p>Bitte geben Sie zum Bestätigen Ihr aktuelles Benutzerpasswort ein.</p>
                                    <div class="p-inputgroup my-4">
                                        <Password v-model="password" placeholder="Passwort" :feedback="false" />
                                    </div>

                                    <Button type="submit" :loading="loading" class="mr-2">Endgültig löschen</Button>
                                    <i class="mx-2"></i>
                                    <Button @click="cancel">Abbrechen</Button>
                                </form>
                            </template>
                        </ApolloMutation>
                    </Dialog>
                </div>
                <div class="sm:w-1/2 sm:pl-4 prose prose-sm">
                    <h3>Sicherheit</h3>
                    <p>Sie bekommen zur Sicherheit eine Bestätigungs-E-Mail zugeschickt.</p>
                    <h3>Beschäftigungsgeber</h3>
                    <p>Sie werden nach dem Löschen automatisch von allen Beschäftigungsgebern entfernt.</p>
                </div>
           </div>
    </AppFrame>
</template>
