<script setup lang="ts">
import PortalCreate from '@/containers/Portal/PortalCreate.vue'
import gql from 'graphql-tag'
import AppFrame from '@/components/AppFrame.vue'
import { useRoute } from 'vue-router'
import { useQuery } from "@vue/apollo-composable"
import Skeleton from 'primevue/skeleton'

const route = useRoute()

const portalQuery = gql`
    query portal ($id: ID!) {
        portal (id: $id) {
            id, url, name, intro_text, legal_notice, privacy_policy, confirm_message, users { id, full_name }, theme { background_color, button_color, logo { filename, url } }
        }
    }
`

const { result, loading, error, refetch: refetchReport } = useQuery(portalQuery, {
    id: route.params.id
})
</script>

<template>
    <AppFrame>
        <div v-if="loading">
            <Skeleton class="mb-8" />
            <Skeleton class="mb-2" width="40%" />
            <Skeleton class="mb-2" height="4rem" width="40%" />
        </div>
        <PortalCreate
            v-if="result && result.portal"
            :portal="result.portal" />
    </AppFrame>
</template>
