import { PDFDocument, rgb, StandardFonts, layoutMultilineText, TextAlignment } from 'pdf-lib'
// import fontkit from '@pdf-lib/fontkit'

export interface EmergencyKitOptions {
    companyUrl: string
    email: string
    companyMasterPassword: string
}

export interface ReportKitOptions {
    portalUrl: string
    reportKey: string
}

export interface ReportDataPDF {
    messages: Array<any>
    contact: Record<any, any> | null
}

export interface RecoveryCodes {
    codes: Array<string>
}

export const saveEmergencyKit = async (pdfUrl: string, options: EmergencyKitOptions) => {
    const existingBytes = await fetch(pdfUrl).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(existingBytes)
    const compentumFont = await pdfDoc.embedFont(StandardFonts.Courier)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const { height } = firstPage.getSize()

    const { companyUrl, email, companyMasterPassword } = options

    const fontSize = 11
    const fontColor = rgb(0, 0, 0)
    const xMargin = 90
    const fieldPadding = 43

    const paddingUrl = 192 + fontSize
    const paddingEmail = paddingUrl + fontSize + fieldPadding
    // Ein feld wird übersprungen darum * 2 und - 3 weil die Abstände im Template nicht gleich sind
    const paddingPassword = paddingEmail - 1 + (fontSize + fieldPadding) * 2

    firstPage.drawText(companyUrl, {
        x: xMargin,
        y: height - paddingUrl,
        size: fontSize,
        font: compentumFont,
        color: fontColor
    })

    firstPage.drawText(email, {
        x: xMargin,
        y: height - paddingEmail,
        size: fontSize,
        font: compentumFont,
        color: fontColor
    })

    firstPage.drawText(companyMasterPassword, {
        x: xMargin,
        y: height - paddingPassword,
        size: fontSize,
        font: compentumFont,
        color: fontColor
    })

    return await pdfDoc.save()
}

export const saveReportKit = async (pdfUrl: string, options: ReportKitOptions) => {
    const existingBytes = await fetch(pdfUrl).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(existingBytes)
    const compentumFont = await pdfDoc.embedFont(StandardFonts.Courier)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const { height } = firstPage.getSize()

    const { portalUrl, reportKey } = options

    const fontSize = 11
    const fontColor = rgb(0, 0, 0)
    const xMargin = 90
    const fieldPadding = 43

    const paddingUrl = 152 + fontSize
    const paddingKey = paddingUrl + fontSize + fieldPadding

    firstPage.drawText(portalUrl, {
        x: xMargin,
        y: height - paddingUrl,
        size: fontSize,
        font: compentumFont,
        color: fontColor
    })

    firstPage.drawText(reportKey, {
        x: xMargin,
        y: height - paddingKey,
        size: fontSize,
        font: compentumFont,
        color: fontColor
    })

    return await pdfDoc.save()

}

export const saveReport = async (pdf, pdfUrl: string, reportData: ReportDataPDF) => {
    // Whole template
    const existingBytes = await fetch(pdfUrl).then(res => res.arrayBuffer())
    // Prefilled first page
    const filledFirstPage = pdf

    // Deckblatt
    const pdfDoc = await PDFDocument.load(filledFirstPage)
    // Whole Template
    const pdfTemplate = await PDFDocument.load(existingBytes)
    const textSize = 10

    const compentumFont = await pdfDoc.embedFont(StandardFonts.Courier)
    // pdfDoc.registerFontkit(fontkit)
    // const url = `/public/fonts/IBMPlexSans-Regular.ttf`
    // console.log(AppConfig)
    // const fontBytes = await fetch(url).then(res => res.arrayBuffer())
    // console.log('font bytes', fontBytes)
    // const compentumFont = await pdfDoc.embedFont(fontBytes)

    const pages = pdfDoc.getPages()
    const startPage = pages[0]
    // https://github.com/Hopding/pdf-lib/issues/72#issuecomment-823608210

    if (reportData.contact) {
        const { height } = startPage.getSize()
        const xMargin = 60
        const xFieldPadding = 240
        const yFieldPadding = 50
        const paddingContact = height / 2  + textSize + 110
        const { email, firstName, lastName, phone } = reportData.contact

        startPage.drawText(firstName, {
            x: xMargin,
            y: height - paddingContact,
            size: textSize,
            font: compentumFont
        })
        startPage.drawText(lastName, {
            x: xMargin + xFieldPadding,
            y: height - paddingContact,
            size: textSize,
            font: compentumFont
        })
        startPage.drawText(email, {
            x: xMargin,
            y: height - paddingContact - yFieldPadding,
            size: textSize,
            font: compentumFont
        })
        if (phone) {
            startPage.drawText(phone, {
                x: xMargin + xFieldPadding,
                y: height - paddingContact - yFieldPadding,
                size: textSize,
                font: compentumFont
            })
        }
    } else {
        const { height } = startPage.getSize()
        const xMargin = 63
        const checkboxMargin = height / 2 + 80
        startPage.drawText('x', {
            x: xMargin,
            y: height - checkboxMargin,
            size: textSize,
            font: compentumFont
        })
        // startPage.drawCircle({
        //     x: xMargin,
        //     y: height - checkboxMargin,
        //     size: 3,
        //     color: rgb(0.231, 0.439, 0.922)
        // })
    }

    const [messageTemplate] = await pdfDoc.copyPages(pdfTemplate, [1])
    let messagePage = pdfDoc.addPage(messageTemplate)

    const { height, width } = messagePage.getSize()
    let startingPosition = height - 150
    const xMargin = 60

    for (let messageObject of reportData.messages) {
        if (startingPosition < 50) {
            const [messageTemplate] = await pdfDoc.copyPages(pdfTemplate, [1])
            messagePage = pdfDoc.addPage(messageTemplate)
            // reset starting position
            startingPosition = height - 150
        }


        const {message, author, internal, time } = messageObject
        const lineHeight = textSize * 2

        messagePage.drawText(time, {
            x: xMargin,
            y: startingPosition,
            size: textSize,
            font: compentumFont,
            color: rgb(0.231, 0.439, 0.922)
        })

        if (internal) {
            const internalNote = 'Interne Notiz'
            const textWidth = compentumFont.widthOfTextAtSize(internalNote, textSize)
            messagePage.drawText(internalNote, {
                x: width - textWidth - xMargin,
                y: startingPosition,
                size: textSize,
                font: compentumFont,
                color: rgb(0.231, 0.439, 0.922)
            })
        }

        startingPosition -= lineHeight
        messagePage.drawText(author, {
            x: xMargin,
            y: startingPosition,
            size: textSize,
            font: compentumFont,
            color: rgb(0.231, 0.439, 0.922)
        })
        startingPosition -= lineHeight

        const multiText = layoutMultilineText(message, {
            alignment: TextAlignment.Left,
            font: compentumFont,
            fontSize: textSize,
            bounds: { width: width - (xMargin * 2), height: 1000, x: 0, y: 0 }
        })

        for (let i = 0; i < multiText.lines.length; i++) {
            if (startingPosition < 50) {
                const [messageTemplate] = await pdfDoc.copyPages(pdfTemplate, [1])
                messagePage = pdfDoc.addPage(messageTemplate)
                // reset starting position
                startingPosition = height - 150
            }


            messagePage.drawText(multiText.lines[i].text, {
                x: xMargin,
                y: startingPosition,
                size: textSize,
                font: compentumFont
            })
            startingPosition -= lineHeight
        }

        messagePage.drawLine({
            start: { x: xMargin, y: startingPosition },
            end: { x: width - xMargin, y: startingPosition },
            thickness: 2,
            color: rgb(0.231, 0.439, 0.922)
        })
        startingPosition -= lineHeight
    }


    return await pdfDoc.save()
}

export const saveRecoveryCodes = async (pdfUrl: string, options: RecoveryCodes) => {
    const existingBytes = await fetch(pdfUrl).then(res => res.arrayBuffer())
    const pdfDoc = await PDFDocument.load(existingBytes)
    const compentumFont = await pdfDoc.embedFont(StandardFonts.Courier)
    const pages = pdfDoc.getPages()
    const firstPage = pages[0]
    const { height } = firstPage.getSize()
    const { codes } = options

    const fontSize = 11
    const fontColor = rgb(0, 0, 0)
    const xMargin = 95
    const topPadding = 87
    const lineHeight = fontSize * 2
    const fieldPadding = 10 + lineHeight
    let yPosition = height - topPadding
    
    for (let code of codes) {
        firstPage.drawText(code, {
            x: xMargin,
            y: yPosition,
            size: fontSize,
            font: compentumFont,
            color: fontColor
        })
        yPosition -= fieldPadding
    }

    return await pdfDoc.save()
}
