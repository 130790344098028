<script setup lang="ts">
import type { Ref } from 'vue'
import { defineProps, ref, defineEmits } from 'vue'
import Skeleton from 'primevue/skeleton'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'

import RecryptReportsService from '@/containers/Recrypt/RecryptReportsService.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'
import EmptyPortal from '@/containers/Recrypt/EmptyPortal.vue'

const props = defineProps(['portalId', 'title', 'currentUser'])
const currentPage: Ref<number> = ref(1)
const allReportsFetched: Ref<boolean> = ref(false)
const reports: Ref<Array<any>> = ref([])
const numberOfResults: Ref<number | null> = ref(null)
const errorCount: Ref<number> = ref(0)

const emit = defineEmits(['finished'])

defineExpose(props)

const { result, loading, error, refetch, onResult } = useQuery(
    gql`
        query reportsByPortal ($portalId: ID!, $status: [ReportStatus!]!, $page: Int) {
            reportsByPortal(portal_id: $portalId, status: $status, page: $page) {
                data {id, status, report_iv, access_list, share_keys, share_keys_dirty}
                paginatorInfo {currentPage, hasMorePages, total}
            }
        }
    `,
    {
        portalId: props.portalId,
        status: ['NEW', 'RECEIVED', 'UNDER_REVIEW', 'AWAITING_FEEDBACK', 'COMPLETED', 'SUSPENDED', 'MARKED_FOR_DELETION'],
        page: currentPage
    },
    () => ({ fetchPolicy: 'network-only' })
    ,
)

onResult(async result => {
    if (result.loading) return

    numberOfResults.value = result.data.reportsByPortal.paginatorInfo.total
    reports.value = [...reports.value, ...result.data.reportsByPortal.data]

    if (result.data.reportsByPortal.paginatorInfo.hasMorePages) {
        currentPage.value += 1
        // refetch()
    } else {
        allReportsFetched.value = true
    }
})

const onError = (count) => {
    errorCount.value = count
}
</script>

<template>
    <div class="mb-8">
        <h3 class="mb-2 text-lg font-bold">
            {{ title }}
            <span v-if="numberOfResults" class="text-gray-500 font-normal">({{ numberOfResults }})</span>
        </h3>
        <div v-if="errorCount > 0" class="text-red-500 font-normal mb-3 -mt-1 flex items-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 mr-1">
                <path fill-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495zM10 5a.75.75 0 01.75.75v3.5a.75.75 0 01-1.5 0v-3.5A.75.75 0 0110 5zm0 9a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
            </svg>
            <span><span class="font-bold">{{ errorCount }} Meldungen</span> konnten nicht neuverschlüsselt werden.</span>
        </div>
        <div>
            <div v-if="loading">
                <Skeleton width="100" class="h-6" />
            </div>
            <ValidationMessage v-else-if="error" :response="error" />
            <RecryptReportsService
                v-else-if="reports && reports.length > 0 && allReportsFetched"
                :reports="reports"
                :current-user="currentUser"
                @error="onError"
                @finished="() => emit('finished')" />
            <EmptyPortal v-else @finished="() => emit('finished')"/>
        </div>
    </div>
</template>
