<script setup lang="ts">
import type { Ref, ComputedRef } from 'vue'
import type { Healthcheck } from '@/types'
import { computed, ref, watch, inject } from 'vue'
import { useRouter } from 'vue-router'
import gql from 'graphql-tag'
import { useQuery } from '@vue/apollo-composable'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Toast from 'primevue/toast'
import Button from 'primevue/button'
import { useToast } from 'primevue/usetoast'
import ValidationMessage from '@/components/ValidationMessage.vue'
import Message from 'primevue/message';
import Checkbox from 'primevue/checkbox'

import AppConfig from '@/config'
import { REPORT_QUERY } from '../Report/queries'
import { buildQueryVariables } from '../Report/ReportIndexState'

interface portal {
    id: string
}

const toast = useToast()
const emit = defineEmits(['limit:reached'])

const props = defineProps<{ portalLimit: Number }>()
defineExpose(props)

const getHealthcheck: Function | undefined = inject('getHealthcheck')
const refreshHealthcheck: Function | undefined = inject('refreshHealthcheck')

const deletePortal: Ref<any> = ref(null)
const downgradePlan: Ref<boolean> = ref(false)
const currentUserRole = ref(AppConfig.currentUserRole)
const hasPermission = computed(() => currentUserRole.value === 'owner')

const healthcheck: ComputedRef<Healthcheck | null> = computed(() => getHealthcheck ? getHealthcheck() : null)
const portalLimit = computed(() => healthcheck.value?.portal_count_limit || 3)
const defaultPortalLimit = computed(() => healthcheck.value?.portal_count_limit_default || 3)
const canDowngradePlan = computed(() => portalLimit.value > defaultPortalLimit.value)
const isSaas: Ref<boolean> = ref(AppConfig.isSaas)

const query = gql`
    query portals {
        portals(getAll: true) {
            id
            url
            name
            reports_count
        }
    }
`

const deleteMutation = isSaas.value
    ? gql`
        mutation deletePortal ($id: ID!, $downgradePlan: Boolean) {
            deletePortal (input: { id: $id, downgradePlan: $downgradePlan }) { id }
        }
    ` : gql`
        mutation deletePortal ($id: ID!) {
            deletePortal (input: { id: $id }) { id }
        }
    `

const router = useRouter()

const onClickRow = (slotProps) => {
    if (!hasPermission.value) return
    router.push(`/portals/${slotProps.data.id}/edit`)
}

const showDeleteConfirmation = (portal) => {
    deletePortal.value = portal
    toast.add({
        severity: 'warn',
        summary: `Sind Sie sicher, dass Sie ${deletePortal.value.name} dauerhaft löschen möchten?`,
        group: 'rm-portal',
        closable: false,
    });
}

const onSuccessfullyDeletingPortal = () => {
    toast.removeGroup('rm-portal')
    deletePortal.value = null
}

const onRejectDeletingPortal = () => {
    deletePortal.value = null
    toast.removeGroup('rm-portal')
}

const { result, loading, error, refetch } = useQuery(query)

const createdPortals = computed(() => result?.value?.portals?.length || 0)
const canCreatePortal = computed(() => createdPortals.value < props.portalLimit)
const tooManyPortals = computed(() =>  createdPortals.value > props.portalLimit)

watch(canCreatePortal, (value) => {
    emit('limit:reached', value)
})
</script>

<template>
    <div class="prose mb-8">
        <p>Meldeportale sollen speziellen Gruppen zur Verfügung stehen. Damit Portale nicht „erraten“ werden können (z. B. Unternehmensname), wird eine zufällige Kennung generiert. </p>
        <p>Sie können auf diese URL auch von Ihrer Webseite weiterleiten (z.B. www.unternehmen.de/meldeportal auf Ihr compentum Meldeportal)</p>
        <p>Sie haben <b>{{ createdPortals }}</b> von <b>{{  portalLimit }}</b> Portalen erstellt.</p>
        <Message v-if="tooManyPortals" severity="error">Sie haben mehr Meldeportale erstellt als gebucht. Bitte buchen Sie ein weiteres Portal oder löschen Sie eins.</Message>
    </div>
    <Toast position="bottom-center" group="rm-portal">
        <template #message="slotProps">
            <div class="flex flex-col w-full">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{ slotProps.message.summary }}</h4>
                    <p>{{ slotProps.message.detail }}</p>
                    <div class="flex items-center flex-wrap text-left mt-4" v-if="isSaas && canDowngradePlan">
                        <Checkbox v-model="downgradePlan" inputId="downgrade" binary />
                        <label for="downgrade" class="ml-2 basis-5/6">Gebuchtes Meldeportal aus dem Abonnement entfernen</label>
                        <small class="mt-2 basis-full">Die Änderungen sind nach dem Herunterstufen des Zusatztarifs sofort wirksam. Die restliche Laufzeit wird in Form eines Guthabens Ihrem Kundenkonto gutgeschrieben. Das Guthaben wird bei der nächsten Rechnung automatisch angewendet.</small>
                    </div>
                </div>
                <ApolloMutation
                    :mutation="deleteMutation"
                    :variables="{ id: deletePortal.id, downgradePlan  }"
                    :refetchQueries="() => [
                        { query: REPORT_QUERY, variables: buildQueryVariables() },
                        { query: gql`query portals { portals { id, name }}`}
                    ]"
                    @done="async () => {
                        refreshHealthcheck?.()
                        await refetch()
                        onSuccessfullyDeletingPortal()
                    }"
                >
                    <template v-slot="{ mutate, loading, error }">
                        <ValidationMessage v-if="error" :response="error" />
                        <div class="flex w-full mt-4 space-x-2">
                            <div class="w-1/2">
                                <Button
                                    class="p-button-secondary w-full block"
                                    label="Nein"
                                    @click="onRejectDeletingPortal" />
                            </div>
                            <div class="w-1/2">
                                <Button
                                    class="p-button-danger w-full block"
                                    label="Ja"
                                    :loading="loading"
                                    @click="mutate" />
                            </div>
                        </div>
                    </template>
                </ApolloMutation>
            </div>
        </template>
    </Toast>
    <DataTable
        :loading="loading"
        :value="result ? result.portals : null"
        :paginator="true"
        :rows="10"
        :rowHover="hasPermission ? true : false"
        @row-click="onClickRow"
    >
        <Column field="name" header="Portalbezeichnung">
            <template #body="slotProps">
                <router-link
                    v-if="hasPermission"
                    :to="`/portals/${slotProps.data.id}/edit`"
                    class="font-medium cursor-pointer hover:text-primary-600">
                    {{ slotProps.data.name }}
                </router-link>
                <span v-else>
                    {{ slotProps.data.name }}
                </span>
            </template>
        </Column>
        <Column field="reports_count" header="Verfahren" />
        <Column field="url" header="URL">
            <template #body="slotProps">
                <a class="text-sm text-primary-600 flex items-center" :href="slotProps.data.url" target="_blank" rel="noopener">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                    </svg>
                    {{ slotProps.data.url }}
                </a>
            </template>
        </Column>
        <Column header="Aktion">
            <template #body="slotProps">
                <Button
                    v-if="hasPermission"
                    type="button"
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-text"
                    v-tooltip.bottom="'Meldeportal löschen'"
                    severity="danger"
                    :disabled="slotProps.data.is_me"
                    @click="() => showDeleteConfirmation(slotProps.data)" />
            </template>
        </Column>
        <template #empty>
            <p class="text-center text-slate-500">
                Keine Meldeportale vorhanden. Erstellen Sie Ihr erstes über
                "Portal erstellen".
            </p>
        </template>
    </DataTable>
</template>
