<script setup lang="ts">
import type { Ref } from 'vue'
import { defineEmits, onMounted } from 'vue'

const emit = defineEmits(['finished'])
onMounted(() => {
    emit('finished')
})
</script>

<template>
   <p class="text-sm text-gray-500">{{ `Keine Verfahren vorhanden`}}</p>
</template>
