<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import Dialog from 'primevue/dialog';


const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    title: {
        type: String,
        required: true
    }
})

defineEmits(['update:modelValue'])

</script>

<template>
    <Dialog :visible="modelValue" @update:visible="$emit('update:modelValue', $event)" modal :header="props.title" :style="{ width: '50vw' }">
        <slot></slot>
        <template #footer>
            <slot name="footer"></slot>
        </template>
    </Dialog>
</template>
