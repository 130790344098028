<script setup lang="ts">
import { onMounted, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useQuery } from "@vue/apollo-composable"
import DataTable from 'primevue/datatable'
import Button from 'primevue/button'
import Column from 'primevue/column'
import Skeleton from 'primevue/skeleton'
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Calendar from 'primevue/calendar'

import StatusBadge from '@/components/StatusBadge.vue'
import DeadlineStatus from '@/components/DeadlineStatus.vue'
import StatusDropdown from '@/components/StatusDropdown.vue'
import { REPORT_QUERY } from './queries'
import {
    dataTableFirst,
    paginationFirst,
    searchTerm,
    statuses,
    filters,
    sortField,
    sortOrder,
    paginationPage,
    buildQueryVariables,
    clearFilter,
    onPage,
} from './ReportIndexState'

const router = useRouter()

watch(searchTerm, (value) => onPage(fetchMore))

const formatDate = (value) => {
    return new Date(value).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
};

const onClickRow = (slotProps) => {
    router.push(`/reports/${slotProps.data.id}`)
}

onMounted(() => {
    const paginatorInfo = result?.value?.reports?.paginatorInfo
    if (paginatorInfo && paginatorInfo.currentPage) {
        dataTableFirst.value = (paginationFirst.value * paginatorInfo.currentPage) - paginationFirst.value
    }
})

const { result, loading, fetchMore } = useQuery(REPORT_QUERY, buildQueryVariables())
</script>

<template>
    <template v-if="result && result.reports">
        <DataTable v-model:first.sync="dataTableFirst" v-model:filters="filters" v-model:sort-field="sortField"
            v-model:sort-order="sortOrder" :rows="paginationFirst" :totalRecords="result.reports.paginatorInfo.total"
            :loading="loading" :lazy="true" :value="result.reports.data" :paginator="true" :rowHover="true"
            :scrollable="true" scrollDirection="horizontal" filterDisplay="row" :globalFilterFields="['status']"
            @row-click="onClickRow" @filter="() => onPage(fetchMore)" @sort="() => onPage(fetchMore)"
            @page="() => onPage(fetchMore)">
            <template #header>
                <div class="table-header">
                    <div class="sm:flex justify-between">
                        <div>
                            <Button class="!mr-2 p-button-outlined" v-tooltip.bottom="'Liste aktualisieren'"
                                icon="pi pi-refresh" @click="() => {
                                    dataTableFirst = 0
                                    paginationPage = 1
                                    onPage(fetchMore)
                                }" />
                            <Button type="button" icon="pi pi-filter-slash" label="Filter zurücksetzen"
                                class="p-button-outlined" @click="clearFilter(fetchMore)" />
                        </div>
                        <span class="p-input-icon-left mt-4 sm:mt-0">
                            <i class="pi pi-search" />
                            <InputText v-model="searchTerm" placeholder="Nach Kennung suchen" />
                        </span>
                    </div>
                </div>
            </template>
            <Column field="subject" header="Kennung" style="min-width:19rem">
                <template #body="slotProps">
                    <router-link :to="`/reports/${slotProps.data.id}`"
                        class="font-medium cursor-pointer hover:text-primary-600">
                        {{ `#${slotProps.data.identifier}` }}
                        <span class="text-slate-400 ml-1 inline-flex items-center text-sm">
                            <i class="pi pi-envelope mr-1 !text-xs"></i><span>{{ slotProps.data.messages.count }}</span>
                        </span>
                        <span v-if="slotProps.data.internal"
                            class="ml-2 text-white bg-primary-700 font-medium p-0.5 px-1 text-xs rounded">{{ 'Alternative Einreichung'}}</span>
                    </router-link>
                </template>
            </Column>
            <Column field="category" header="Kategorie" :show-filter-menu="false">
                <template #body="slotProps">
                    {{ slotProps.data.category ? slotProps.data.category.name : '-' }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <Dropdown v-model="filterModel.value" optionLabel="name" optionValue="id"
                        :options="result.categories?.data" :show-clear="true" placeholder="Alle Kategorien"
                        class="p-column-filter" @change="filterCallback()" />
                </template>
            </Column>
            <Column field="deadline" header="Frist" :sortable="true">
                <template #body="slotProps">
                    <DeadlineStatus :remaining-days="slotProps.data.deadline.remaining_days"
                        :type="slotProps.data.deadline.type" />
                </template>
            </Column>
            <Column field="created_at" header="Eingegangen am" :sortable="true" :show-filter-menu="false"
                style="min-width:12rem">
                <template #body="slotProps">
                    {{ formatDate(slotProps.data.created_at) }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <Calendar v-model="filterModel.value" @date-select="filterCallback()" placeholder="Zeitraum"
                        :date="new Date()" view="month" dateFormat="mm.yy" />
                </template>
            </Column>
            <Column field="status" header="Status" :sortable="true" :show-filter-menu="false" style="min-width:12rem">
                <template #body="{ data }">
                    <StatusBadge :type="data.status" />
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <StatusDropdown v-model="filterModel.value" @change="filterCallback()" :options="statuses"
                        placeholder="Alle offenen" class="p-column-filter" :show-clear="true" />
                </template>
            </Column>
            <Column field="portal.name" header="Portal" :show-filter-menu="false">
                <template #body="slotProps">
                    {{ slotProps.data.portal.name }}
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <Dropdown v-model="filterModel.value" optionLabel="name" optionValue="id" :options="result.portals"
                        :show-clear="true" placeholder="Alle Portale" class="p-column-filter" @change="filterCallback()" />
                </template>
            </Column>
            <template #empty>
                <p class="text-center text-slate-500">Keine Meldungen vorhanden.</p>
            </template>
        </DataTable>
    </template>
    <DataTable v-else :value="[
        { subject: null, status: null, created_at: null, portal: null },
        { subject: null, status: null, created_at: null, portal: null },
        { subject: null, status: null, created_at: null, portal: null }
    ]">
        <Column field="status" header="Status">
            <template #body>
                <Skeleton></Skeleton>
            </template>
        </Column>
        <Column field="subject" header="Betreff">
            <template #body>
                <Skeleton></Skeleton>
            </template>
        </Column>
        <Column field="deadline" header="Frist">
            <template #body>
                <Skeleton></Skeleton>
            </template>
        </Column>
        <Column field="created_at" header="Eingegangen am">
            <template #body>
                <Skeleton></Skeleton>
            </template>
        </Column>
        <Column field="portal" header="Portal">
            <template #body>
                <Skeleton></Skeleton>
            </template>
        </Column>
    </DataTable>
</template>
