import type { Ref, ComputedRef } from 'vue'
import type { Status } from '@/utils/status'

import { ref, computed } from 'vue'
import { FilterMatchMode } from 'primevue/api'

import { Options as StatusOption } from '@/utils/status'
import format from 'date-fns/format'
import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'

export const dataTableFirst: Ref<number> = ref(0)
export const paginationFirst: Ref<number> = ref(15)
export const searchTerm: Ref<string> = ref('')
export const statuses: Ref<Array<Status>> = ref(StatusOption)

export const filters = ref({
    'status': { value: null, matchMode: FilterMatchMode.EQUALS },
    'category': { value: null, matchMode: FilterMatchMode.EQUALS },
    'portal.name': { value: null, matchMode: FilterMatchMode.EQUALS },
    'created_at': { value: null, matchMode: FilterMatchMode.EQUALS },
});
export const sortField: Ref<string> = ref('deadline')
export const sortOrder: Ref<number> = ref(1)

export const paginationPage: ComputedRef<number> = computed(() => Math.round(dataTableFirst.value / paginationFirst.value) + 1)
export const defaultStatusSelection = ['NEW', 'RECEIVED', 'UNDER_REVIEW', 'AWAITING_FEEDBACK', 'COMPLETED', 'SUSPENDED', 'MARKED_FOR_DELETION']


export const buildQueryVariables = () => {
    const sortMapping = {
        'created_at': 'CREATED_AT',
        'status': 'STATUS',
        'deadline': 'DEADLINE',
    }
    const sortValue = sortMapping[sortField.value]
    let createdAtRange

    if (filters.value.created_at.value) {
        createdAtRange = {
            from: format(startOfMonth(filters.value.created_at.value), 'yyyy-MM-dd HH:mm:ss'),
            to: format(endOfMonth(filters.value.created_at.value), 'yyyy-MM-dd HH:mm:ss'),
        }
    }

    return {
        first: paginationFirst.value,
        page: paginationPage.value,
        sortField: typeof sortValue === 'string' ? sortValue : undefined,
        sortRelation: typeof sortValue !== 'string' ? sortValue : undefined,
        sortOrder: sortOrder.value > 0 ? 'ASC' : 'DESC',
        status: filters.value.status.value ? [filters.value.status.value] : defaultStatusSelection,
        hasCategory: filters.value.category.value ? { value: filters.value.category.value, column: 'ID' } : null,
        portal_id: filters.value['portal.name'].value ? filters.value['portal.name'].value as String : undefined,
        created_at: createdAtRange,
        search: searchTerm.value.length > 0 ? searchTerm.value : undefined,
    }
}

export const clearFilter = async (fetchMore) => {
    searchTerm.value = ''
    filters.value = {
        'category': { value: null, matchMode: FilterMatchMode.EQUALS },
        'status': { value: null, matchMode: FilterMatchMode.EQUALS },
        'portal.name': { value: null, matchMode: FilterMatchMode.EQUALS },
        'created_at': { value: null, matchMode: FilterMatchMode.EQUALS },
    }
    await onPage(fetchMore)
}

export const onPage = async (fetchMore) => {
    fetchMore({
        variables: buildQueryVariables(),
        updateQuery: (previousResult, { fetchMoreResult }) => {
            const newReports = fetchMoreResult.reports.data
            const newPageInfo = fetchMoreResult.reports.paginatorInfo

            return {
                reports: {
                    __typename: previousResult.reports.__typename,
                    paginatorInfo: newPageInfo,
                    data: newReports,
                },
            }
        }
    })
}
