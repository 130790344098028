<script setup lang="ts">
import type { Healthcheck } from '@/types'
import type { ComputedRef } from 'vue'

import { ref, inject, computed, defineProps } from 'vue'
import { onClickOutside } from '@vueuse/core'
import Skeleton from 'primevue/skeleton'

const props = defineProps({
    'overlay': {
        type: Boolean,
        default: true
    },
    'unselected': {
        type: Boolean,
        default: false
    },
    'whiteLabel': {
        type: Boolean,
        default: false
    },
})

const active = ref(false)
const target = ref(null)

const getInitials = (name: string) => {
    // @ts-ignore
    return name.match(/(^\S\S?|\b\S)?/g).join('').match(/(^\S|\S$)?/g).join('').toUpperCase()
}

const getThumbnailBackground = (name: string) => {
    const firstLetter = getInitials(name).charAt(0)
    const colors = {'a,b,c,d': 'bg-blue-300', 'e,f,g,h': 'bg-red-300', 'i,j,k,l': 'bg-green-300', 'm,n,o,p': 'bg-purple-300' ,'q,r,s,t': 'bg-cyan-300', 'u,v,w,x,y,z': 'bg-orange-300'}
    const index = Object.keys(colors).find(key => key.includes(firstLetter.toLowerCase()))

    return index ? colors[index] : 'bg-yellow-300'
}

const getHealthcheck: Function | undefined = inject('getHealthcheck')
const healthcheck: ComputedRef<Healthcheck | null> = computed(() => getHealthcheck ? getHealthcheck() : null)

onClickOutside(target, (event) => active.value = false)
</script>

<template>
    <div
        ref="target"
        class="transiton-all z-20"
        :class="{
            'py-2 px-4 w-full shadow-2xl h-full transparent md:p-3 xl:p-4 overflow-auto': (props.unselected && active),
            'left-0 right-0 bottom-0 top-0 w-screen py-2 px-4 md:w-72 xl:w-full shadow-2xl absolute h-full min-h-screen transparent md:p-3 xl:p-4': (!props.unselected && active),
            'bg-primary-850': overlay && active,
            'shadow-none': !overlay,
            'relative': !active
        }">
        <label
            v-if="!props.unselected"
            for="toggle-menu"
            :class="{
                'relative flex items-center xl:p-4 rounded-lg md:mb-8 cursor-pointer select-none': true,
                'xl:bg-black/20  xl:hover:bg-black/30 xl:active:bg-black/20': !overlay,
                'xl:bg-white/20  xl:hover:bg-white/30 xl:active:bg-white/20': overlay,
            }">
            <div
                class="w-9 h-9 md:w-14 md:h-14 xl:w-10 xl:h-10 xl:mr-4 rounded-full flex-0 flex-shrink-0 flex justify-center items-center text-lg font-bold border border-white/20"
                :class="getThumbnailBackground($appConfig.tenantName)">
                {{ getInitials($appConfig.tenantName) }}
            </div>
            <div
                :class="{
                    'hidden xl:flex flex-1 overflow-hidden flex-col justify-center relative pr-6 xl:pr-10': true,
                    '!block !pl-4 xl:!pl-0': active,
                }">
                <div class="truncate font-bold">{{ $appConfig.tenantName }}</div>
                <div class="truncate text-xs"><span v-if="healthcheck" class="font-medium">{{ healthcheck.alerts }}</span> offene Verfahren</div>
            </div>
            <button
                @click.prevent="active = !active"
                id="toggle-menu"
                name="toggle-menu"
                :class="{
                    'absolute bg-white text-gray-500 rounded -right-1 -bottom-1 md:right-0 md:bottom-0 xl:bg-transparent xl:rounded-none xl:bottom-auto xl:right-4 xl:text-white/40': true,
                    'bg-transparent rounded-none bottom-auto right-0 md:right-2 md:top-2 xl:top-auto text-white/40': active
                }">
                <svg v-if="active" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 xl:h-6 xl:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                </svg>
            </button>
        </label>
        <label v-else for="toggle-menu" :class="{'mb-4 text-white': active, 'text-black': !active && !whiteLabel, 'text-white': !active && whiteLabel}" class="relative flex items-center xl:p-4 xl:bg-white/20 rounded-lg xl:hover:bg-white/30 xl:active:bg-white/20 cursor-pointer select-none">
            <div>Beschäftigungsgeber wechseln</div>
            <button
                @click.prevent="active = !active"
                id="toggle-menu"
                name="toggle-menu"
                :class="{
                    'absolute bg-white text-gray-500 rounded -right-1 -bottom-1 md:right-0 md:bottom-0 xl:bg-transparent xl:rounded-none xl:bottom-auto xl:right-4': true,
                    'xl:text-black/40': !active,
                    'bg-transparent rounded-none bottom-auto right-0 md:right-2 md:top-2 xl:top-auto text-white/40': active
                }">
                <svg v-if="active" xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 xl:h-6 xl:w-6" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 xl:h-6 xl:w-6" fill="none" viewBox="0 0 24 24" :stroke="whiteLabel ? 'white' :'currentColor'" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                </svg>
            </button>
        </label>
        <transition
            enter-active-class="duration-300 ease-out"
            enter-from-class="transform -translate-y-6 opacity-0"
            enter-to-class="opacity-100 -translate-y-0"
            leave-active-class="duration-0">
            <div v-if="active">
                <ApolloQuery
                    :query="gql => gql`
                        query organizations {
                            myOrganizations(first: 0) { data { id, name, activeAlerts, accessible } }
                        }
                    `"
                >
                    <template v-slot="{ result: { data }, isLoading }">
                        <div v-show="isLoading" class="mt-8 md:mt-0">
                            <Skeleton width="100%" class="mb-4" />
                            <div class="relative flex items-center mb-4 xl:mb-0 xl:p-4 rounded-r-lg rounded-l-full xl:rounded-tl-lg xl:rounded-bl-lg">
                                <div class="w-12 h-12 md:w-14 md:h-14 xl:w-10 xl:h-10 mr-4 bg-gray-100 rounded-full flex-0 flex-shrink-0 flex justify-center items-center text-lg font-bold border border-white/20" />
                                <div class="flex flex-1 overflow-hidden flex-col justify-center relative pr-6 xl:pr-10">
                                    <Skeleton width="100%" class="mb-2 mt-1" />
                                    <Skeleton width="60%" class="mb-4" />
                                </div>
                            </div>
                            <div class="relative flex items-center mb-4 xl:mb-0 xl:p-4 rounded-r-lg rounded-l-full xl:rounded-tl-lg xl:rounded-bl-lg">
                                <div class="w-12 h-12 md:w-14 md:h-14 xl:w-10 xl:h-10 mr-4 bg-gray-100 rounded-full flex-0 flex-shrink-0 flex justify-center items-center text-lg font-bold border border-white/20" />
                                <div class="flex flex-1 overflow-hidden flex-col justify-center relative pr-6 xl:pr-10">
                                    <Skeleton width="100%" class="mb-2 mt-1" />
                                    <Skeleton width="60%" class="mb-4" />
                                </div>
                            </div>
                        </div>
                        <h3
                            v-if="data && data.myOrganizations"
                            class="mt-8 md:mt-0 font-bold text-sm mb-4" :class="{'text-white/60': overlay, 'text-black/60': !overlay}">Mein Beschäftigungsgeber</h3>
                        <div v-if="data && data.myOrganizations" class="overflow-y-auto h-full max-h-[60vh] md:max-h-[75vh]">
                            <a
                                v-for="organization in data.myOrganizations.data"
                                :key="organization.id"
                                :href="`/${organization.id}`"
                                :class="{
                                    'relative flex items-center mb-4 xl:mb-0 xl:p-4 rounded-r-lg rounded-l-full xl:rounded-tl-lg xl:rounded-bl-lg cursor-pointer select-none': true,
                                    'hover:bg-black/30 active:bg-black/20': !overlay,
                                    'hover:bg-white/30 active:bg-white/20': overlay,
                                    'opacity-50': !organization.accessible,
                                }">
                                <div
                                    class="w-12 h-12 md:w-14 md:h-14 xl:w-10 xl:h-10 mr-4 rounded-full flex-0 flex-shrink-0 flex justify-center items-center text-lg font-bold border border-white/20"
                                    :class="getThumbnailBackground(organization.name)">
                                    {{ getInitials(organization.name) }}
                                </div>
                                <div
                                    class="flex flex-1 overflow-hidden flex-col justify-center relative pr-6 xl:pr-10"
                                    :class="{'text-black': !overlay}">
                                    <div class="truncate font-bold">{{ organization.name }}</div>
                                    <div
                                        v-if="organization.accessible"
                                        class="truncate text-xs"><span class="font-medium">{{ organization.activeAlerts }}</span> offene Verfahren</div>
                                    <div
                                        v-else
                                        class="truncate text-xs flex flex-wrap items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 mr-1">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                        </svg>
                                        {{ 'Zugriff gesperrt' }}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </template>
                </ApolloQuery>
                <a
                    href="/organization/new"
                    class="inline-flex items-center mt-8 text-white hover:bg-white/20 active:bg-white/10 px-3 pr-4 py-1 rounded font-medium text-sm"
                    :class="{'text-black': !overlay}">
                    <svg xmlns="http://www.w3.org/2000/svg" class="mr-1 h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
                    </svg>
                    Neuen Beschäftigungsgeber anlegen
                </a>
            </div>
        </transition>
    </div>
</template>
