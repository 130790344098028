export interface Status {
    id: string,
    name: string,
}

export const Options: Array<Status> = [
    { id: 'NEW', name: 'Neu' },
    { id: 'RECEIVED', name: 'Eingegangen' },
    { id: 'UNDER_REVIEW', name: 'In Prüfung' },
    { id: 'AWAITING_FEEDBACK', name: 'Warte auf Rückmeldung' },
    { id: 'COMPLETED', name: 'Abgeschlossen' },
    { id: 'SUSPENDED', name: 'Eingestellt' },
    { id: 'MARKED_FOR_DELETION', name: 'Zur Löschung' }
]
