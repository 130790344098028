<template>
    <div class="flex flex-col" :class="backgroundColor">
        <div class="text-center mb-2 text-6xl text-slate-500">{{ props.value }}</div>
        <div class="text-center text-sm text-slate-400 font-medium">{{ props.label }}</div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
    value: {
        type: Number,
        default: 0
    },
    label: {
        type: String,
        default: ''
    },
    color: {
        type: String,
        default: 'white'
    }
})

const backgroundColor = computed(() => {
    return `bg-${props.color}`
})
</script>
