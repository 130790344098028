<script setup lang="ts">
import { defineProps, defineEmits, ref, watch } from 'vue';
import type { Ref } from 'vue'
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from "@vuelidate/validators";
import gql from 'graphql-tag';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message';
import Textarea from 'primevue/textarea';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';

const rules = {
    templateName: { required, maxLength: maxLength(255) },
    templateContent: { required },
}

const mutation = gql`
    mutation createMessageTemplate ($name: String, $content: String) {
        createMessageTemplate (input: { name: $name, content: $content }) {
            id, name, content, is_visible
        }
    }
`

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const success: Ref<boolean> = ref(false)
const templateName: Ref<string | null> = ref(null)
const templateContent: Ref<string> = ref('')
const visible = ref(props.modelValue)

watch(() => props.modelValue, (val) => visible.value = val)
watch(visible, (val) => emit('update:modelValue', val))

const v$ = useVuelidate(rules, { templateName, templateContent })

const onDone = ({ data: { createMessageTemplate } }) => {
    if (createMessageTemplate) {
        templateName.value = null
        templateContent.value = ''
        success.value = true
    }
}

const updateCache = (cache, { data: { createMessageTemplate } }) => {
    const query = {
        query: gql`
            query messageTemplates {
                messageTemplates(with_hidden: true) { id, name, is_visible }
            }
        `
    }

    let data = cache.readQuery(query)
    data = {
        ...data,
        messageTemplates: [
            ...data.messageTemplates,
            createMessageTemplate
        ]
    }
    cache.writeQuery({ ...query, data })
}

</script>

<template>
    <Dialog
        v-model:visible="visible"
        :header="`Antwortvorlage erstellen`"
        position="top"
        modal
        :dismissable-mask="true"
        :close-on-escape="true"
        :draggable="false"
        :style="{ width: '700px' }"
    >
        <ApolloMutation
            :mutation="mutation"
            :variables="{ name: templateName, content: templateContent }"
            :update="updateCache"
            :refetchQueries="() => [
                { query: gql`query messageTemplates { messageTemplates { id, name, is_visible }}`}
            ]"
            :onDone="onDone">
            <template v-slot="{ mutate, loading, error }">
                <Message v-if="error" severity="error">{{ error.message }}</Message>
                <Message v-if="success" severity="success">Antwortvorlage erfolgreich angelegt.</Message>
                <form method="post" autocomplete="on" @submit.prevent="() => !v$.$invalid && mutate()">
                    <div class="p-inputgroup my-4">
                        <InputText v-model="templateName" placeholder="Name" />
                    </div>
                    <div class="p-inputgroup my-4">
                        <Textarea v-model="templateContent" placeholder="Inhalt" rows="8"/>
                    </div>
                    <Button type="submit" :loading="loading" :disabled="v$.$invalid">Erstellen</Button>
                </form>
            </template>
        </ApolloMutation>
    </Dialog>
</template>
