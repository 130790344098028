export class PiniaStorage implements Storage {
    private localStore
    public length

    constructor (piniaStore) {
        this.localStore = piniaStore
    }

    public getItem(key: string): string | null {
        return this.localStore[key]
    }

    public setItem(key: string, value: string): void {
        this.localStore[key] = value
    }

    public removeItem(key: string): void {
        this.localStore.$reset()
    }

    public clear(): void {

    }

    public key(index: number): string | null {
        return null
    }
}
