<script setup lang="ts">
import { ref, computed } from 'vue'
import Button from 'primevue/button'

import CategoryIndex from '@/containers/Category/CategoryIndex.vue'
import CreateCategoryDialog from '@/containers/Category/CreateCategoryDialog.vue'
import AppFrame from '@/components/AppFrame.vue'
import AppConfig from '../config'

const currentUserRole = ref(AppConfig.currentUserRole)
const hasPermission = computed(() => currentUserRole.value === 'owner')

const showCreateModal = ref(false)
</script>

<template>
    <AppFrame title="Kategorien">
        <template #actions>
            <Button v-if="hasPermission" label="Kategorie erstellen" class="p-button-sm" @click="showCreateModal = true" />
        </template>
        <CreateCategoryDialog v-if="hasPermission" v-model="showCreateModal" />
        <CategoryIndex />
    </AppFrame>
</template>
