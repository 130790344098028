<script lang="ts" setup>
import type { Ref } from 'vue'
import { ref, defineProps, defineEmits } from 'vue'
import VuePdfEmbed from 'vue-pdf-embed'

import Checkbox from 'primevue/checkbox';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button'

defineProps({
    modelValue: {
        type: Boolean,
        default: false,
    },
})
defineEmits(['update:modelValue'])

const visible = ref(false)
const pdf: Ref<any | null> = ref(null)
</script>

<template>
    <div class="flex items-center mb-4">
        <Checkbox :modelValue="modelValue" @update:modelValue="(newValue) => $emit('update:modelValue', newValue)" inputId="terms" :binary="true" />
        <label for="terms" class="ml-2"> Ich erkläre mich mit den <a class="mt-1 cursor-pointer underline underline-offset-2 hover:no-underline text-primary-600" @click="visible = true">AGB</a> von compentum einverstanden. </label>
    </div>

    <Dialog v-model:visible="visible" modal header="Allgemeine Geschäftsbedingungen" class="w-full sm:w-3/4 lg:w-1/2">
        <div class="mb-4 flex">
            <Button label="Drucken" @click="pdf?.print()" icon="pi pi-print" class="mr-4" />
        </div>
        <div class="bg-gray-500">
            <vue-pdf-embed ref="pdf" source="/img/compentum-agb.pdf" class="w-11/12 mx-auto"/>
        </div>
    </Dialog>
</template>

<style>
    .vue-pdf-embed > div {
        margin-bottom: 8px;
        box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
    }
</style>
