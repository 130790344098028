<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps({
    label: {
        type: String,
        default: 'in %s Tagen'
    },
    labelToday: {
        type: String,
        default: 'heute'
    },
    labelSingular: {
        type: String,
        default: 'in einem Tag'
    },
    labelPast: {
        type: String,
        default: 'Frist abgelaufen'
    },
    labelPastSingular: {
        type: String,
        default: 'Frist abgelaufen'
    },
    remainingDays: {
        type: Number,
        default: 0
    },
    outline: {
        type: Boolean,
        default: true
    }
})

const remainingLabelText = computed (() => {
    let label: string = ''

    if (props.remainingDays === 0) {
        label = props.labelToday
    } else if (props.remainingDays === 1) {
        label = props.labelSingular
    } else if (props.remainingDays === -1) {
        label = props.labelPastSingular
    } else if (props.remainingDays < 1) {
        label = props.labelPast
    } else if (props.remainingDays > 1) {
        label = props.label
    }

    return label
})

const deadlineText = computed(() => {
    return remainingLabelText.value.replace('%s', props.remainingDays.toString())
})

const showDeadline = computed(() => {
    return props.remainingDays !== null
})

const color = computed(() => {
    const greenStyle = 'border-green-300 text-green-600'
    const yellowStyle = 'border-yellow-300 text-yellow-600'
    const orangeStyle = 'border-orange-300 text-orange-600'
    const redStyle = 'border-red-300 text-red-700'

    let cssClasses = greenStyle

    if (props.remainingDays <= 30) {
        cssClasses = yellowStyle
    }

    if (props.remainingDays <= 14) {
        cssClasses = orangeStyle
    }

    if (props.remainingDays <= 7) {
        cssClasses = redStyle
    }

    return cssClasses
})
</script>

<template>
    <span
        v-if="showDeadline"
        :class="[{
            'px-4 py-2 rounded-full border text-sm': outline,
            'font-semibold flex cursor-pointer align-center w-max transition duration-300 ease': true
        }, color]"
    >
    {{ deadlineText }}
    </span>
    <span v-else>{{'-'}}</span>
</template>
