<template>
    <div class="m-3 lg:m-5 px-4 md:px-6 lg:px-8">
        <nav>
            <ul class="flex text-xs md:text-sm items-center">
                <li><a class="text-gray-600 font-medium hover:text-gray-800 active:text-gray-600" href="#">nextindex</a></li>
                <li class="mx-1 text-gray-600">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 mt-0.5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                </li>
                <li><a class="text-gray-600 font-medium hover:text-gray-800 active:text-gray-600" href="#">Meldungen</a></li>
                <li class="mx-1 text-gray-600">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3 mt-0.5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                </li>
                <li><span class="text-gray-500 font-normal" href="#">Meldung Nummer C7XG</span></li>
            </ul>
        </nav>
    </div>
</template>
