<script setup lang="ts">
import type { Ref, ComputedRef } from 'vue'

import { defineProps, ref, computed, withDefaults, onMounted, defineExpose } from 'vue'
import Message from 'primevue/message'
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'

type ToastPositionType = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right' | 'center' | undefined;
interface Props {
    toast?: boolean,
    class?: string,
    toastPosition?: ToastPositionType,
    response: import("@apollo/client/errors").ApolloError
}

const props = withDefaults(defineProps<Props>(), {
    toast: false,
    toastPosition: 'bottom-right'
})
const toastHandler = useToast()
const validationMessages: ComputedRef<any> = computed((): unknown => props.response?.graphQLErrors[0]?.extensions?.validation);

onMounted(() => {
    if (props.toast) {
        const messages = validationMessages?.value?.join(', ')
        toastHandler.add({
            severity: 'error',
            summary: messages ? messages : props.response.graphQLErrors[0].message
        });
    }
})

defineExpose(props)
</script>

<template>
    <template v-if="!toast">
        <Message v-if="validationMessages" :class="class" severity="error">
            <div v-for="(message, key) in validationMessages" :key="key">{{
                message[0]
            }}</div>
        </Message>
        <Message v-else :class="class" severity="error">{{ response.graphQLErrors[0].message }}</Message>
    </template>
    <Toast v-else :position="toastPosition" />
</template>
