import AppProvider from './AppProvider.vue'
import AuthLogin from './Auth/AuthLogin.vue'
import AuthCreateAccount from './Auth/AuthCreateAccount.vue'
import AuthEmailPassword from './Auth/AuthEmailPassword.vue'
import AuthResetPassword from './Auth/AuthResetPassword.vue'
import AppSidebar from './AppSidebar.vue'
import AppBreadcrumb from './AppBreadcrumb.vue'
import AppContent from './AppContent.vue'
import AppFlash from './AppFlash.vue'
import AppEncryptionBanner from './AppEncryptionBanner.vue'
import AppVerificationBanner from './AppVerificationBanner.vue'
import AppError from './AppError.vue'
import UserRemoved from './User/UserRemoved.vue'

import TenantCreate from './Tenant/TenantCreate.vue'
import CustomerForm from './Payment/CustomerForm.vue'
import SubscriptionMissing from './Payment/SubscriptionMissing.vue'
import CheckoutSuccess from './Payment/CheckoutSuccess.vue'

export default {
    AppProvider,
    AuthLogin,
    AuthCreateAccount,
    AppSidebar,
    AppBreadcrumb,
    AppContent,
    AppError,
    AppFlash,
    AppEncryptionBanner,
    AuthEmailPassword,
    AuthResetPassword,
    TenantCreate,
    UserRemoved,
    CustomerForm,
    SubscriptionMissing,
    CheckoutSuccess,
    AppVerificationBanner
}
