<script setup lang="ts">
import type { Ref } from 'vue'
import type { User } from '@/containers/Report/reportKeys'

import { ref, watch, onMounted, defineEmits } from 'vue'
import gql from 'graphql-tag'
import { useLazyQuery, useQuery } from "@vue/apollo-composable"
import Skeleton from 'primevue/skeleton'
import ProgressBar from 'primevue/progressbar'
import Password from 'primevue/password'
import Message from 'primevue/message'
import Button from 'primevue/button'
import { decrcyptPrivateKey } from '@/containers/Report/reportKeys'
import { StorageService, PiniaStorage } from '@/services/storage'
import { useKeyStore } from '@/services/storage/keyStorage'
import AppConfig from '@/config'

const keyStore = useKeyStore()

const emit = defineEmits(['initialized'])

const store = new StorageService<User>({
    storage: new PiniaStorage(keyStore)
})

const meQuery = gql`
    query {
        me {
            identifier
            private_key
            salt
            iv
        }
    }
`
const reportsAudit = gql`
    query reportsAudit {
        reportsAudit {
            number_of_dirty_reports
            self_affected
        }
    }
`

const isDecrypting: Ref<boolean> = ref(true)
const requiresPasswordPrompt: Ref<boolean> = ref(false)
const processing: Ref<boolean> = ref(false)
const password: Ref<string> = ref('')
const passwordValidationMessage: Ref<string | null> = ref(null)
const currentUser: Ref<User | null> = ref(
    store.restoreState('currentUser') as User
)

const { result: userResult, loading, load: loadUser } = useLazyQuery(meQuery)
const { result: reportsAuditResults, loading: reportsAuditLoading, refetch: reportsAuditRefetch } = useQuery(reportsAudit, {
    skip: true,
    fetchPolicy: 'network-only',
})

const onStartProcessing = () => {
    processing.value = true
    emit('initialized')
}

const decrcyptUserPrivateKey = async () => {
    const { currentUser: user, error } = await decrcyptPrivateKey(userResult.value.me, password.value, store)
    passwordValidationMessage.value = error

    if (! error) {
        requiresPasswordPrompt.value = false
    }

    if (user) {
        currentUser.value = user
    }

    emit('initialized')
}

const decryptMasterKey = async () => {
    const masterkeyUser = {
        identifier: 'master',
        iv: AppConfig.keys.iv,
        private_key: AppConfig.keys.privateKey,
        salt: AppConfig.keys.salt
    }

    // Don't pass a store to decryptPrivateKey so it does not get saved in the session
    const { error, currentUser: user } = await decrcyptPrivateKey(masterkeyUser, password.value)
    passwordValidationMessage.value = error
    currentUser.value = user

    if (! error) {
        requiresPasswordPrompt.value = false
        isDecrypting.value = false
    }

    emit('initialized')
}

watch(userResult, async value => {
    requiresPasswordPrompt.value = true
})

onMounted(async () => {
    if (reportsAuditResults.value) {
        await reportsAuditRefetch()
    }
})

!currentUser.value && loadUser()
</script>
<template>
    <ProgressBar v-if="reportsAuditLoading" class="mb-2" />
    <div v-else>
        <div v-if="reportsAuditResults?.reportsAudit?.number_of_dirty_reports > 0">
            <div v-if="loading">
                <Skeleton class="mb-2" />
                <Skeleton width="10rem" class="mb-2" />
            </div>
            <div v-else-if="requiresPasswordPrompt && !reportsAuditResults?.reportsAudit?.self_affected">
                <Message :closable="false">{{ reportsAuditResults?.reportsAudit?.number_of_dirty_reports }} Meldung(en) müssen neu verschlüsselt werden.</Message>
                <p class="text-gray-600 mb-4">Bitte geben Sie Ihr Passwort ein, um die Meldung zu entschlüsseln.</p>
                <form method="post" autocomplete="on" class="flex" @submit.prevent="decrcyptUserPrivateKey">
                    <Password v-model="password" :class="['block mr-2', { 'p-invalid': passwordValidationMessage }]" autocomplete="on" placeholder="Passwort" name="password" :feedback="false" />
                    <Button type="submit" class="ml-2" label="Entschlüsseln" />
                    <p class="mt-2 text-red-600 text-sm">{{ passwordValidationMessage }}</p>
                </form>
            </div>
            <div v-else-if="requiresPasswordPrompt && reportsAuditResults?.reportsAudit?.self_affected">
                <Message :closable="false">{{ reportsAuditResults?.reportsAudit?.number_of_dirty_reports }} Meldung(en) müssen neu verschlüsselt werden.</Message>
                <p class="text-gray-600 mb-4">Da Ihr Benutzeraccount von der neuverschlüsselung betroffen ist, müssen Sie Ihr <b>Wiederherstellungspasswort</b> zur Neuverschlüsselung verwenden.</p>
                <form method="post" autocomplete="on" @submit.prevent="decryptMasterKey">
                    <Password v-model="password" :class="['block mr-2', { 'p-invalid': passwordValidationMessage }]" autocomplete="on" placeholder="Wiederherstellungspasswort" name="password" :feedback="false" />
                    <Button type="submit" class="ml-2" label="Entschlüsseln" />
                    <p class="mt-2 text-red-600 text-sm">{{ passwordValidationMessage }}</p>
                </form>
            </div>
            <div v-else-if="!processing">
                <Message :closable="false">{{ reportsAuditResults?.reportsAudit?.number_of_dirty_reports }} Meldung(en) müssen neu verschlüsselt werden.</Message>
                <Button @click.prevent="onStartProcessing">Neuverschlüsselung starten</Button>
            </div>
            <slot v-else-if="currentUser" :current-user="currentUser" />
            <div v-else>
                <p>Etwas ist schief gelaufen</p>
            </div>
        </div>
        <div v-else>
            <Message :closable="false">Keine Meldung muss neu verschlüsselt werden.</Message>
            <Button @click.prevent="() => reportsAuditRefetch()">Erneut prüfen</Button>
        </div>
    </div>
</template>
