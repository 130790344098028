<script setup lang="ts">
import gql from 'graphql-tag';
import { ref } from 'vue'
import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import AppFrame from '@/components/AppFrame.vue'
import Button from 'primevue/button'
import InlineMessage from 'primevue/inlinemessage';


const toast = useToast()

const query = gql`
    query{
        generateExport {
            status
            message
        }
    }
`

const showSuccess = () => {
    toast.add({ severity: 'success', summary: 'Erfolgreich angefragt', detail: 'Export wird erstellt. Sie werden per E-Mail benachrichtigt, wenn die Datei zum Download bereit steht.', life: 3000 });
}

const onError = (e) => {
    toast.add({ severity: 'error', summary: 'Fehler', detail: e, life: 3000 });
}

const generateExport = async (query, loading, result) => {
    query.skip = false

    if (await loading === false) {
        query.skip = true
    }

    if (await result) {
        showSuccess()
    }
}
</script>

<template>
    <Toast />
    <AppFrame full-width>
        <h3 class="text-lg font-bold text-gray-600 mb-4">Vollständiger Export</h3>
        <div class="sm:flex">
            <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0">
                <ApolloQuery :query="query" @error="onError" :skip="true">
                    <template #default="{ query, result, loading, error }">
                        <ValidationMessage v-if="error" :response="error" />
                        <Button @click="generateExport(query, loading, result)" :loading="loading"
                            label="Export generieren" />
                    </template>
                </ApolloQuery>
            </div>
            <div class="sm:w-1/2 sm:pl-4 prose prose-sm">
                <h3>Export generieren</h3>
                <p>Sie möchten Ihre Daten exportieren? Kein Problem! Klicken Sie einfach auf den Button "Export generieren".
                    Die Generierung des Exports erfolgt im Hintergrund. Sobald der Export fertig ist, werden Sie per E-Mail
                    darüber informiert.</p>
                <h3>Datensicherheit</h3>
                <p>Die exportierten Daten sind sicher verschlüsselt, um Ihre Privatsphäre zu schützen. Zum Entschlüsseln
                    müssen Sie die im Export mitgelieferte Anwendung nutzen.</p>
                <h3>Passwort für Entschlüsselung</h3>
                <p>Zum Entschlüsseln Ihrer Daten müssen Sie Ihr aktuelles Passwort oder das Wiederherstellungspasswort des
                    Beschäftigungsgebers verwenden. Aus Sicherheitsgründen ist es nicht möglich, Passwörter von anderen
                    Inhabern für diesen Export zu verwenden.</p>
                <h3>Und so geht's:</h3>
                <ol>
                    <li>Klicken Sie auf den Button "Export generieren".</li>
                    <li>Warten Sie, bis der Export fertig ist.</li>
                    <li>Öffnen Sie die E-Mail, die Sie von uns erhalten haben.</li>
                    <li>Klicken Sie auf den Link in der E-Mail.</li>
                    <li>Laden Sie den Export als Zip-Archiv herunter.</li>
                    <li>Entpacken Sie die Zip-Datei</li>
                    <li>Entschlüsseln Sie den Export mit der mitgelieferten Anwendung.</li>
                </ol>
                <div>
                    <InlineMessage severity="warn"><strong>Hinweis:</strong> Der Export kann einige Zeit in Anspruch nehmen. Bitte haben Sie etwas Geduld.</InlineMessage>
                </div>
                <div class="mt-4">
                    <InlineMessage class="mt-4" severity="info">Es werden verschiedene Versionen der Anwendung für Windows und Mac OS X sowie für verschiedene Rechnerarchitekturen mitgeliefert.</InlineMessage>
                </div>
            </div>
        </div>
    </AppFrame>
</template>
