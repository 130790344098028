<script setup lang="ts">
import type { Healthcheck } from '@/types'
import type { Ref, ComputedRef } from 'vue'

import { ref, computed, inject, onMounted } from 'vue'
import { onClickOutside, useWindowSize } from '@vueuse/core'
import Message from 'primevue/message';
import Button from 'primevue/button'

import AppTenantMenu from '@/components/AppTenantMenu.vue'
import UserAccountMenu from '@/components/UserAccountMenu.vue'
import AppDialog from '@/components/AppDialog.vue'
import AppConfig from '../config'

const currentUserRole = ref(AppConfig.currentUserRole)
const hasPermission = computed(() => currentUserRole.value === 'owner')

const { width } = useWindowSize()
const showSettings: Ref<boolean> = ref(false)
const target = ref(null)
const paymentModalVisible = ref(false)

const getHealthcheck: Function | undefined = inject('getHealthcheck')
const healthcheck: ComputedRef<Healthcheck | null> = computed(() => getHealthcheck ? getHealthcheck() : null)

onMounted(() => {
    if (AppConfig.incompletePayment && hasPermission.value) {
        paymentModalVisible.value = true
    }
})

const redirectToPaymentPage = function () {
    window.location.href = AppConfig.paymentUrl
}

onClickOutside(target, (event) => width.value < 1280 && (showSettings.value = false))
</script>

<template>
    <div class="transition-all relative flex items-center py-2 px-4 md:p-3 md:items-stretch md:flex-col md:h-screen w-full md:w-20 xl:w-1/3 xl:max-w-xs xl:p-4 shadow-lg bg-primary-900 text-white">
        <AppTenantMenu />
        <nav class="mx-auto md:mx-0">
            <ul class="flex px-2 sm:px-4 md:px-0 md:block">
                <li class="mx-0.5 md:mx-0 md:mb-2">
                    <router-link
                        to="/home"
                        class="flex items-center justify-center md:h-14 xl:justify-start xl:h-auto font-medium text-gray-200 p-2 rounded hover:bg-white/20 active:bg-white/10"
                        active-class="bg-primary-500 hover:bg-primary-600 active:bg-primary-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6 xl:h-5 xl:w-5 mr-0 xl:mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10" />
                        </svg>
                        <span class="hidden xl:inline">Übersicht</span>
                    </router-link>
                </li>
                <li class="mx-0.5 md:mx-0 md:mb-2">
                    <router-link
                        to="/reports"
                        class="flex items-center justify-center md:h-14 xl:justify-start xl:h-auto font-medium text-gray-200 p-2 rounded hover:bg-white/20 active:bg-white/10"
                        active-class="bg-primary-500 hover:bg-primary-600 active:bg-primary-500">
                        <span class="relative">
                            <span
                                v-if="healthcheck && healthcheck.alerts > 0"
                                class="absolute -right-1.5 -top-2 xl:right-1 bg-red-600 shadow text-white rounded-full text-[10px] text-xxs font-medium flex justify-center items-center leading-none w-4 h-4">
                                {{ healthcheck.alerts }}
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6 xl:h-5 xl:w-5 mr-0 xl:mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z" />
                            </svg>
                        </span>
                        <span class="hidden xl:inline">Alle Verfahren</span>
                    </router-link>
                </li>
                <!-- <li class="mx-0.5 md:mx-0 md:mb-2">
                    <router-link
                        to="/material"
                        class="flex items-center justify-center md:h-14 xl:justify-start xl:h-auto font-medium text-gray-200 p-2 rounded hover:bg-white/20 active:bg-white/10"
                        active-class="bg-primary-500 hover:bg-primary-600 active:bg-primary-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6 xl:h-5 xl:w-5 mr-0 xl:mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                        </svg>
                        <span class="hidden xl:inline">Material</span>
                    </router-link>
                </li> -->
                <li class="mx-0.5 md:mx-0 md:mb-2">
                    <router-link
                        to="/portals"
                        class="flex items-center justify-center md:h-14 xl:justify-start xl:h-auto font-medium text-gray-200 p-2 rounded hover:bg-white/20 active:bg-white/10"
                        active-class="bg-primary-500 hover:bg-primary-600 active:bg-primary-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6 xl:h-5 xl:w-5 mr-0 xl:mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
                        </svg>
                        <span class="hidden xl:inline">Meldeportale</span>
                    </router-link>
                </li>
                <li
                    class="mx-0.5 md:mx-0 md:mb-2 relative"
                    ref="target">
                    <button
                        class="flex w-full relative items-center justify-center md:h-14 xl:justify-start xl:h-auto font-medium text-gray-200 p-2 rounded hover:bg-white/20 active:bg-white/10"
                        :class="{ 'bg-white/20 bg-white/10': showSettings }"
                        @click.prevent="() => showSettings = !showSettings">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6 xl:h-5 xl:w-5 mr-0 xl:mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg>
                        <span class="hidden xl:inline">Konfiguration</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-3 h-3 ml-auto pointer-events-none absolute right-0 bottom-0 md:right-1 md:bottom-1 md:w-4 md:h-4 xl:static xl:bg-transparent xl:w-5 xl:h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </button>
                    <ul v-if="showSettings"
                        class="bg-white space-y-1 rounded-lg text-black justify-start items-center z-20 absolute text-sm w-72 -right-1/2 top-9 md:rounded-lg md:text-base md:right-auto md:top-0 md:left-16 shadow-2xl xl:w-auto xl:static xl:shadow-none p-4 xl:pl-8 xl:pt-3 xl:bg-transparent">
                        <li v-if="hasPermission" class="mx-0.5 md:mx-0 md:mb-2">
                            <router-link
                                to="/users"
                                class="block justify-start h-auto font-medium text-black xl:text-gray-200 p-2 rounded hover:bg-black/20 active:bg-black/10 xl:hover:bg-white/20 xl:active:bg-white/10"
                                active-class="!bg-primary-500 !hover:bg-primary-600 !active:bg-primary-500 !text-white">
                                <span>Benutzer</span>
                            </router-link>
                        </li>
                        <li v-if="hasPermission" class="mx-0.5 md:mx-0 md:mb-2">
                            <router-link
                                to="/categories"
                                class="block justify-start h-auto font-medium text-black xl:text-gray-200 p-2 rounded hover:bg-black/20 active:bg-black/10 xl:hover:bg-white/20 xl:active:bg-white/10"
                                active-class="!bg-primary-500 !hover:bg-primary-600 !active:bg-primary-500">
                                <span>Kategorien</span>
                            </router-link>
                        </li>
                        <li class="mx-0.5 md:mx-0 md:mb-2">
                            <router-link to="/message-templates"
                                class="block justify-start h-auto font-medium text-black xl:text-gray-200 p-2 rounded hover:bg-black/20 active:bg-black/10 xl:hover:bg-white/20 xl:active:bg-white/10"
                                active-class="!bg-primary-500 !hover:bg-primary-600 !active:bg-primary-500">
                                <span>Antwortvorlagen</span>
                            </router-link>
                        </li>
                        <li v-if="hasPermission" class="mx-0.5 md:mx-0 md:mb-2">
                            <router-link
                                to="/organization-settings"
                                class="block justify-start h-auto font-medium text-black xl:text-gray-200 p-2 rounded hover:bg-black/20 active:bg-black/10 xl:hover:bg-white/20 xl:active:bg-white/10"
                                active-class="!bg-primary-500 !hover:bg-primary-600 !active:bg-primary-500 !text-white">
                                <span>Einstellungen</span>
                            </router-link>
                        </li>
                        <li v-if="$appConfig.isSaas && hasPermission" class="mx-0.5 md:mx-0 md:mb-2 flex items-center">
                            <a
                            :href="'/' + $appConfig.tenantSlug + '/customer-portal'"
                            :style="{ 'pointer-events': $appConfig.onTrial ? 'none' : 'auto' }"
                            :class="{ 'opacity-50': $appConfig.onTrial }"

                            class="block justify-start h-auto font-medium text-black xl:text-gray-200 p-2 rounded hover:bg-black/20 active:bg-black/10 xl:hover:bg-white/20 xl:active:bg-white/10"
                            active-class="!bg-primary-500 !hover:bg-primary-600 !active:bg-primary-500">
                                Vertragsverwaltung
                            </a>
                            <i
                                v-if="$appConfig.onTrial"
                                v-tooltip.bottom="'In der Vertragsverwaltung können Sie Ihre Kundendaten und das Abonnement verwalten sowie Rechnungen einsehen. Sobald Sie ein Abonnement abgeschlossen haben, können Sie die Vertragsverwaltung nutzen.'"
                                class="pi pi-info-circle text-slate-500 xl:text-slate-400"
                            ></i>
                        </li>
                    </ul>
                </li>
            </ul>
        </nav>
        <div class="md:mt-auto">
            <div v-if="healthcheck && healthcheck.recrypt_required" class="hidden text-sm xl:flex p-4 bg-orange-400 text-white rounded-lg my-4 relative">
                <div class="absolute -top-2 -left-2 p-1 shadow-md bg-orange-300 text-white rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 flex-shrink-0">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                    </svg>
                </div>
                <div>
                    Es ist nötig, dass Sie eine Neuverschlüsselung anstoßen.
                    <router-link to="/portals" class="mt-1 underline underline-offset-2 hover:no-underline">Zur Neuverschlüsselung</router-link>
                </div>
            </div>

            <div v-if="AppConfig.onTrial" class="hidden text-sm xl:flex p-4 bg-primary-400 text-white rounded-lg my-4 relative">
                <div class="absolute -top-2 -left-2 p-1 shadow-md bg-primary-300 text-white rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 flex-shrink-0" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
                <div v-if="AppConfig.launchPeriodTrial">
                    Derzeit befinden Sie sich in der Einführungsphase von compentum. Bis Inkrafttreten des HinSchG können Sie den Dienst kostenlos nutzen.
                    <router-link to="/welcome" class="mt-1 underline underline-offset-2 hover:no-underline">Mehr erfahren</router-link>
                    <div v-if="hasPermission" class="flex justify-end mt-4">
                        <a
                            :href="`/${AppConfig.tenantSlug}/checkout/customer`"
                            class="inline-flex items-center px-2 py-1 font-semibold bg-white rounded text-blue-500 hover:bg-white/80 active:bg-white/90">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                            </svg>
                            {{ 'Jetzt buchen' }}
                        </a>
                    </div>
                </div>
                <div v-else-if="AppConfig.unlimitedTrial">
                    Sie befinden sich aktuell in der <strong>unbegrenzten Demo-Version</strong> von compentum.
                </div>
                <div v-else-if="AppConfig.onTrial">
                    Sie befinden sich aktuell in der <strong>Demo-Version</strong> von compentum. Die Demo ended am <strong>{{ AppConfig.trialEndsAt }}</strong>.
                    <div v-if="hasPermission" class="flex justify-end mt-4">
                        <a
                            :href="`/${AppConfig.tenantSlug}/checkout/customer`"
                            class="inline-flex items-center px-2 py-1 font-semibold bg-white rounded text-blue-500 hover:bg-white/80 active:bg-white/90">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                            </svg>
                            {{ 'Jetzt buchen' }}
                        </a>
                    </div>
                </div>
            </div>

            <div v-if="hasPermission && healthcheck && healthcheck.user_and_invite_count > healthcheck.user_count_limit" class="hidden text-sm xl:flex p-4 bg-amber-600 rounded-lg my-4 relative">
                <div class="absolute -top-2 -left-2 p-1 shadow-md bg-amber-600 text-white rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 flex-shrink-0">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                    </svg>
                </div>
                <div>
                    Wir haben festgestellt, dass mehr Benutzer (inkl. offenen Einladungen) der Organisation angehören, als durch den gewählten Tarif erlaubt sind.
                    <br /><br />
                    Benutzer und Einladungen: <strong>{{ healthcheck.user_and_invite_count }}</strong>
                    <br />
                    Maximal erlaubt: <strong>{{ healthcheck.user_count_limit }}</strong>
                    <br /><br />
                    Bitte buchen Sie zusätzliche Benutzerplätze oder entfernen Sie nicht mehr benötigte Benutzer bzw. Einladungen.
                    <div class="flex justify-end mt-4">
                        <router-link
                            to="/users"
                            class="inline-flex items-center px-2 py-1 font-semibold bg-white rounded text-amber-600 hover:bg-white/80 active:bg-white/90">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                            </svg>
                            {{ 'Zue Benutzerverwaltung' }}
                        </router-link>
                    </div>
                </div>
            </div>

            <div v-if="hasPermission && healthcheck && healthcheck.portal_count > healthcheck.portal_count_limit" class="hidden text-sm xl:flex p-4 bg-amber-600 rounded-lg my-4 relative">
                <div class="absolute -top-2 -left-2 p-1 shadow-md bg-amber-600 text-white rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 flex-shrink-0">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                    </svg>
                </div>
                <div>
                    Wir haben festgestellt, dass mehr Meldeportale in der Organisation existieren, als durch den gewählten Tarif erlaubt sind.
                    <br /><br />
                    Portale: <strong>{{ healthcheck.portal_count }}</strong>
                    <br />
                    Maximal erlaubt: <strong>{{ healthcheck.portal_count_limit }}</strong>
                    <br /><br />
                    Bitte buchen Sie zusätzliche Meldeportale oder entfernen Sie nicht mehr benötigte Meldeportale.
                    <div class="flex justify-end mt-4">
                        <router-link
                            to="/portals"
                            class="inline-flex items-center px-2 py-1 font-semibold bg-white rounded text-amber-600 hover:bg-white/80 active:bg-white/90">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                            </svg>
                            {{ 'Zur Portalverwaltung' }}
                        </router-link>
                    </div>
                </div>
            </div>

            <div v-if="hasPermission && AppConfig.incompletePayment" class="hidden text-sm xl:flex p-4 bg-amber-600 rounded-lg my-4 relative">
                <div class="absolute -top-2 -left-2 p-1 shadow-md bg-amber-600 text-white rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 flex-shrink-0">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z" />
                    </svg>
                </div>
                <div>
                    Ihre Zahlung für den Beschäftigungsgegeber {{ AppConfig.tenantName }} ist fehlgeschlagen. Bitte aktualisieren Sie Ihre Zahlungsmethode und versuchen Sie es erneut.
                    <br /><br />
                    Hinweis: Bitte schließen Sie die Zahlung so schnell wie möglich ab, um den Zugriff auf den Dienst nicht zu verlieren.
                    <div class="flex justify-end mt-4">
                        <a
                            :href="AppConfig.paymentUrl"
                            class="inline-flex items-center px-2 py-1 font-semibold bg-white rounded text-amber-600 hover:bg-white/80 active:bg-white/90">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 mr-1">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                            </svg>
                            {{ 'Zur Vertragsverwaltung' }}
                        </a>
                    </div>
                </div>
                <AppDialog title="Ihre Zahlung ist fehlgeschlagen" v-model="paymentModalVisible">
                    <Message severity="warn" :closable="false">
                        <p class="ml-1">
                            Ihre Zahlung für den Beschäftigungsgegeber {{ AppConfig.tenantName }} ist fehlgeschlagen. Bitte aktualisieren Sie Ihre Zahlungsmethode und versuchen Sie es erneut.
                        </p>
                    </Message>
                    <p>
                        Hinweis: Bitte schließen Sie die Zahlung so schnell wie möglich ab, um den Zugriff auf den Dienst nicht zu verlieren.
                    </p>
                    <template #footer>
                        <Button label="Später abschließen" outlined @click="paymentModalVisible = false" />
                        <Button label="Zur Vertragsverwaltung" @click="redirectToPaymentPage()" autofocus />
                    </template>
                </AppDialog>
            </div>
            <UserAccountMenu />
        </div>
    </div>
</template>
