<script setup lang="ts">
import { computed, ref } from 'vue'
import type { ComputedRef } from 'vue'
import Message from 'primevue/message'
import Button from 'primevue/button'
import APP_CONFIG from '@/config'
import { saveReportKit } from '@/services/pdf'
import type { ReportKitOptions } from '@/services/pdf'

const props = defineProps<{
    passphrase: string[]
    identifier: string,
    confirmMessage: string,
    portalUrl?: string | null
}>()

const finalPassphrase: ComputedRef<string> = computed(
    (): string => `${props.identifier}-${props.passphrase.join('-')}`
);

const savePassphrase = async () => {
    const { portalUrl, locale } = APP_CONFIG
    const reportKit: ReportKitOptions = {
        portalUrl: props.portalUrl ? props.portalUrl : `${location.protocol}//${location.host}${portalUrl}`,
        reportKey: finalPassphrase.value
    }

    const pdfTemplate = `/img/compentum-verfahrens-passwort_${locale}.pdf`
    const pdfBytes= await saveReportKit(pdfTemplate, reportKit)

    const a = document.createElement("a");

    a.href = window.URL.createObjectURL(new Blob([pdfBytes], {type: 'application/pdf'}))
    a.download = 'compentum-verfahren-passphrase.pdf'
    a.click()
}

defineExpose(props)
</script>

<template>
    <div>
        <Message severity="success" :closable="false">
            <slot name="successMessage">{{ $t("reportConfirmation.successMessage") }}</slot>
        </Message>
        <div class="prose-sm md:prose mb-8 mt-12">
            <slot name="description">
                <p class="whitespace-pre-wrap">
                    {{ confirmMessage }}
                </p>

                <h3>{{ $t("reportConfirmation.headerPassword") }}</h3>
                <p>{{ $t("reportConfirmation.description[0]") }}</p>
                <p>{{ $t("reportConfirmation.description[1]") }}</p>

                <h3>{{ $t("reportConfirmation.headerPersonalPassword") }}</h3>
            </slot>
        </div>
        <div class="p-4 bg-slate-200 text-lg tracking-wide md:text-xl font-mono font-bold md:tracking-widest mb-6">
            {{ finalPassphrase }}
        </div>
        <Button :label="$t('reportConfirmation.downloadLabel')" @click.prevent="savePassphrase" />
    </div>
</template>
