<script setup lang="ts">
import type { Ref, ComputedRef } from 'vue'
import type { Healthcheck } from '@/types'
import { computed, inject, ref, defineProps, defineEmits, watch } from 'vue'
import { useQuery, useResult } from "@vue/apollo-composable"
import gql from 'graphql-tag'
import { useVuelidate } from "@vuelidate/core"
import { email as emailValidator, required } from "@vuelidate/validators";
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Message from 'primevue/message'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Checkbox from 'primevue/checkbox'
import AppConfig from '@/config'


const isSaas: Ref<boolean> = ref(AppConfig.isSaas)

const rules = {
    inviteEmailAddress: { required, emailValidator }
}

const mutation = isSaas.value
    ? gql`
        mutation sendInvite ($email: String!, $upgradePlan: Boolean) {
            sendInvite (input: { email: $email, upgradePlan: $upgradePlan }) {
                id, email, expires_at, is_valid
            }
        }
    `
    : gql`
        mutation sendInvite ($email: String!) {
            sendInvite (input: { email: $email }) {
                id, email, expires_at, is_valid
            }
        }
    `

const query = gql`
    query invites {
        invites { id, email, expires_at, is_valid }
    }
`

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const success: Ref<boolean> = ref(false)
const inviteEmailAddress: Ref<string | null> = ref(null)
const upgradePlan: Ref<boolean> = ref(false)
const v$ = useVuelidate(rules, { inviteEmailAddress })
const visible = ref(props.modelValue)

const getHealthcheck: Function | undefined = inject('getHealthcheck')
const healthcheck: ComputedRef<Healthcheck | null> = computed(() => getHealthcheck ? getHealthcheck() : null)
const maxUserLimit = computed(() => healthcheck.value?.user_count_limit || 3)
const currentUsersCount = computed(() => healthcheck.value?.user_and_invite_count || 1)
const maxReached = computed(() => currentUsersCount.value >= maxUserLimit.value)
const refreshHealthcheck: Function | undefined = inject('refreshHealthcheck')

watch(() => props.modelValue, (val) => visible.value = val)
watch(visible, (val) => emit('update:modelValue', val))

const formatDate = (value) => {
    return new Date(value).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
};

const onDone = ({ data: { sendInvite } }) => {
    if (sendInvite) {
        success.value = true
        inviteEmailAddress.value = null
        upgradePlan.value = false
        refetch()
        refreshHealthcheck?.()
    }
}

const { result, loading, error, refetch } = useQuery(query)
</script>

<template>
    <Dialog
        v-model:visible="visible"
        :header="`Benutzer zu ${$appConfig.tenantName} einladen`"
        position="top"
        modal
        :dismissable-mask="true"
        :close-on-escape="true"
        :draggable="false"
        style="max-width: 821px;">
        <ApolloMutation
            :mutation="mutation"
            :variables="{ email: inviteEmailAddress, upgradePlan }"
            @done="onDone"
        >
            <template v-slot="{ mutate, loading, error }">
                <Message v-if="error" severity="error">{{ error.message }}</Message>
                <Message v-if="success" severity="success">Eine Einladung wurde an die angegebene E-Mail-Adresse verschickt.</Message>
                <form method="post" autocomplete="on" @submit.prevent="() => !v$.$invalid && mutate()">
                    <div class="p-inputgroup my-4">
                        <InputText v-model="inviteEmailAddress"  placeholder="E-Mail" />
                        <Button type="submit" icon="pi pi-send" :loading="loading" :disabled="v$.$invalid" />
                    </div>
                    <div class="flex">
                        <span class="relative top-0 mr-2">
                            Der Benutzer wird als Ombudsperson eingeladen. <br />
                            Ombudspersonen können Meldeportalen zugewiesen werden und verwalten die eingehenden Meldungen. <br />
                            Sie haben keinen Zugriff auf die Benutzer-, Portal- und Abrechnungsverwaltung.
                        </span>
                    </div>
                    <div class="flex items-center flex-wrap mt-4" v-if="isSaas && maxReached">
                        <Checkbox v-model="upgradePlan" inputId="upgrade" binary/>
                        <label for="upgrade" class="ml-2">Kostenpflichtig zusätzlichen Benutzerplatz buchen</label>
                        <small class="mt-2 basis-full">Der Betrag wird anteilig für die Restlaufzeit Ihres Vertrags berechnet und die angegebene Zahlungsmethode damit belastet. Dieses Upgrade wird automatisch zum Ende der Laufzeit verlängert und kann jederzeit wieder heruntergestuft werden. Weitere Informationen zu den Preisen finden Sie <a class="underline" href="https://compentum.de/preise">hier</a>.</small>
                    </div>
                </form>
            </template>
        </ApolloMutation>
    </Dialog>

    <Message v-if="error" severity="error">There was an error retrieving the invite data.</Message>
    <div v-if="result && result.invites.length" class="mb-12 mt-8">
        <h2 class="text-lg text-gray-600 font-bold mb-4">
            <span class="text-neutral-500 font-normal">({{ result.invites.length }})</span>
            Offene Einladungen
        </h2>
        <DataTable :value="result.invites">
            <Column field="email" header="E-Mail"></Column>
            <Column field="expires_at" header="Gültig bis">
                <template #body="slotProps">
                    <span v-if="!slotProps.data.is_valid">abgelaufen</span>
                    <span v-else="slotProps.data.is_valid">{{ formatDate(slotProps.data.expires_at) }}</span>
                </template>
            </Column>
            <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                <template #body="slotProps">
                    <ApolloMutation
                        :mutation="gql`
                            mutation deleteInvite ($id: ID!) {
                                deleteInvite (id: $id) { id }
                            }
                        `"
                        :variables="{ id: slotProps.data.id  }"
                        @done="() => {
                            refetch()
                            refreshHealthcheck?.()
                        }"
                    >
                        <template v-slot="{ mutate, loading }">
                            <Button type="button" icon="pi pi-times" v-tooltip.bottom="'Einladung stornieren'" class="p-button-rounded p-button-text" :loading="loading" @click="mutate" />
                        </template>
                    </ApolloMutation>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
