<script setup lang="ts">
import type { Ref } from 'vue'
import { ref, defineProps, defineExpose, defineEmits, nextTick } from 'vue'
import gql from 'graphql-tag'
import Dropdown from 'primevue/dropdown'

import ValidationMessage from '@/components/ValidationMessage.vue'

interface Role {
    id: String,
    name: String,
}

const props = defineProps<{ userId: String, currentRole: Role, disabled: boolean }>()
const emit = defineEmits(['done'])
const options: Ref<Array<Role>> = ref([
    { id: 'owner', name: 'Inhaber' },
    { id: 'employee', name: 'Ombudsperson' }
])
const selectedRole: Ref<String> = ref(props.currentRole.id)

defineExpose(props)
</script>

<template>
    <ApolloMutation
        :mutation="gql`
            mutation updateMemberRole ($id: ID!, $roleName: String!) {
                updateMemberRole (input: { id: $id, role: $roleName }) {
                    user {
                        id,
                        role {
                            id
                            name
                        }
                    }
                }
            }
        `"
        :variables="{
            id: userId,
            roleName: selectedRole
        }"
        @done="emit('done')"
    >
        <template v-slot="{ mutate, loading, error }">
            <div v-tooltip.bottom="{ value: 'Sie können die Rolle von inaktiven Benutzer nicht ändern.', disabled: !props.disabled }">
                <ValidationMessage v-if="error" :response="error" :toast="true" />
                <Dropdown
                    v-model="selectedRole"
                    :options="options"
                    :loading="loading"
                    :disabled="props.disabled ?? false"
                    optionLabel="name"
                    optionValue="id"
                    placeholder="Rolle wählen"
                    @change="async () => {
                        await nextTick()
                        await mutate()
                    }" />
            </div>
        </template>
    </ApolloMutation>
</template>
