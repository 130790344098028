<script setup lang="ts">
import { computed } from 'vue'
import type { Ref } from 'vue'
import type { Message } from './message'
import type { User, ContentEncrypted } from '@/containers/Report/reportKeys'
import AudioInlinePlayer from '@/components/Audio/AudioInlinePlayer.vue'

import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { ref } from 'vue'
import Skeleton from 'primevue/skeleton'
import PrimeMessage from 'primevue/message'

import { decryptContent } from '@/containers/Report/reportKeys'
import File from '@/containers/File/File.vue'

const props = defineProps<{
    data: Message,
    shareKeys: Record<string, string>
    user: User
}>()

const isDecrypting: Ref<boolean> = ref(false)
const isOurMessage: Ref<boolean> = ref(
       (props.user.id === 'wb' && props.data.from_submitter)
    || (props.user.id !== 'wb' && !props.data.from_submitter)
)
const messageContent: Ref<string> = ref('')
const errorMessage: Ref<string | null> = ref(null)

const audioFiles = computed(() => {
    return props.data.attachments?.filter(attachment => attachment.is_voice_message)
})

const decryptMessageContent = async () => {
    isDecrypting.value = true
    errorMessage.value = null

    try {
        const encryptedContent: ContentEncrypted = {
            content: props.data.content,
            iv: props.data.iv,
            shareKeys: props.shareKeys,
        }

        const clearText = await decryptContent(
            encryptedContent,
            props.user
        )
        messageContent.value = clearText
    } catch(err) {
        if (err instanceof Error) {
                console.error(err.message)
        } else {
            console.error(err)
        }
        errorMessage.value = 'Nachricht konnte nicht entschlüsselt werden.'
    }

    isDecrypting.value = false
}

decryptMessageContent()
defineExpose(props)
</script>

<template>
    <div
        class="p-4 rounded-lg border"
        :class="{
            'bg-slate-50 border-slate-200': !isOurMessage,
            'bg-primary-50 border-primary-200': isOurMessage,
            'border-dashed border-2': data.is_internal,
        }">
        <div
            class="ml-0 mb-2 pb-2 border-b text-xs flex justify-between"
            :class="{ 'border-b-slate-200 text-slate-600': !isOurMessage, 'border-b-primary-200 text-primary-800': isOurMessage }">
            <span v-if="data.from_submitter && data.created_at">{{ format(parseISO(data.created_at), 'dd.MM.yyyy HH:mm') }} Uhr</span>
            <span v-else-if="data.created_at">{{ format(parseISO(data.created_at), 'dd.MM.yyyy HH:mm') }} Uhr</span>
            <span v-if="data.is_internal" class="text-white bg-primary-700 font-medium p-0.5 px-1 text-xs rounded">{{'Interne Notiz'}}</span>

            <span v-if="props.user.id !== 'wb' && data.author" class="flex items-center">
                {{ data.author.is_trashed ? $t('components.messages.message.deletedUser') : data.author.full_name }}
            </span>
            <span v-else class="flex items-center">
                {{ data.from_submitter ? $t('components.messages.message.whistleblower') : $t('components.messages.message.station') }}
            </span>
        </div>
        <PrimeMessage v-if="errorMessage" :closable="false" severity="error">{{ errorMessage }}</PrimeMessage>
        <div v-if="isDecrypting">
            <Skeleton class="mb-2" />
            <Skeleton class="mb-2 !w-2/3" />
            <Skeleton class="!w-4/5" />
        </div>
        <div v-if="messageContent" class="prose-sm md:prose max-w-none md:max-w-none whitespace-pre-line break-words">{{ messageContent }}</div>
        <div
            v-if="data.attachments && data.attachments.length > 0"
            class="mt-4 pt-4 border-t border-t-slate-200">
            <h3 class="text-slate-800 font-medium flex items-center mb-2">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-1">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                </svg>
                {{ $t('components.messages.form.attachementHeader') }}
            </h3>
            <small><b>{{ $t("components.messages.message.malwareInfoHeader") }}</b>{{ $t("components.messages.message.malwareInfoContent") }}</small>
            <ul class="text-slate-600 list-disc pl-5 mt-2">
                <li v-for="(attachment, key) in data.attachments" :key="attachment.id ? attachment.id : key">
                    <File
                        :item="attachment"
                        :user="user"
                        :shareKeys="shareKeys"
                        :readonly="attachment.is_voice_message" />
                </li>
            </ul>
        </div>

        <div v-if="audioFiles && audioFiles.length > 0" class="mt-4 pt-4 border-t border-t-slate-200">
            <h3 class="text-slate-800 font-medium flex items-center mb-2">
                <i class="pi pi-microphone"></i>
                {{ $t("components.messages.message.audioHeader") }}
            </h3>
            <ul class="text-slate-600 pl-0 list-none">
                <li v-for="(audio, key) in audioFiles" :key="key" class="my-3">
                    <AudioInlinePlayer
                        :item="audio"
                        :user="user"
                        :shareKeys="shareKeys" />
                </li>
            </ul>
        </div>
    </div>
</template>
