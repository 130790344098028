<script setup lang="ts">
import type { Ref } from 'vue'
import type { User } from '@/containers/Report/reportKeys'

import gql from 'graphql-tag'
import { ref, nextTick, defineProps } from 'vue'
import { email as emailValidator, required, minLength, maxLength, sameAs } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import Password from 'primevue/password'
import Button from 'primevue/button'
import Message from 'primevue/message'
import { useLazyQuery } from "@vue/apollo-composable"

import { StorageService, PiniaStorage } from '@/services/storage'
import { useKeyStore } from '@/services/storage/keyStorage'

import { CryptoService } from '../../services/index'
import ValidationMessage from '@/components/ValidationMessage.vue'
import InputGroup from '@/components/Form/InputGroup.vue'
import { decrcyptPrivateKey } from '@/containers/Report/reportKeys'
import AppFrame from '@/components/AppFrame.vue'

const keyStore = useKeyStore()

const store = new StorageService<User>({
    storage: new PiniaStorage(keyStore)
})

const props = defineProps({
    userResult: {
        type: Object,
        required: true
    }
})

const currentPassword: Ref<string> = ref('')
const newPassword: Ref<string | null> = ref('')
const newPasswordConfirmation: Ref<string | null> = ref('')
const success = ref(false)

const privateKey: Ref<string | null> = ref(null)
const iv: Ref<string | null> = ref(null)
const salt: Ref<string | null> = ref(null)
const isGeneratingKeyPair: Ref<boolean> = ref(false)
const currentUser: Ref<User | null> = ref(null)
const passwordValidationMessage: Ref<string | null> = ref(null)

const rules = {
    currentPassword: { required },
    newPassword: { required, minLength: minLength(8), maxLength: maxLength(128) },
    newPasswordConfirmation: { required, sameAsPassword: sameAs(newPassword) },
}
const v$ = useVuelidate(rules, { currentPassword, newPassword, newPasswordConfirmation })

const mutation = gql`
    mutation updatePassword ($currentPassword: String!, $newPassword: String!, $newPasswordConfirmation: String!, $privateKey: String!, $salt: String!, $iv: String! ) {
        updatePassword (input: { current_password: $currentPassword, password: $newPassword, password_confirmation: $newPasswordConfirmation, private_key: $privateKey, salt: $salt, iv: $iv }) {
            status
            message
            redirectTo
            user {
                id
                first_name
                last_name
                email
            }
        }
    }
`

// Crypto
const cryptoService = new CryptoService()

/**
 * Re-encrypt user private key, if he changes his password
 */
const updateUserKeys = async (password: string) => {
    // Get current users private key and decrypt with current password
    const { currentUser: user, error } = await decrcyptPrivateKey(props.userResult.me, currentPassword.value, store)
    passwordValidationMessage.value = error

    if (error) {
        isGeneratingKeyPair.value = false
    }

    const { privateKeyJwk } = user

    if (user) {
        currentUser.value = user
    }


    // Salt for AES key
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const iv = window.crypto.getRandomValues(new Uint8Array(12))

    // Generate a secure key off a password
    const key = await cryptoService.getKeyFromPassword(password)
    const aesKey = await cryptoService.getAESKeyFromPBKDF(key, salt)
    const encryptedPrivateKey = await cryptoService.encryptPrivateKey(privateKeyJwk, aesKey, iv)

    return {
        privateKey: cryptoService.arrayBufferToBase64(encryptedPrivateKey),
        iv: cryptoService.arrayBufferToBase64(iv),
        salt: cryptoService.arrayBufferToBase64(salt)
    }
}

const beforeSubmit = async (mutate: Function) => {
    if (newPassword.value === null) {
        return
    }

    isGeneratingKeyPair.value = true
    const wallet = await updateUserKeys(newPassword.value)
    privateKey.value = wallet.privateKey
    iv.value = wallet.iv
    salt.value = wallet.salt

    await nextTick()
    isGeneratingKeyPair.value = false
    await mutate()
}

const onDone = ({ data: { updatePassword } }) => {
    if (updatePassword.status === 'success') {
        success.value = true
    }
}
</script>

<template>
    <AppFrame :full-width="true">
    <h3 class="text-lg font-bold text-gray-600 mb-4">Account Passwort ändern</h3>
        <div class="sm:flex">
            <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0">
                <ApolloMutation
                    :mutation="mutation"
                    :variables="{ currentPassword, newPassword, newPasswordConfirmation, iv, salt, privateKey }"
                    @done="onDone">
                    <template v-slot="{ mutate, loading, error }">
                        <ValidationMessage v-if="error" :response="error" />
                        <Message v-if="success" severity="success">{{
                            'Ihr neues Passwort wurde gespeichert.'
                        }}</Message>
                        <form method="post" autocomplete="on" @submit.prevent="beforeSubmit(mutate)">
                            <InputGroup>
                                <Password v-model="currentPassword" autocomplete="off" placeholder="Aktuelles Passwort" name="password" :toggle-mask="true" :class="['block mr-2', { 'p-invalid': passwordValidationMessage }]" />
                                <p class="mt-2 text-red-600 text-sm">{{ passwordValidationMessage }}</p>
                            </InputGroup>
                            <InputGroup>
                                <Password v-model="newPassword" autocomplete="off" placeholder="Neues Passwort" name="password" :toggle-mask="true" />
                            </InputGroup>
                            <InputGroup>
                                <Password v-model="newPasswordConfirmation" autocomplete="off" placeholder="Neues Passwort bestätigen" name="password_confirmation" :toggle-mask="true" :feedback="false" />
                            </InputGroup>

                            <Button :disabled="v$.$invalid" :loading="loading || isGeneratingKeyPair" type="submit" class="block w-full" label="Neues Passwort speichern" />
                        </form>
                    </template>
                </ApolloMutation>
            </div>
            <div class="sm:w-1/2 sm:pl-4 prose prose-sm">
                <h3>Sicherheit</h3>
                <p>Ihr Passwort bildet das Fundament einer sicheren Ende-zu-Ende-Verschlüsselung. <a target="_blank" href="https://www.bsi.bund.de/DE/Themen/Verbraucherinnen-und-Verbraucher/Informationen-und-Empfehlungen/Cyber-Sicherheitsempfehlungen/Accountschutz/Sichere-Passwoerter-erstellen/sichere-passwoerter-erstellen_node.html">Wählen und speichern Sie daher Ihr Passwort sorgsam</a>.</p>

                <h3>Account und Datenwiederherstellung</h3>
                <p>Bei Verlust Ihres Passworts oder der Anmeldeinformationen, gibt es aus Sicherheitsgründen nur <a target="_blank" href="#">limitierte Wiederherstellungsmöglichkeiten</a>. Ziehen Sie daher die Nutzung eines <a target="_blank" href="#">Passwortmanagers</a> in Betracht oder sichern Sie Ihre Zugänge an einem geschützten Platz.</p>
            </div>
        </div>
    </AppFrame>
</template>
