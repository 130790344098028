<script lang="ts">
import { computed, ref, defineComponent } from 'vue'
import gql from 'graphql-tag'

import AuthLoginCompact from '@/containers/Auth/AuthLoginCompact.vue'

export default defineComponent({
    components: { AuthLoginCompact },
    provide() {
        return {
            getHealthcheck: () => this.healthcheck,
            refreshHealthcheck: this.refresh
        }
    },

    apollo: {
        healthcheck:{
            fetchPolicy: 'network-only',
            pollInterval: 10000,
            query: gql`
                query {
                    healthcheck { authenticated, alerts, recrypt_required, portal_count_limit, portal_count, portal_count_limit_default, email_verified, user_and_invite_count, user_count_limit, user_count_limit_default }
                }
            `
        }
    },

    data () {
        return {
            healthcheck: {
                authenticated: true,
                alerts: 0,
                recrypt_required: false,
                portal_count_limit_default: 3,
                portal_count_limit: 3,
            },
        }
    },

    methods: {
        refresh() {
            this.$apollo.queries.healthcheck.refresh()
        },

        onReLogin() {
            this.refresh()
        }
    }
})
</script>

<template>
    <div>
        <slot />
        <div
            v-if="!healthcheck?.authenticated"
            class="filter-none fixed inset-0 flex justify-center items-center">
            <div class="absolute inset-0 bg-gray-800"></div>
            <div class="bg-white rounded w-full max-w-md mx-auto z-10 relative p-4 md:p-8">
                <AuthLoginCompact @login="onReLogin" />
            </div>
        </div>
    </div>
</template>
