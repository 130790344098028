// @ts-nocheck
import { AsyncStorage } from './AsyncStorage'
import { StorageOptions } from './StorageOptions'

export class StorageService<S> implements StorageOptions<S> {
  public asyncStorage: boolean
  public storage: Storage | AsyncStorage
  public restoreState!: ((
        key: string,
        storage?: Storage | AsyncStorage
    ) => Promise<S> | S)

  public saveState!: ((
        key: string,
        state: Record<string, unknown>,
        storage?: Storage | AsyncStorage
    ) => Promise<void> | void)

  public constructor (options?: StorageOptions<S>) {
    if (typeof options === 'undefined') options = {} as StorageOptions<S>

    /**
     * Check if window.localStroage is available
     * because we default to it.
     */
    let localStorageAvailable = true

    try {
      window.localStorage.getItem('')
    } catch (err) {
      localStorageAvailable = false
    }

    /**
     * Set default storage
     */
    if (options.storage) {
      this.storage = options.storage
    } else if (localStorageAvailable) {
      this.storage = window.localStorage
    } else {
      throw new Error('No storage is provided nor window is available')
    }

    this.asyncStorage = options.asyncStorage || false

    if (this.asyncStorage) {
      this.setAsyncMethods(options)
    } else {
      this.setSyncMethods(options)
    }
  }

  private setAsyncMethods (options?: StorageOptions<S>): void {
    this.restoreState = options?.restoreState
      ? options?.restoreState
      : (key: string, storage: AsyncStorage) =>
          (storage || (this.storage as AsyncStorage))
            .getItem(key)
            .then(value =>
              typeof value === 'string'
                ? JSON.parse(value || '{}')
                : value || {}
            )

    this.saveState = options?.saveState
      ? options?.saveState
      : (key: string, state: Record<string, unknown>, storage: AsyncStorage) =>
          (storage || (this.storage as AsyncStorage)).setItem(
            key,
            this.asyncStorage ? state || {} : (JSON.stringify(state) as any)
          )
  }

  private setSyncMethods (options?: StorageOptions<S>): void {
    this.restoreState = options?.restoreState
      ? options?.restoreState
      : (key: string, storage: Storage) => {
          const value = (storage || (this.storage as Storage)).getItem(key)

          if (typeof value === 'string') {
            return JSON.parse(value)
          } else {
            return value || null
          }
        }

    this.saveState = options?.saveState
      ? options?.saveState
      : (key: string, state: Record<string, unknown>, storage: Storage) =>
          (storage || (this.storage as Storage)).setItem(
            key,
            JSON.stringify(state)
          )
  }
}
