<script setup lang="ts">
import { ref } from 'vue'
import type { Ref } from 'vue'
import gql from 'graphql-tag'
import { useQuery } from "@vue/apollo-composable"

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Button from 'primevue/button'
import Menu from 'primevue/menu'

import EditMessageTemplateDialog from './EditMessageTemplateDialog.vue'
import DeleteMessageTemplateButton from './DeleteMessageTemplateButton.vue';

interface messageTemplate {
    id: string
}

const query = gql`
    query messageTemplates {
        messageTemplates(with_hidden: true) { id, name, is_visible }
    }
`

const deleteMutation = gql`
    mutation deleteMessageTemplate($id: ID!) {
        deleteMessageTemplate(id: $id) {
            id, name, is_visible
        }
    }
`

const menu = ref()
const editDialogVisible: Ref<boolean> = ref(false)
const editMessageTemplateResource: Ref<messageTemplate | null> = ref(null)


const onClickEdit = (data, event) => {
    editMessageTemplateResource.value = data
    editDialogVisible.value = true
}

const updateCacheAfterDeletion = (cache, { data: { deleteMessageTemplate } }) => {
    cache.evict({ id: cache.identify(deleteMessageTemplate) })
    cache.gc()
}

const { result, loading, error, refetch } = useQuery(query)
</script>

<template>
    <DataTable :loading="loading" :value="result ? result.messageTemplates : null" :paginator="true" :rows="10">
        <Column field="name" header="Name" />
        <Column field="is_visible" header="Sichtbar" bodyStyle="text-align:right" headerClass="column-header-right">
            <template #body="{ data }">
                <i v-if="data.is_visible" class="pi pi-eye text-primary-500" />
                <i v-else class="pi pi-eye-slash" />
            </template>
        </Column>
        <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible; display: flex">
            <template #body="slotProps">
                <Button
                    type="button"
                    class="p-button-rounded p-button-text p-button-sm"
                    v-tooltip.bottom="'Bearbeiten'"
                    icon="pi pi-pencil" @click="() => {
                        editMessageTemplateResource = slotProps.data
                        editDialogVisible = true
                    }" aria-haspopup="true" aria-controls="overlay_menu" />
                <EditMessageTemplateDialog v-if="editMessageTemplateResource && editMessageTemplateResource.id === slotProps.data.id"
                    v-model="editDialogVisible" :message-template="editMessageTemplateResource"/>
                <DeleteMessageTemplateButton
                    :message-template-id="slotProps.data.id"
                    :update-cache="updateCacheAfterDeletion"/>
            </template>
        </Column>
        <template #empty>
            <p class="text-center text-slate-500">Keine Antwortvorlagen vorhanden. Erstellen Sie Ihre Erste über "Antwortvorlage
                erstellen".</p>
        </template>
    </DataTable>
</template>
