<script setup lang="ts">
import type { Ref } from 'vue'
import { useRoute } from 'vue-router'
import { ref } from 'vue'
import gql from 'graphql-tag'
import { useRouter } from 'vue-router'
import AppConfig from '@/config'

import { REPORT_QUERY } from './queries'
import {
    buildQueryVariables,
 } from './ReportIndexState'

import { decryptContent } from '@/containers/Report/reportKeys'

import axios from 'axios'
import Button from 'primevue/button'

import ReceiveReport from './Services/ReceiveReport.vue'
import MessageForm from '@/containers/Message/MessageForm.vue'
import Message from '@/containers/Message/Message.vue'
import ReportStatus from '@/containers/Report/ReportStatus.vue'
import ReportCategory from '@/containers/Report/ReportCategory.vue'
import ContactInformation from './ContactInformation.vue'
import DeadlineStatus from '@/components/DeadlineStatus.vue'
import SelectButton from 'primevue/selectbutton';
import ValidationMessage from '@/components/ValidationMessage.vue'
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import { saveReport } from '@/services/pdf'
import type { ReportDataPDF } from '@/services/pdf'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

const router = useRouter()

enum CommunicationView {
    Whistleblower,
    Internal,
    Mixed,
    Activity
}

interface ViewItem {
    name: string
    value: CommunicationView
}

const messageFormVisible: Ref<boolean> = ref(false)
const exportLoading: Ref<boolean> = ref(false)
const communicationView: Ref<ViewItem> = ref({ name: 'Alles', value: CommunicationView.Mixed })
const viewOptions = ref([
    { name: 'Alles', value: CommunicationView.Mixed },
    { name: 'Kommunikation Hinweisgeber', value: CommunicationView.Whistleblower },
    { name: 'Interne Kommunikation', value: CommunicationView.Internal },
    { name: 'Änderungshistorie', value: CommunicationView.Activity }
])

const route = useRoute()
const toast = useToast()
const { fullPath } = route
const formatDate = (value) => {
    return new Date(value).toLocaleDateString("de-DE", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
    });
};
const deleteReport: Ref<any> = ref(null)
const showMessageForm = () => messageFormVisible.value = true
const hideMessageForm = () => messageFormVisible.value = false
const getChannel = (value) => {
    const channels = {
        'VERBALLY': 'mündlich (Telefon oder persönliche Zusammenkunft)',
        'WRITTEN': 'schriftlich (Mail, Fax, Brief)',
        'PORTAL': 'Meldeportal'
    }
    return value ? channels[value] : '-'
}

const decryptMessageContent = async (encryptedData,{ iv, shareKeys, currentUser, isJson = true }) => {
    exportLoading.value = true
    let decryptedData: string | Record<any, any> = ''

    try {
        const encryptedContent = {
            content: encryptedData,
            iv: iv,
            shareKeys: shareKeys,
        }
        const clearText = await decryptContent(encryptedContent, currentUser)
        if (isJson) {
            decryptedData = JSON.parse(clearText) as Record<any, any>
        } else {
            decryptedData = clearText
        }
    } catch(err) {
        console.error(err)
    }

    exportLoading.value = false
    return decryptedData
}

const exportReport = async (result, shareKeys, currentUser) => {
    const requestUrl = `/${AppConfig.tenantSlug}${fullPath}/export`

    let pdfContent: ReportDataPDF = {
        contact: null,
        messages: []
    }
    if (result.report.contact_information) {
        pdfContent.contact = await decryptMessageContent(result.report.contact_information, { iv: result.report.report_iv, shareKeys, currentUser }) as Record<any, any>
    }

    if (result.report.messages.items.length > 0) {
        for (let message of result.report.messages.items) {
            let decryptedMessage =  await decryptMessageContent(message.content, { iv: message.iv, shareKeys, currentUser, isJson: false })
            pdfContent.messages.push({
                message: decryptedMessage,
                time: message.created_at,
                author: message.author ? message.author.full_name : 'Hinweisgeber',
                internal: message.is_internal
            })
        }
    }

    try {
        exportLoading.value = true
        const { data } = await axios.get(requestUrl, {
            method: 'GET',
            responseType: 'arraybuffer'
        })
        const pdfTemplate = '/img/compentum-verfahrens-export.pdf'
        const pdfBytes = await saveReport(data, pdfTemplate, pdfContent)
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(new Blob([pdfBytes], {type: 'application/pdf'}))
        a.download = `compentum-verfahren-${result.report.identifier}.pdf`
        a.click()
    } catch (err) {
        console.error(err)
    } finally {
        exportLoading.value = false
    }
}

const showDeleteConfirmation = (report) => {
    deleteReport.value = report
    toast.add({
        severity: 'warn',
        summary: `Sind Sie sicher, dass Sie das Verfahren mit der ID ${deleteReport.value.report.identifier} dauerhaft löschen möchten?`,
        group: 'rm-report',
        closable: false,
    });
}

const onSuccessfullyDeletingReport = () => {
    toast.removeGroup('rm-report')
    deleteReport.value = null
    router.push('/reports/')

}

const onRejectDeletingReport = () => {
    deleteReport.value = null
    toast.removeGroup('rm-report')
}

const updateCache = (cache, { data: { deleteReport } }) => {
    const query = {
        query: REPORT_QUERY,
        variables: buildQueryVariables()
    }
    let data = cache.readQuery(query)
    const reportId = deleteReport.id
    const filteredReports = data.reports.data.filter(item => item.id !== reportId)
    data = {
        ...data,
        reports: {
            data: {
                ...filteredReports
            }
        }
    }
    cache.writeQuery({ ...query, data })
}


</script>

<template>
    <ReceiveReport>
        <template v-slot="{ result, internalMessages, publicMessages, mixedMessages, shareKeys, currentUser, refetchReport }">
            <div class="lg:flex flex-row-reverse max-w-7xl">
                <div class="lg:w-1/3">
                    <div class="mb-12 bg-slate-50 p-4 lg:py-6 rounded">
                        <div class="mb-6 lg:mb-8">
                            <label for="report-status" class="mb-1 block font-semibold text-gray-600 text-base">Status</label>
                            <ReportStatus
                                id="report-status"
                                :report-id="result?.report.id"
                                :value="result?.report.status"
                                :share-keys="shareKeys"
                                :current-user="currentUser" />
                        </div>
                        <div>
                            <label for="category" class="mb-1 block font-semibold text-gray-600 text-base">Kategorie</label>
                            <!-- <span class="font-semibold text-gray-600">{{ result?.report.category ? result?.report.category.name : '-' }}</span> -->
                            <!-- <Dropdown id="category" :options="[]" optionLabel="name" option-value="id" placeholder="Kategorie auswählen*" class="w-full" /> -->
                            <ReportCategory
                                id="category"
                                :report-id="result?.report.id"
                                :value="result?.report.category" />
                        </div>
                    </div>
                    <div class="my-12 bg-slate-50 p-4 lg:py-6 rounded">
                        <div class="mb-6 lg:mb-8">
                            <label for="report-status" class="block font-semibold text-gray-400 text-base">Meldeportal:</label>
                            <span class="font-semibold text-gray-600">{{ result?.report.portal.name }}</span>
                        </div>
                        <div v-if="result?.report.internalSubmitter?.full_name" class="mb-6 lg:mb-8">
                            <label for="internalSubmitter" class="block font-semibold text-gray-400 text-base">Erfasst von:</label>
                            <span class="font-semibold text-gray-600">{{ result.report.internalSubmitter.is_trashed ? 'Gelöschter Benutzer' : result.report.internalSubmitter.full_name }}</span>
                        </div>
                        <div v-if="result?.report.reporting_channel" class="mb-6 lg:mb-8">
                            <label for="internalSubmitter" class="block font-semibold text-gray-400 text-base">Meldekanal:</label>
                            <span class="font-semibold text-gray-600">{{ getChannel(result?.report.reporting_channel) }}</span>
                        </div>
                        <div class="mb-6 lg:mb-8">
                            <label for="report-status" class="block font-semibold text-gray-400 text-base">Datum der Eingangsbestätigung:</label>
                            <span class="font-semibold text-gray-600">{{ formatDate(result?.report.created_at) }}</span>
                        </div>
                        <div v-if="result?.report.deadline.remaining_days" class="mb-6 lg:mb-8">
                            <label for="report-status" class="block font-semibold text-gray-400 text-base">Frist bis zur Rückmeldung:</label>
                            <DeadlineStatus :remaining-days="result?.report.deadline.remaining_days" :outline="false" />
                        </div>
                        <div v-if="result?.report.completed_at.date" class="mb-6 lg:mb-8">
                            <label for="report-status" class="block font-semibold text-gray-400 text-base">Abschlussdatum:</label>
                            <span class="font-semibold text-gray-600">{{ formatDate(result?.report.completed_at.date) }}</span>
                        </div>
                        <div v-if="result?.report.completed_at.processing_time" class="mb-6 lg:mb-8">
                            <label for="report-status" class="block font-semibold text-gray-400 text-base">Bearbeitungszeit:</label>
                            <span class="font-semibold text-gray-600">{{ result?.report.completed_at.processing_time }} Tage</span>
                        </div>
                        <div class="mb-6 lg:mb-8">
                            <label for="report-status" class="block font-semibold text-gray-400 text-base">Zuletzt geändert am:</label>
                            <span class="font-semibold text-gray-600">{{ formatDate(result?.report.updated_at) }}</span>
                        </div>
                        <div class="mb-6 lg:mb-8">
                            <label for="report-status" class="block font-semibold text-gray-400 text-base">Kontaktinformationen:</label>
                            <div v-if="result?.report.submit_type === 'CONFIDENTIAL'">
                                <ContactInformation
                                    :data="result?.report.contact_information"
                                    :iv="result?.report.report_iv"
                                    :submitterEmail="result?.report.submitter_email"
                                    :shareKeys="shareKeys"
                                    :user="currentUser"
                                />
                            </div>
                            <div v-else-if="result?.report.submit_type">
                                <span class="font-semibold text-gray-600">{{ 'Diese Meldung wurde anonym übermittelt' }}</span>
                            </div>
                        </div>
                        <div class="mb-6 lg:mb-8">
                            <Button class="p-button-sm" :loading="exportLoading" @click="exportReport(result, shareKeys, currentUser)">
                                Verfahren exportieren
                            </Button>
                        </div>

                        <div class="mb-6 lg:mb-8">
                            <Button label="Verfahren löschen" class="p-button-sm p-button-delete" icon="pi pi-trash" @click="showDeleteConfirmation(result)" severity="danger" link />

                            <Toast position="bottom-center" group="rm-report">
                                <template #message="slotProps">
                                    <div class="flex flex-col w-full">
                                        <div class="text-center">
                                            <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                                            <h4>{{ slotProps.message.summary }}</h4>
                                            <p>{{ slotProps.message.detail }}</p>
                                        </div>
                                        <ApolloMutation
                                            :mutation="gql`
                                                mutation deleteReport ($id: ID!) {
                                                    deleteReport (id: $id) { id }
                                                }
                                            `"
                                            :variables="{ id: deleteReport.report.id  }"
                                            :update="updateCache"
                                            @done="async () => {
                                                onSuccessfullyDeletingReport()
                                            }"
                                        >
                                            <template v-slot="{ mutate, loading, error }">
                                                <ValidationMessage v-if="error" :response="error" />
                                                <div class="flex w-full mt-4 space-x-2">
                                                    <div class="w-1/2">
                                                        <Button
                                                            class="p-button-secondary w-full block"
                                                            label="Nein"
                                                            @click="onRejectDeletingReport" />
                                                    </div>
                                                    <div class="w-1/2">
                                                        <Button
                                                            class="p-button-danger w-full block"
                                                            label="Ja"
                                                            :loading="loading"
                                                            @click="mutate" />
                                                    </div>
                                                </div>
                                            </template>
                                        </ApolloMutation>
                                    </div>
                                </template>
                            </Toast>

                        </div>
                    </div>
                </div>
                <div class="lg:w-2/3 lg:pr-6 xl:pr-12">
                    <div class="sm:flex items-center mb-8 lg:mb-14">
                        <h1 class="text-xl text-gray-600 font-bold mb-4 md:mb-0">
                            {{ 'Kennung des Verfahrens' }}<span class="text-slate-400 ml-1">{{`#${result.report.identifier}`}}</span>  <span v-if="result.report.internal" class="ml-2 text-white bg-primary-700 font-medium p-0.5 px-1 text-xs rounded">{{'Alternative Einreichung'}}</span>
                        </h1>
                        <div class="flex items-center ml-auto md:pl-4">
                            <router-link to="/reports" class="flex items-center text-blue-500 px-2 py-1 mr-2 hover:bg-primary-50 rounded active:bg-primary-100">
                                zur Übersicht
                            </router-link>
                            <Button class="p-button-sm" @click.prevent="showMessageForm">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-4 h-4">
                                    <path fill-rule="evenodd" d="M7.793 2.232a.75.75 0 01-.025 1.06L3.622 7.25h10.003a5.375 5.375 0 010 10.75H10.75a.75.75 0 010-1.5h2.875a3.875 3.875 0 000-7.75H3.622l4.146 3.957a.75.75 0 01-1.036 1.085l-5.5-5.25a.75.75 0 010-1.085l5.5-5.25a.75.75 0 011.06.025z" clip-rule="evenodd" />
                                </svg>
                                <span class="ml-2 font-bold">antworten</span>
                            </Button>
                        </div>
                    </div>
                    <div class="card flex justify-center mb-4">
                        <SelectButton v-model="communicationView" :options="viewOptions" optionLabel="name" aria-labelledby="basic" :allow-empty="false" :unselectable="true" />
                    </div>
                    <template v-if="communicationView.value === CommunicationView.Mixed">
                        <MessageForm
                            v-if="result && messageFormVisible"
                            :report-id="result.report.id"
                            :anonymous="result.report.submit_type === 'ANONYMOUS'"
                            :share-keys="shareKeys"
                            :current-user="currentUser"
                            :allow-internal="true"
                            @responded="hideMessageForm"
                            @close="hideMessageForm" />
                        <div class="my-8">
                            <h3 class="font-semibold text-gray-600 text-lg mt-8 mb-4">
                                Gesamte Kommunikation
                            </h3>
                            <div class="space-y-8">
                                <Message
                                    v-for="messageData in mixedMessages"
                                    :key="messageData.id"
                                    :shareKeys="shareKeys"
                                    :user="currentUser"
                                    :data="messageData"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-else-if="communicationView.value === CommunicationView.Whistleblower">
                        <MessageForm
                            v-if="result && messageFormVisible"
                            :report-id="result.report.id"
                            :anonymous="result.report.submit_type === 'ANONYMOUS'"
                            :share-keys="shareKeys"
                            :current-user="currentUser"
                            :allow-internal="false"
                            @responded="hideMessageForm"
                            @close="hideMessageForm" />
                        <div class="my-8">
                            <h3 class="font-semibold text-gray-600 text-lg mt-8 mb-4">
                                Kommunikation Hinweisgeber
                            </h3>
                            <div class="space-y-8">
                                <Message
                                    v-for="messageData in publicMessages"
                                    :key="messageData.id"
                                    :shareKeys="shareKeys"
                                    :user="currentUser"
                                    :data="messageData"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-else-if="communicationView.value === CommunicationView.Internal">
                        <MessageForm
                            v-if="result && messageFormVisible"
                            :report-id="result.report.id"
                            :anonymous="result.report.submit_type === 'ANONYMOUS'"
                            :share-keys="shareKeys"
                            :current-user="currentUser"
                            :set-internal="true"
                            header-title="Interne Notiz verfassen"
                            @responded="hideMessageForm"
                            @close="hideMessageForm" />
                        <div class="my-8">
                            <h3 class="font-semibold text-gray-600 text-lg mt-8 mb-4">Interne Kommunikation</h3>
                            <div class="space-y-8">
                                <Message
                                    v-for="messageData in internalMessages"
                                    :key="messageData.id"
                                    :shareKeys="shareKeys"
                                    :user="currentUser"
                                    :data="messageData"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-else-if="communicationView.value === CommunicationView.Activity">
                        <div class="my-8">
                            <h3 class="font-semibold text-gray-600 text-lg mt-8 mb-4">Aktivitätsprotokoll</h3>
                            <div class="space-y-8">
                                <Timeline :value=" result.report.activities ">
                                    <template #opposite="slotProps">
                                        <time class="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">{{ format(parseISO(slotProps.item.date), 'dd.MM.yyyy HH:mm') }}</time>
                                    </template>
                                    <template #content="slotProps">
                                        <p class="mb-4 text-base font-normal text-gray-500 dark:text-gray-400">{{ slotProps.item.description }}</p>
                                    </template>
                                </Timeline>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>
    </ReceiveReport>
</template>

<style>
.p-button-delete {
    color: #c02929!important;
}

.p-buttonset .p-button:not(:first-of-type):not(:last-of-type) {
    border-radius: 0;
}

.p-buttonset .p-button:first-of-type:not(:only-of-type) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.p-buttonset .p-button:last-of-type:not(:only-of-type) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>
