import {ref, computed} from 'vue'

import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '@/views/Home.vue'
import UsersView from '@/views/Users.vue'
import UserSettingsView from '@/views/UserSettings.vue'
import OrgaSettingsView from '@/views/OrgaSettings.vue'
import CategoriesView from '@/views/Categories.vue'
import PortalsView from '@/views/Portals.vue'
import PortalUpdate from '@/views/PortalUpdate.vue'
import PortalCreate from '@/views/PortalCreate.vue'
import MessageTemplatesView from '@/views/MessageTemplates.vue'
import ReportIndex from '@/views/Report/Index.vue'
import ReportShow from '@/views/Report/Show.vue'
import ReportCreate from '@/views/Report/Create.vue'
import Welcome from '@/views/Welcome.vue'

import AppFrame from '@/components/AppFrame.vue'
import PassThrough from '@/components/PassThrough.vue'
import AppConfig from '../config'

const currentUserRole = ref(AppConfig.currentUserRole)
const hasPermission = computed(() => currentUserRole.value === 'owner')
const permissionRedirect = (to, from, next) => {
    if (hasPermission.value) {
        next()
    } else {
        next({ name: 'Home' })
    }
}

const routes = [
    { path: '/', redirect: '/home' },
    {
        path: '/home',
        name: 'Home',
        component: HomeView
    },
    {
        path: '/reports',
        name: 'Reports',
        component: PassThrough,
        children: [
            {
                path: '',
                name: 'Reports',
                component: ReportIndex,
            },
            {
                path: ':id',
                name: 'Report',
                component: ReportShow,
            },
        ]
    },
    {
        path: '/report/new',
        name: 'ReportCreate',
        component: ReportCreate,
    },
    {
        path: '/material',
        name: 'Material',
        component: { components: { AppFrame }, template: '<AppFrame title="Material"></AppFrame>' }
    },
    {
        path: '/portals',
        name: 'Portale',
        component: PortalsView
    },
    {
        path: '/portals',
        name: 'Portale',
        component: PassThrough,
        children: [
            {
                path: '',
                name: 'Portale',
                component: PortalsView,
            },
            {
                path: ':id/edit',
                name: 'Portal bearbeiten',
                component: PortalUpdate,
                beforeEnter: permissionRedirect
            },
            {
                path: 'new',
                name: 'Portal erstellen',
                component: PortalCreate,
            },
        ]
    },
    {
        path: '/message-templates',
        name: 'Antwortvorlagen',
        component: MessageTemplatesView
    },
    {
        path: '/users',
        name: 'Benutzer',
        component: UsersView,
        beforeEnter: permissionRedirect
    },
    {
        path: '/categories',
        name: 'Kategorien',
        component: CategoriesView,
        beforeEnter: permissionRedirect
    },
    {
        path: '/user-settings',
        name: 'Persönliche Einstellungen',
        component: UserSettingsView,
    },
    {
        path: '/organization-settings',
        name: 'Beschäftigungsgeber Einstellungen',
        component: OrgaSettingsView,
    },
    {
        path: '/welcome',
        name: 'Willkommen',
        component: Welcome,
    },
    { path: '/:pathMatch(.*)*', redirect: '/home' },
]

const router = createRouter({
    history: createWebHistory(APP_CONFIG.tenantSlug),
    hashbang: false,
    routes,
})

export default router
