import { createApp, provide } from 'vue'
import { DefaultApolloClient } from '@vue/apollo-composable'
import VueApolloComponents from '@vue/apollo-components'
import * as Sentry from "@sentry/vue";
import PrimeVue from 'primevue/config'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import ConfirmationService from 'primevue/confirmationservice';
import { createPinia } from 'pinia'
import AppConfig from './config'
import Containers from './containers/index'
import { i18n } from './plugins/i18n'

import apolloProvider, { apolloClient } from './apolloProvider'
import appRouter from './routes/app'

const pinia = createPinia()
const app = createApp({
    components: Containers,
    setup () {
        provide(DefaultApolloClient, apolloClient)
    },
})

if (AppConfig.enableSentry) {
    Sentry.init({
        app,
        dsn: AppConfig.sentryDsn,
         integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(appRouter),
            }),
            new Sentry.Replay(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['http://localhost:8081/', 'http://localhost', 'https://compentum.de','https://nx-stage.compentum.de', 'https://nx-saas.compentum.de'],
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        trackComponents: true,
    })
}

app.use(apolloProvider)
app.use(PrimeVue)
app.use(VueApolloComponents)
app.use(ToastService)
app.use(ConfirmationService);
app.use(pinia)
app.use(i18n)
app.directive('tooltip', Tooltip)
if (AppConfig.mountAppRouter && AppConfig.tenantSlug) {
    app.use(appRouter)
}
app.config.globalProperties.$appConfig = AppConfig
app.mount('.app')
