interface AppFlash {
    readonly error: string,
    readonly status: string,
}

export interface Category {
    readonly id: string,
    readonly name: string,
}

export interface OrganizationKeys {
    readonly privateKey: string;
    readonly publicKey: string;
    readonly salt: string;
    readonly iv: string;
}

interface PortalTheme {
    readonly logo: string,
    readonly logoAlt: string,
    readonly backgroundColor: string,
    readonly primaryColor: string,
}

interface AppConfig {
    readonly mountAppRouter: boolean;
    readonly tenantSlug: string;
    readonly tenantName: string;
    readonly isSaas: boolean;
    readonly incompletePayment: boolean;
    readonly paymentUrl: string;
    readonly onTrial: boolean;
    readonly unlimitedTrial: boolean;
    readonly trialEndsAt: string;
    readonly launchPeriodTrial: boolean;
    readonly portalSlug: string;
    readonly portalUrl: string;
    readonly keys: OrganizationKeys;
    readonly flash: AppFlash;
    readonly accessList: Record<string, string>;
    readonly assignedCategories: Array<Category>;
    readonly enableSentry: boolean;
    readonly sentryDsn: string;
    readonly currentUserRole: string;
    readonly currentUserName: string;
    readonly theme: PortalTheme | null;
    readonly confirmMessage: string;
    readonly locale: string;
}

declare const APP_CONFIG: AppConfig;

export default APP_CONFIG
