<script setup lang="ts">
import type { User } from '@/containers/Report/reportKeys'
import { ref, defineEmits, computed, watch } from 'vue'
import Progressbar from 'primevue/progressbar'
import { updateShareKeys } from '../Report/reportKeys'
import { apolloClient } from '@/apolloProvider';
import gql from 'graphql-tag'

const props = defineProps<{ reports: Array<any>, currentUser: User }>()
const progress = ref(0)
const progressCount = ref(0)
const errorCount = ref(0)
const dirtyReports = computed(() => {
    return props.reports.filter(report => report.share_keys_dirty) || []
})
const emit = defineEmits(['finished', 'error'])

defineExpose(props)

watch(() => progress.value, (val) => {
    if (val >= 100) {
        emit('finished')
        emit('error', errorCount.value)
    }
})

const updateManyReportKeysMutation = gql`
    mutation updateManyReportKeys($reports: [UpdateReportKeysInput]) {
        updateManyReportKeys(reports: $reports) {
            id
            share_keys_dirty
        }
    }
`

// updateShareKeys nimmt einmal das User Objekt (id, jwk), EncryptedReportContent und die userList / Access List als parameter
// Dann wird der share key des users gezogen und entschlüsselt => reportKey.
// Dann wird der reportKey mit allen public keys in userList neu verschlüsselt und man bekommt eine neue
// liste mit shareKeys zurück
// Dann kann man eine mutation o.ä. machen etc.

if (dirtyReports.value.length === 0 || props.reports.length === 0) {
    progress.value = 100
} else {
  update()
}

async function updateKeys (keyList: Array<Record<string, unknown>>) {
    return await apolloClient.mutate({
        mutation: updateManyReportKeysMutation,
        variables: { reports: keyList }
    })
}

async function update () {
    const BATCH_SIZE = 128
    const max = dirtyReports.value.length
    let leftReports = max
    let input = [] as Array<Record<string, unknown>>

    for (const report of dirtyReports.value ) {
        const userList = JSON.parse(report.access_list)
        const oldShareKeys = JSON.parse(report.share_keys)
        try {
            const newShareKeysForReport = await updateShareKeys(props.currentUser, oldShareKeys, userList)
            const share_keys = JSON.stringify(newShareKeysForReport)
            const id = report.id
            leftReports--
            input.push({id, share_keys})

        } catch (err: unknown) {
            if (err instanceof Error) {
                console.error(err.message)
                errorCount.value++
            }
        } finally {
            /**
             * Cases:
             *  1. Batchsize >= max && input.length = max
             *  We have a batch size of 20 and only 3 dirty reports.
             *
             *  2. input.length = batchsize
             *  We have 100 dirty reports and batchsize of 20 and processing the first 20 items
             *
             *  3. leftReports <= 0 && input.length > 0
             *
             *  102 dirty reports, batchsize of 100. After first 100 reports processed, we have 2 left to submit
             *  also substract errorCount, if some keys wont work
             */

            if (
                (BATCH_SIZE >= max && input.length === max) ||
                (input.length === BATCH_SIZE) ||
                (leftReports - errorCount.value <= 0 && input.length > 0)) {
                try {
                    await updateKeys(input)
                    input = []
                } catch (err) {
                    console.error(err)
                    errorCount.value++
                }

            }
        }
        progressCount.value++
        progress.value = Math.floor((progressCount.value) / max * 100)
    }
}

</script>

<template>
    <Progressbar :value="progress" />
</template>
