<script setup lang="ts">
import type { Ref } from 'vue'

import gql from 'graphql-tag'
import { ref, defineProps, nextTick } from 'vue'
import { email as emailValidator, required, minLength, maxLength, sameAs } from "@/utils/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'
import Message from 'primevue/message'

import { CryptoService } from '../../services/index'
import ValidationMessage from '@/components/ValidationMessage.vue'
import GuestLayout from '@/components/Layouts/Guest.vue'
import InputGroup from '@/components/Form/InputGroup.vue'

const props = defineProps<{ emailValue: string, token: string }>()

const email: Ref<string | null> = ref(props.emailValue)
const password: Ref<string | null> = ref(null)
const passwordConfirmation: Ref<string | null> = ref(null)
const success = ref(false)
const privateKey: Ref<string | null> = ref(null)
const publicKey: Ref<string | null> = ref(null)
const iv: Ref<string | null> = ref(null)
const salt: Ref<string | null> = ref(null)
const isGeneratingKeyPair: Ref<boolean> = ref(false)

const rules = {
    email: { required, emailValidator },
    password: { required, minLength: minLength(8), maxLength: maxLength(128) },
    passwordConfirmation: { required, sameAsPassword: sameAs(password) },
}
const v$ = useVuelidate(rules, { email, passwordConfirmation, password })

const mutation = gql`
    mutation resetPassword ($token: String!, $email: String!, $password: String!, $passwordConfirmation: String!, $publicKey: String!, $privateKey: String!, $salt: String!, $iv: String! ) {
        resetPassword (input: { token: $token, email: $email, password: $password, password_confirmation: $passwordConfirmation, public_key: $publicKey, private_key: $privateKey, salt: $salt, iv: $iv }) {
            status
            message
            redirectTo
            user {
                id
                first_name
                last_name
                email
            }
        }
    }
`

// Crypto
const cryptoService = new CryptoService()

const generateKeys = async (password: string) => {
    const { publicKeyJwk, privateKeyJwk } = await cryptoService.generateUserKeyPair()

    // Salt for AES key
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const iv = window.crypto.getRandomValues(new Uint8Array(12))

    // Generate a secure key off a password
    const key = await cryptoService.getKeyFromPassword(password)
    const aesKey = await cryptoService.getAESKeyFromPBKDF(key, salt)
    const encryptedPrivateKey = await cryptoService.encryptPrivateKey(privateKeyJwk, aesKey, iv)

    return {
        publicKey:btoa(JSON.stringify(publicKeyJwk)),
        privateKey: btoa(String.fromCharCode(...new Uint8Array(encryptedPrivateKey))),
        iv: btoa(String.fromCharCode(...new Uint8Array(iv))),
        salt: btoa(String.fromCharCode(...new Uint8Array(salt)))
    }
}

const beforeSubmit = async (mutate: Function) => {
    if (password.value === null) {
        return
    }

    isGeneratingKeyPair.value = true
    const wallet = await generateKeys(password.value)
    privateKey.value = wallet.privateKey
    publicKey.value = wallet.publicKey
    iv.value = wallet.iv
    salt.value = wallet.salt

    await nextTick()
    isGeneratingKeyPair.value = false
    await mutate()
}

const onDone = ({ data: { resetPassword } }) => {
    if (resetPassword.status === 'success') {
        success.value = true
        setTimeout(() => document.location.href = resetPassword.redirectTo + window.location.search, 200)
    }
}
</script>

<template>
    <GuestLayout size="max-w-5xl">
        <div class="sm:flex">
            <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0">
                <ApolloMutation
                    :mutation="mutation"
                    :variables="{ token, email, password, passwordConfirmation, iv, salt, publicKey, privateKey }"
                    @done="onDone">
                    <template v-slot="{ mutate, loading, error }">
                        <ValidationMessage v-if="error" :response="error" />
                        <Message v-if="success" severity="success">{{
                            'Sie werden jeden Moment weitergeleitet...'
                        }}</Message>
                        <form v-if="!success" method="post" autocomplete="on" @submit.prevent="beforeSubmit(mutate)">
                            <h3 class="text-2xl font-bold text-gray-600 text-center mb-4">Neues Passwort festlegen</h3>
                            <div class="p-4 bg-red-100 text-red-700 rounded mb-6">
                                <h3 class="text-red-700 mt-0 font-semibold mb-1">Hinweis zur Verschlüsselung</h3>
                                <p class="mb-0 text-sm">Sie werden automatisch aus allen Meldeportalen entfernt und müssen von einem Inhaber wieder hinzugefügt werden. Dies ist zur Neuverschlüsselung nötig. Sie können die Neuverschlüsselung mit dem Wiederherstellungspasswort vornehmen. Sollten Sie Ihr Passwort vergessen haben prüfen Sie bitte Ihr Emergency Kit.</p>
                            </div>
                            <InputGroup>
                                <InputText v-model="email" placeholder="E-Mail-Adresse" name="email" type="email" />
                            </InputGroup>
                            <InputGroup>
                                <Password v-model="password" autocomplete="off" placeholder="Passwort" name="password" :toggle-mask="true" />
                            </InputGroup>
                            <InputGroup>
                                <Password v-model="passwordConfirmation" autocomplete="off" placeholder="Passwort bestätigen" name="password_confirmation" :toggle-mask="true" :feedback="false" />
                            </InputGroup>

                            <Button :disabled="v$.$invalid" :loading="loading || isGeneratingKeyPair" type="submit" class="block w-full" label="Speichern und anmelden" />
                        </form>
                    </template>
                </ApolloMutation>
            </div>
            <div class="sm:w-1/2 sm:pl-4 prose prose-sm">
                <h3>Sicherheit</h3>
                <p>Ihr Passwort bildet das Fundament einer sicheren Ende-zu-Ende-Verschlüsselung. <a target="_blank" href="https://www.bsi.bund.de/DE/Themen/Verbraucherinnen-und-Verbraucher/Informationen-und-Empfehlungen/Cyber-Sicherheitsempfehlungen/Accountschutz/Sichere-Passwoerter-erstellen/sichere-passwoerter-erstellen_node.html">Wählen und speichern Sie daher Ihr Passwort sorgsam</a>.</p>

                <h3>Account und Datenwiederherstellung</h3>
                <p>Bei Verlust Ihres Passworts oder der Anmeldeinformationen, gibt es aus Sicherheitsgründen nur <a target="_blank" href="#">limitierte Wiederherstellungsmöglichkeiten</a>. Ziehen Sie daher die Nutzung eines <a target="_blank" href="#">Passwortmanagers</a> in Betracht oder sichern Sie Ihre Zugänge an einem geschützten Platz.</p>
            </div>
        </div>
    </GuestLayout>
</template>
