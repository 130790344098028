<script setup lang="ts">
import { ref, defineProps, onUpdated, onMounted, watch } from 'vue'
import type { Ref } from 'vue'
import gql from 'graphql-tag'

import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button'
import SelectButton from 'primevue/selectbutton';
import Card from 'primevue/card';

import GuestLayout from '@/components/Layouts/Guest.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'
import InputGroup from '@/components/Form/InputGroup.vue'
import AppConfig from '@/config'

const props = defineProps({
    customer: {
        type: Object,
        required: true,
    },
    customerTaxId: {
        type: String,
        default: null,
    },
    basicMonthlyPrice: {
        type: Object,
        required: true,
    },
    basicYearlyPrice: {
        type: Object,
        required: true,
    },
    advancedMonthlyPrice: {
        type: Object,
        required: true,
    },
    advancedYearlyPrice: {
        type: Object,
        required: true,
    },
    allMonthlyPrice: {
        type: Object,
        required: true,
    },
    allYearlyPrice: {
        type: Object,
        required: true,
    },
})

const name = ref(null)
const email = ref(null)
const addressLine1 = ref(null)
const addressLine2 = ref(null)
const postalCode = ref(null)
const city = ref(null)
const country = ref('de')
const taxId: Ref<string|null> = ref(null)
const period: Ref<string> = ref('monthly')
const priceId: Ref<string|null> = ref(null)
const ombudsservice = ref(false)
const selectedMonthlyPriceId: Ref<string|null> = ref(null)
const selectedYearlyPriceId: Ref<string|null> = ref(null)
// const hasOver250Employees = ref(false)
// const selectedOmbudPriceId: Ref<string|null> = ref(null)

const monthlyPriceOptions = ref([
    { value: props.basicMonthlyPrice.id, label: 'Basis', price: "49 € mtl.*" },
    { value: props.advancedMonthlyPrice.id, label: 'Erweitert', price: "79 € mtl.*" },
    { value: props.allMonthlyPrice.id, label: 'All Inclusive', price: "499 € mtl.*" },
])

const yearlyPriceOptions = ref([
    { value: props.basicYearlyPrice.id, label: 'Basis', price: "558,60 € jährl.*" },
    { value: props.advancedYearlyPrice.id, label: 'Erweitert', price: "900,60 € jährl.*" },
    { value: props.allYearlyPrice.id, label: 'All Inclusive', price: "5.688,60 € jährl.*" },
])

const periodOptions = ref([
    { value: 'monthly', name: 'Monatlich' },
    { value: 'yearly', name: 'Jährlich' },
])

const mutation = gql`
    mutation updateCustomer ($name: String, $email: String, $addressLine1: String, $addressLine2: String, $postalCode: String, $city: String, $country: String, $taxId: String) {
        updateCustomer (input: { name: $name, email: $email, address_line1: $addressLine1, address_line2: $addressLine2, postal_code: $postalCode, city: $city, country: $country, tax_id: $taxId }) {
            id
        }
    }`

onMounted(() => {
    if (props.customer) {
        name.value = props.customer.name
        email.value = props.customer.email
        addressLine1.value = props.customer?.address?.line1
        addressLine2.value = props.customer?.address?.line2
        postalCode.value = props.customer?.address?.postal_code
        city.value = props.customer?.address?.city
        country.value = props.customer?.address?.country
        taxId.value = props.customerTaxId
    }

    priceId.value = props.basicMonthlyPrice.id
})

onUpdated(() => {
    if (props.customer) {
        name.value = props.customer.name
        email.value = props.customer.email
        addressLine1.value = props.customer.addressLine1
        addressLine2.value = props.customer.addressLine2
        postalCode.value = props.customer.postalCode
        city.value = props.customer.city
        country.value = props.customer.country
    }
})

const onDone = () => {
    let url = '/' + AppConfig.tenantSlug + '/checkout/payment?priceId=' + priceId.value

    document.location.href = url
}

const resetPriceId = () => {
    console.log(period.value)
    if (period.value === 'monthly') {
        selectedYearlyPriceId.value = priceId.value

        priceId.value = selectedMonthlyPriceId.value ?? props.basicMonthlyPrice.id
    } else if (period.value === 'yearly') {
        selectedMonthlyPriceId.value = priceId.value

        priceId.value = selectedYearlyPriceId.value ?? props.basicYearlyPrice.id
    }
}

watch(priceId, async (newValue, oldValue) => {
    const ombudPriceIds = [
        props.advancedMonthlyPrice.id,
        props.advancedYearlyPrice.id,
        props.allMonthlyPrice.id,
        props.allYearlyPrice.id,
    ]

    if (ombudPriceIds.includes(newValue)) {
        ombudsservice.value = true
    } else {
        ombudsservice.value = false
    }
})

</script>


<template>
    <GuestLayout size="max-w-6xl">
        <h1 class="text-2xl font-bold text-gray-600 text-center mb-8 w-full">Neues Abonnement abschließen</h1>
        <div class="sm:flex">
            <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0">
                <ApolloMutation
                    :mutation="mutation"
                    :variables="{
                        name, email, addressLine1, addressLine2, postalCode, city, country, taxId
                    }"
                    @done="onDone"
                >
                    <template v-slot="{ mutate, loading, error }">
                        <form method="post" autocomplete="on" @submit.prevent="mutate" class="flex flex-wrap">
                            <!-- Form to collect customer data for Stripe -->
                            <h3 class="text-2xl font-bold text-gray-600 mb-8 w-full">1. Rechnungsadresse</h3>
                            <InputGroup class="w-full mb-8">
                                <span class="p-float-label">
                                    <InputText v-model="name" name="name" type="text" autofocus required/>
                                    <label for="name">Firmenname</label>
                                </span>
                            </InputGroup>
                            <InputGroup class="w-full mb-8">
                                <span class="p-float-label">
                                    <InputText v-model="addressLine1" name="addressLine1" type="text" required/>
                                    <label for="addressLine1">Adresse</label>
                                </span>
                            </InputGroup>
                            <InputGroup class="w-full mb-8">
                                <span class="p-float-label">
                                    <InputText v-model="addressLine2" name="addressLine2" type="text"/>
                                    <label for="addressLine2">Adresse - Zusatz (optional)</label>
                                </span>
                            </InputGroup>
                            <InputGroup class="w-full mb-8 flex justify-between">
                                <span class="p-float-label w-full mr-2">
                                    <InputText v-model="postalCode" name="postalCode" type="text" class="w-full" required/>
                                    <label for="postalCode">PLZ</label>
                                </span>
                                <span class="p-float-label w-full ml-2">
                                    <InputText v-model="city" name="city" type="text" class="w-full" required/>
                                    <label for="city">Ort</label>
                                </span>
                            </InputGroup>
                            <InputGroup class="w-full mb-8">
                                <span class="p-float-label">
                                    <InputText v-model="taxId" name="taxId" type="text"/>
                                    <label for="taxId">USt-IdNr.</label>
                                </span>
                            </InputGroup>
                            <div class="p-inputgroup flex-1 w-full mb-8">
                                <span class="p-float-label">
                                    <Dropdown v-model="country" name="country" optionLabel="label" optionValue="value" :options="[
                                        { label: 'Deutschland', value: 'DE' },
                                        { label: 'Österreich', value: 'AT' },
                                        { label: 'Schweiz', value: 'CH' },
                                    ]"/>
                                    <label for="country">Land</label>
                                </span>
                            </div>

                            <h3 class="text-2xl font-bold text-gray-600 mb-8 w-full">2. Kontakt im Unternehmen</h3>
                            <InputGroup class="w-full mb-8">
                                <span class="p-float-label">
                                    <InputText v-model="email" name="email" type="email" required/>
                                    <label for="email">E-Mail-Adresse</label>
                                </span>
                            </InputGroup>

                            <h3 class="text-2xl font-bold text-gray-600 mb-8 w-full">3. Wählen Sie Ihren Tarif aus</h3>
                            <div class="w-full mb-8">
                                <div class="text-center mb-6">
                                    <SelectButton v-model="period" :options="periodOptions" optionValue="value" optionLabel="name" :allow-empty="false" @change="resetPriceId"></SelectButton>
                                    <div class="mt-6">Spare bis zu <strong>5 %</strong> bei jährlicher Buchung!</div>
                                </div>

                                <template v-if="period === 'monthly'">
                                    <SelectButton v-model="priceId" name="priceId" :options="monthlyPriceOptions" optionValue="value" class="flex mb-2 relative" :allow-empty="false" required>
                                        <template #option="slotProps">
                                            <div class="flex flex-wrap">
                                                <h3 class="text-sm mb-2 w-full text-center font-bold">{{ slotProps.option.label }}</h3>
                                                <div class="flex justify-between items-center w-full mb-4">
                                                    <h4 class="text-xl text-center w-full font-bold">{{ slotProps.option.price }}</h4>
                                                </div>
                                            </div>
                                        </template>
                                    </SelectButton>
                                    <div class="mb-2">
                                        <small class="text-xs w-full text-left">Mindestlaufzeit: 1 Monat, Verlängerung jeweils um 1 Monat. <a href="https://compentum.de/preise" target="_blank" class="underline">Weitere Informationen zu den Tarifen</a></small>
                                    </div>
                                    <div class="mb-2">
                                        <small class="mb-4">Promo Codes für Rabatte können auf der Zahlungsseite eingetragen werden. Falls Sie schon ein Promo Code eingetragen haben, wird der Rabatt auf der Zahlungsseite angewendet.</small>
                                    </div>
                                </template>

                                <template v-else-if="period === 'yearly'">
                                    <SelectButton v-model="priceId" name="priceId" :options="yearlyPriceOptions" optionValue="value" class="flex mb-2 relative" :allow-empty="false" required>
                                            <template #option="slotProps">
                                                <div class="flex flex-wrap">
                                                    <h3 class="text-sm mb-2 w-full text-center font-bold">{{ slotProps.option.label }}</h3>
                                                    <div class="flex justify-between items-center w-full mb-4">
                                                        <h4 class="text-xl text-center w-full font-bold">{{ slotProps.option.price }}</h4>
                                                    </div>
                                                </div>
                                            </template>
                                        </SelectButton>
                                        <div class="mb-2">
                                            <small class="text-xs w-full text-left">Mindestlaufzeit: 1 Jahr, Verlängerung jeweils um 1 Jahr. <a href="https://compentum.de/preise" target="_blank" class="underline">Weitere Informationen zu enthaltenen Leistungen</a></small>
                                        </div>
                                        <div class="mb-2">
                                            <small class="mb-4">Promo Codes für Rabatte können auf der Zahlungsseite eingetragen werden. Falls Sie schon ein Promo Code eingetragen haben, wird der Rabatt auf der Zahlungsseite angewendet.</small>
                                        </div>
                                </template>
                            </div>

                            <small v-if="ombudsservice" class="prose prose-sm mb-2">Ich bin mit der Geltung der <a target="_blank" href="/img/ergaenzungsvereinbarung-ombudsservice.pdf">Ergänzungsvereinbarung Ombudsservice</a> einverstanden.</small>

                            <ValidationMessage v-if="error" :response="error" class="w-full"/>

                            <small class="prose prose-sm mb-4">Sie werden zu unseren Zahlungspartner Stripe weitergeleitet. Sie können Ihre Bestellung sowie den Gesamtbetrag auf der Zahlungsseite noch einmal überprüfen. Nach Eingabe Ihrer Zahlungsdaten und Abschluss des Abonnements werden Sie zu compentum zurückgeleitet.</small>
                            <Button :loading="loading" type="submit" class="block w-full" label="Zur Zahlungsseite" />
                        </form>
                    </template>
                </ApolloMutation>
            </div>
            <div class="sm:w-1/2 sm:pl-4 prose prose-sm">
                <h3>Rechnungsadresse</h3>
                <p>Bitte tragen Sie die Adresse des Unternehmens ein, an das die Rechnung gerichtet werden soll.</p>

                <h3>USt-IdNr.</h3>
                <p>Bitte geben Sie Ihre USt-IdNr an, diese wird auch auf der Rechnung ausgewiesen. </p>
                <p><strong>Sie sind Teil einer Behörde oder eines deutschen Unternehmens, das keine USt-IdNr. besitzt?</strong> Dann kontaktieren Sie uns bitte per <a href="mailto:info@compentum.de" target="_blank">E-Mail</a> oder nutzen Sie unseren praktischen <a href="https://compentum.de/rueckruf" target="_blank">Rückrufservice</a>.</p>

                <h3>Kontakt im Unternehmen</h3>
                <p>An diese E-Mail-Adresse werden alle Informationen verschickt, die im Zusammenhang mit dem Abonnement und der Bezahlung stehen. Diese Mitteilungen werden von unserem Zahlungspartner Stripe versendet.</p>
                <p>Einige Beispiele für die Arten von Benachrichtigungen, die Sie erhalten können, sind:</p>
                <ul>
                    <li>Neue Rechnungen verfügbar</li>
                    <li>Zahlungen, die fehlgeschlagen sind</li>
                    <li>Ankündigungen zur automatischen Vertragsverlängerung</li>
                    <li>und vieles mehr</li>
                </ul>

                <p>Wichtig: Beachten Sie bitte, dass diese Mitteilungen an diese spezifische E-Mail-Adresse gesendet werden und nicht an die Organisation als Ganzes. Stellen Sie sicher, dass Sie eine E-Mail-Adresse angeben, die Sie regelmäßig überprüfen.</p>

                <h3>Tarif</h3>
                <p>Wählen Sie einen Tarif und eine Laufzeit aus. Bei Tarifen mit jährlicher Laufzeit gewähren wir ein <strong>Rabatt von 5 %</strong> gegenüber den monatlichen Tarifen.</p>
                <p>Weitere Information zu unseren Preisen und enthaltenen Leistungen erhalten Sie <a href="https://compentum.de/preise" target="_blank">hier</a>.</p>

                <p>Hinweis: Bei jährlicher Abrechnung wird der Gesamtbetrag für das gesamte Jahr sofort belastet.</p>

                <p>* Alle Preise in Euro, Netto und zzgl. der gesetzlich geltenden MwSt.</p>
            </div>
        </div>
    </GuestLayout>
</template>

<style>
.p-button {
    overflow: visible;
}
</style>
