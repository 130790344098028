<script setup lang="ts">
const props = withDefaults(defineProps<{ title?: string, boxed?: boolean, fullWidth?: boolean }>(), {
    boxed: true,
    fullWidth: false
})
defineExpose(props)
</script>

<template>
  <div :class="{ 'md:p-6 m-3 lg:m-5': true, 'bg-white rounded shadow-lg p-4 lg:p-8': boxed, 'mx-0 lg:mx-0 ': fullWidth }">
        <div v-if="title" :class="{ 'sm:flex items-center': true, 'mb-6 lg:mb-8': !boxed, 'mb-8 lg:mb-14': boxed }">
            <h1 class="text-xl text-gray-600 font-bold mb-4 md:mb-0">
            {{ title }}
            </h1>
            <div class="flex items-center ml-auto md:pl-4">
                <slot name="actions" />
            </div>
        </div>
        <slot />
    </div>
</template>
