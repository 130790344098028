<script setup lang="ts">
import type { Ref } from 'vue'

import { defineEmits, ref } from 'vue'
import Skeleton from 'primevue/skeleton'

import RecryptByPortal from '@/containers/Recrypt/RecryptByPortal.vue'
import UsersDecryptedKey from '@/containers/Auth/UsersDecryptedKey.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'

const finishedRecryptions: Ref<number> = ref(0)
const emit = defineEmits(['finished', 'processing'])

const onFinishRecryptPortalsReports = (numberOfPortals: number) => {
    finishedRecryptions.value++

    if (finishedRecryptions.value === numberOfPortals) {
        emit('finished')
    }
}

const onResult = ({ data }) => {
    if (data?.portals?.length === 0) {
        return emit('finished')
    }
}
</script>

<template>
    <UsersDecryptedKey @initialized="() => emit('processing')">
        <template v-slot="{ currentUser }">
            <ApolloQuery
                :query="gql => gql`query portals { portals(getAll: true) { id, name }}`"
                fetch-policy="network-only"
                @result="onResult">
                <template v-slot="{ result: { error, data }, isLoading }">
                    <div v-if="isLoading">
                        <Skeleton width="100%" class="mb-4" />
                        <Skeleton width="100" class="h-6" />
                    </div>
                    <ValidationMessage v-if="error" :response="error" />
                    <p v-if="data?.portals?.length === 0">Es sind keine Meldeportale vorhanden.</p>
                    <RecryptByPortal
                        v-if="data"
                        v-for="portal in data.portals"
                        :key="portal.id"
                        :title="portal.name"
                        :portal-id="portal.id"
                        :current-user="currentUser"
                        @finished="() => onFinishRecryptPortalsReports(data.portals.length)" />
                </template>
            </ApolloQuery>
        </template>
    </UsersDecryptedKey>
</template>
