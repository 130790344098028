<script setup lang="ts">
import { ref, defineProps, inject, computed } from 'vue'
import type { Ref, ComputedRef } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { useRouter } from 'vue-router'
import { useVuelidate } from "@vuelidate/core"
import { required, maxLength } from "@vuelidate/validators";
import Message from 'primevue/message'
import Listbox from 'primevue/listbox'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import Skeleton from 'primevue/skeleton'
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import ColorPicker from 'primevue/colorpicker';
import PrimeFileUpload from 'primevue/fileupload'
import Editor from 'primevue/editor'
import Checkbox from 'primevue/checkbox';

import PortalPreview from './PortalPreview.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'
import AppConfig from '@/config'

import type { Healthcheck } from '@/types'
import { REPORT_QUERY } from '../Report/queries'
import { buildQueryVariables } from '../Report/ReportIndexState'

const getHealthcheck: Function | undefined = inject('getHealthcheck')
const refreshHealthcheck: Function | undefined = inject('refreshHealthcheck')
const isSaas: Ref<boolean> = ref(AppConfig.isSaas)


interface users {
    id: string
};

const rules = {
    portalName: { required, maxLength: maxLength(255) },
    portalConfirmMessage: { required },
}

const router = useRouter()

const portalQuery = gql`
    query portals {
        portals(getAll: true) {
            id
        }
    }
`

const { result } = useQuery(portalQuery)

const healthcheck: ComputedRef<Healthcheck | null> = computed(() => getHealthcheck ? getHealthcheck() : null)
const createdPortals = computed(() => result?.value?.portals?.length || 0)
const portalLimit = computed(() => healthcheck.value?.portal_count_limit || 3)
const showUpgrade = computed(() => createdPortals.value >= portalLimit.value)

const mutationCreate = isSaas.value
    ? gql`
        mutation createPortal ( $name: String, $intro_text: String, $confirm_message: String, $legal_notice: String, $privacy_policy: String, $users: [ID], $background_color: String, $button_color: String, $logo: Upload, $upgradePlan: Boolean)
        {
            createPortal (input: { name: $name, confirm_message: $confirm_message, intro_text: $intro_text, legal_notice: $legal_notice, privacy_policy: $privacy_policy, users: { connect: $users }, theme: { create: { background_color: $background_color, button_color: $button_color, logo: $logo }}, upgradePlan: $upgradePlan })
                { id, url, name, intro_text, confirm_message, users { id, full_name }, theme { background_color, button_color, logo { filename, url } }}
        }
    `
    : gql`
        mutation createPortal ( $name: String, $intro_text: String, $confirm_message: String, $legal_notice: String, $privacy_policy: String, $users: [ID], $background_color: String, $button_color: String, $logo: Upload )
        {
            createPortal (input: { name: $name, confirm_message: $confirm_message, intro_text: $intro_text, legal_notice: $legal_notice, privacy_policy: $privacy_policy, users: { connect: $users }, theme: { create: { background_color: $background_color, button_color: $button_color, logo: $logo }} })
                { id, url, name, intro_text, confirm_message, users { id, full_name }, theme { background_color, button_color, logo { filename, url } }}
        }
    `

const mutationUpdate = gql`
    mutation updatePortal ($id: ID!, $name: String, $intro_text: String, $legal_notice: String, $privacy_policy: String, $confirm_message: String, $users: [ID], $background_color: String, $button_color: String, $logo: Upload) {
        updatePortal (input: { id: $id, name: $name, intro_text: $intro_text, legal_notice: $legal_notice, privacy_policy: $privacy_policy, confirm_message: $confirm_message, users: { sync: $users }, theme: { upsert: { background_color: $background_color, button_color: $button_color, logo: $logo }}}) {
            id, url, name, intro_text, confirm_message, users { id, full_name }, theme { background_color, button_color, logo { filename, url } }
        }
    }
`

const props = defineProps({
    'portal': {
        type: Object,
        default: null
    },
})

const acceptedTypes: Ref<string> = ref('image/*')
const success: Ref<boolean> = ref(false)
const portalName: Ref<string | null> = ref(props.portal?.name)
const portalIntroText: Ref<string> = ref(props.portal?.intro_text)
const portalLegalNotice: Ref<string> = ref(props.portal?.legal_notice)
const portalPrivacyPolicy: Ref<string> = ref(props.portal?.privacy_policy)
const portalConfirmMessage: Ref<string> = ref(props.portal?.confirm_message)
const portalUsers: Ref<users[]> = ref(props.portal ? props.portal.users : [])
const portalUserIds: Ref<string[]> = ref(portalUsers ? portalUsers.value.map(user => user.id) : [])
const backgroundColor: Ref<string | undefined> = ref(props.portal?.theme?.background_color?.replace('#', '') ?? null)
const buttonColor: Ref<string | undefined> = ref(props.portal?.theme?.button_color?.replace('#', '') ?? null)
const logo = ref()
const upgradePlan: Ref<boolean | null> = ref(isSaas.value ? false : null)

const v$ = useVuelidate(rules, { portalName, portalConfirmMessage })

const portalTheme = computed(() => {
    return {
        background_color: backgroundColor.value ? `#${backgroundColor.value}` : null,
        button_color: buttonColor.value ? `#${buttonColor.value}` : null
    }
})

const loadContentTemplates = async () => {
    portalIntroText.value = (await import('./templates/intro.html?raw')).default
    portalLegalNotice.value = (await import('./templates/legal.html?raw')).default
    portalPrivacyPolicy.value = (await import('./templates/privacy.html?raw')).default
    portalConfirmMessage.value = (await import('./templates/confirm_message.html?raw')).default
}

const onDone = ({ data: { updatePortal, createPortal } }) => {
    if (updatePortal || createPortal) {
        success.value = true
    }

    if (createPortal) {
        refreshHealthcheck?.()
        router.push(`/portals/`)
    }
}

const onSelect = (e) => {
    const newFiles: Array<any> = []
    e.files.forEach((file) => {
        newFiles.push(file)
    })

    logo.value = newFiles[0]
}

if (!props.portal) {
    loadContentTemplates()
}
</script>

<template>
    <ApolloMutation
        :mutation="portal ? mutationUpdate : mutationCreate"
        :variables="
            portal
            ? { id: portal.id, name: portalName, intro_text: portalIntroText, legal_notice: portalLegalNotice, privacy_policy: portalPrivacyPolicy, confirm_message: portalConfirmMessage, users: portalUserIds, ...portalTheme, logo }
            : { name: portalName, intro_text: portalIntroText, legal_notice: portalLegalNotice, privacy_policy: portalPrivacyPolicy, confirm_message: portalConfirmMessage, users: portalUserIds, ...portalTheme, logo, upgradePlan }
        "
        :refetchQueries="() => [
            { query: gql`query portals { portals(getAll: true) { id, url, name, reports_count }}` },
            { query: REPORT_QUERY, variables: buildQueryVariables() },
            { query: gql`query portals { portals { id, name }}` }
        ]"
        @done="onDone"
    >
        <template v-slot="{ mutate, loading, error }">
            <div class="sm:flex items-center mb-8 lg:mb-14">
                <h1 class="text-xl text-gray-600 font-bold mb-4 md:mb-0 mr-auto">
                    {{ portal ? `Meldeportal "${portal.name}" bearbeiten` : 'Neues Portal erstellen' }}
                </h1>
                <router-link to="/portals" class="flex items-center text-blue-500 px-2 py-1 mr-2 hover:bg-primary-50 rounded active:bg-primary-100">
                    zurück zur Übersicht
                </router-link>
                <Button type="submit" :loading="loading" :disabled="v$.$invalid" @click="mutate">Speichern</Button>
            </div>
            <ValidationMessage v-if="error" :response="error" />
            <Message v-if="success" severity="success">Portal erfolgreich gespeichert.</Message>
            <form method="post" autocomplete="on" @submit.prevent="() => !v$.$invalid && mutate()">
                <TabView>
                    <TabPanel header="Allgemeine Einstellungen">
                        <div class="p-inputgroup my-8">
                            <span class="p-float-label">
                                <InputText id="portal" v-model="portalName"  placeholder="Portalbezeichnung" />
                                <label for="portal">Portalbezeichnung</label>
                            </span>
                        </div>
                        <div class="p-inputgroup my-8">
                            <span class="p-float-label">
                                <Textarea id="confirm_text" v-model="portalConfirmMessage" placeholder="Bestätigungstext" rows="10"/>
                                <label for="confirm_text">Bestätigungstext</label>
                            </span>
                        </div>
                        <ApolloQuery
                            class="my-8"
                            :query="gql => gql`
                                query organization($id: ID!) {
                                    organization (id: $id) { id, users { id, full_name, email, email_verified_at } }
                                }
                            `"
                            :variables="{ id: $appConfig.tenantSlug }"
                        >
                            <template v-slot="{ result: { loading, error, data } }">
                                <div class="w-full">
                                    <h3 class="mb-2 text-md font-semibold" for="portalbenutzer">Portalbenutzer</h3>
                                    <Skeleton v-if="loading > 0 || !data" width="100%" height="4rem" />
                                    <Listbox
                                        v-else
                                        v-model="portalUserIds"
                                        :options="data && data.organization ? data.organization.users : []"
                                        :option-disabled="(item) => item.email_verified_at === null"
                                        multiple
                                        :metaKeySelection="false"
                                        optionLabel="full_name"
                                        optionValue="id"
                                        placeholder="Portalbenutzer"
                                        filter
                                        class="w-full md:w-14rem">
                                        <template #option="slotProps">
                                            <div class="flex items-center">
                                                <span
                                                    v-if="slotProps.option.email_verified_at === null"
                                                    class="text-yellow-600 mr-1"
                                                    v-tooltip.right="'E-Mail-Adresse wurde noch nicht verifiziert'">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                                    </svg>
                                                </span>

                                                <input type="checkbox" v-model="portalUserIds" :value="slotProps.option.id" class="mr-3"/>
                                                <div>{{ slotProps.option.full_name }}</div>
                                            </div>
                                        </template>
                                        <template v-slot:emptyfilter>
                                            <i>Keine Ergebnisse gefunden</i>
                                        </template>
                                    </Listbox>
                                    <small class="mt-4 block text-gray-500">
                                        Benutzer, die mit <i class="pi pi-exclamation-triangle text-yellow-600 align-text-bottom"></i> markiert sind, haben ihre E-Mail-Adresse noch nicht verifiziert. Sie können erst zu diesem Portal zugewiesen werden, wenn Sie Ihre E-Mail-Adresse verifiziert haben.
                                    </small>
                                </div>
                            </template>
                        </ApolloQuery>
                        <div v-if="isSaas && showUpgrade" class="flex items-center flex-wrap">
                            <Checkbox v-model="upgradePlan" inputId="upgrade" binary/>
                            <label for="upgrade" class="ml-2">Kostenpflichtig zusätzliches Meldeportal buchen </label>
                            <small class="mt-2 basis-full">Der Betrag wird anteilig für die Restlaufzeit Ihres Vertrags berechnet und die angegebene Zahlungsmethode damit belastet. Dieses Upgrade wird automatisch zum Ende der Laufzeit verlängert und kann jederzeit wieder heruntergestuft werden. Weitere Informationen zu den Preisen finden sie <a class="underline" href="https://compentum.de/preise">hier</a>.</small>
                        </div>
                    </TabPanel>
                    <TabPanel header="Design">
                        <div class="md:flex flex-row max-w-6xl">
                            <div class="flex flex-grow flex-col mb-4">
                                <h4 class="text-sm text-slate-400 font-medium mb-3">Logo</h4>
                                <div>
                                    <img v-if="portal?.theme?.logo" :src="portal?.theme?.logo?.url" class="preview-logo mb-3"/>
                                </div>
                                <PrimeFileUpload
                                    name="files[]"
                                    mode="basic"
                                    url="./upload.php"
                                    :multiple="false"
                                    :showUploadButton="false"
                                    :showCancelButton="false"
                                    :accept="acceptedTypes"
                                    :max-file-size="20000000"
                                    :fileLimit="10"
                                    :previewWidth="100"
                                    choose-label="Logo auswählen"
                                    @select="onSelect"
                                >
                                    <template #empty>
                                        <p>Logo hier rein ziehen zum upload.</p>
                                    </template>
                                </PrimeFileUpload>

                                <h4 class="text-sm text-slate-400 font-medium mb-3 mt-5">Farben</h4>
                                <div class="flex flex-row my-3 items-center">
                                    <ColorPicker v-model="backgroundColor" class="mr-2" />
                                    <div class="field">
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-hashtag" />
                                            <InputText v-model="backgroundColor" id="background-color" class="p-inputtext-sm" placeholder="Hintergrundfarbe" />
                                        </span>
                                        <label for="background-color" class="ml-2">Hintergrundfarbe</label>
                                    </div>
                                </div>
                                <div class="flex flex-row my-3 items-center">
                                    <div class="field">
                                        <ColorPicker v-model="buttonColor" class="mr-2" />
                                        <span class="p-input-icon-left">
                                            <i class="pi pi-hashtag" />
                                            <InputText v-model="buttonColor" id="background-color" class="p-inputtext-sm" placeholder="Schaltflächenfarbe" />
                                        </span>
                                        <label for="background-color" class="ml-2">Schaltflächenfarbe</label>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-grow flex-col">
                                <h4 class="text-sm text-slate-400 font-medium mb-3 mt-5">Vorschau</h4>
                                <PortalPreview :background-color="`#${backgroundColor}`" :button-color="`#${buttonColor}`" class="mb-3" />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <template #header>
                            <span class="p-tabview-title inline-flex items-center">Inhalte <span v-if="!portal" class="inline-block ml-1 w-2 h-2 bg-red-600 rounded-full"></span></span>
                        </template>
                        <Message v-if="!portal" severity="warn" :closable="false">{{
                            'Bitte ersetzen Sie in den folgenden Vorlagen die Platzhalter durch die korrekten Kontaktinformationen.'
                        }}</Message>
                        <h3 class="mb-2 text-md font-semibold mt-8" for="portalbenutzer">Einführungstext</h3>
                        <Editor v-model="portalIntroText" rows="10" editorStyle="height: 320px">
                            <template #toolbar>
                                <span class="ql-formats">
                                    <select class="ql-header" defaultValue="0">
                                        <option value="1">Überschrift</option>
                                        <option value="2">Unterüberschrift</option>
                                        <option value="0">Normal</option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-bold" type="button"></button>
                                    <button class="ql-italic" type="button"></button>
                                    <button class="ql-underline" type="button"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-list" value="ordered" type="button"></button>
                                    <button class="ql-list" value="bullet" type="button"></button>
                                    <select class="ql-align">
                                        <option defaultValue></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-link" type="button"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-clean" type="button"></button>
                                </span>
                            </template>
                        </Editor>

                        <h3 class="mb-2 text-md font-semibold mt-8" for="portalbenutzer">Impressum</h3>
                        <Editor v-model="portalLegalNotice" rows="10" editorStyle="height: 320px">
                            <template #toolbar>
                                <span class="ql-formats">
                                    <select class="ql-header" defaultValue="0">
                                        <option value="1">Überschrift</option>
                                        <option value="2">Unterüberschrift</option>
                                        <option value="0">Normal</option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-bold" type="button"></button>
                                    <button class="ql-italic" type="button"></button>
                                    <button class="ql-underline" type="button"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-list" value="ordered" type="button"></button>
                                    <button class="ql-list" value="bullet" type="button"></button>
                                    <select class="ql-align">
                                        <option defaultValue></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-link" type="button"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-clean" type="button"></button>
                                </span>
                            </template>
                        </Editor>

                        <h3 class="mb-2 text-md font-semibold mt-8" for="portalbenutzer">Datenschutzerklärung</h3>
                        <Editor v-model="portalPrivacyPolicy" rows="10" editorStyle="height: 640px">
                            <template #toolbar>
                                <span class="ql-formats">
                                    <select class="ql-header" defaultValue="0">
                                        <option value="1">Überschrift</option>
                                        <option value="2">Unterüberschrift</option>
                                        <option value="0">Normal</option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-bold" type="button"></button>
                                    <button class="ql-italic" type="button"></button>
                                    <button class="ql-underline" type="button"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-list" value="ordered" type="button"></button>
                                    <button class="ql-list" value="bullet" type="button"></button>
                                    <select class="ql-align">
                                        <option defaultValue></option>
                                        <option value="center"></option>
                                        <option value="right"></option>
                                        <option value="justify"></option>
                                    </select>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-link" type="button"></button>
                                </span>
                                <span class="ql-formats">
                                    <button class="ql-clean" type="button"></button>
                                </span>
                            </template>
                        </Editor>
                    </TabPanel>
                </TabView>
            </form>
        </template>
    </ApolloMutation>
</template>

<style>
.preview-logo {
    max-width: 100%;
    max-height: 100px;
    height: auto;
}

.ql-editor {
    @apply !prose !max-w-none;
}

.ql-editor > *:first-child {
    @apply !mt-0;
}
</style>
