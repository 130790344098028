<script setup lang="ts">
import { computed, ref, defineEmits } from 'vue'
import { email as emailValidator, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'
import Message from 'primevue/message'

import InputGroup from '@/components/Form/InputGroup.vue'
import AppLink from '@/components/AppLink.vue'

const rules = {
    email: { required, emailValidator },
    password: { required },
}
const email = ref(null)
const password = ref(null)
const success = ref(false)
const v$ = useVuelidate(rules, { email, password })
const emit = defineEmits(['login'])

const onDone = ({ data: { login } }) => {
    if (login.status === 'success') {
        success.value = true
        emit('login')
    }
}
</script>

<template>
    <ApolloMutation
        :mutation="gql => gql`
            mutation login ($email: String!, $password: String!) {
                login (input: { email: $email, password: $password, remember: false }) {
                    status
                    message
                    user {
                        id
                        first_name
                        last_name
                    }
                }
            }
        `"
        :variables="{
            email, password
        }"
        @done="onDone"
    >
        <template v-slot="{ mutate, loading, error }">
            <Message v-if="error" severity="error">{{ error.message }}</Message>
            <Message v-if="success" severity="success">{{
                'Sie werden jeden Moment weitergeleitet...'
            }}</Message>
            <form v-if="!success" method="post" autocomplete="on" @submit.prevent="() => !v$.$invalid && mutate()">
                <h3 class="text-2xl font-bold text-gray-600 text-center mb-4">Sign in</h3>
                <InputGroup>
                    <InputText v-model="email" placeholder="E-Mail address" name="email" type="email" autofocus />
                </InputGroup>
                <InputGroup>
                    <Password v-model="password" placeholder="Passwort" name="password" :feedback="false" />
                </InputGroup>

                <Button :disabled="v$.$invalid" :loading="loading" type="submit" class="block w-full" label="Sign in" />
            </form>
        </template>
    </ApolloMutation>
</template>
