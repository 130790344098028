import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client/core'
import { setContext  } from '@apollo/client/link/context'
import { createApolloProvider } from '@vue/apollo-option'
import { createUploadLink } from 'apollo-upload-client'

import AppConfig from './config'

const uploadLink = createUploadLink({ uri: '/graphql' })
const httpLink = createHttpLink({
  uri: '/graphql',
  credentials: 'same-origin',
})

const getToken = () => {
    let tokenCookie = RegExp('XSRF-TOKEN[^;]+').exec(document.cookie)
    let token = decodeURIComponent(tokenCookie ? tokenCookie.toString().replace(/^[^=]+./, '') : '')
    return token
}
let tenantId = AppConfig.tenantSlug

// XSRF token is required to make post requests to your Laravel backend
const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...(tenantId ? { 'X-Tenant': tenantId } : {}),
      'X-XSRF-TOKEN': getToken(),
      accept: 'application/json',
    }
  }
});

// Create the apollo client
export const apolloClient = new ApolloClient({
//   link: authLink.concat(httpLink),
  link: ApolloLink.from([ authLink, uploadLink]),
  cache: new InMemoryCache(),
})

// Create a provider
export default createApolloProvider({
  defaultClient: apolloClient,
})
