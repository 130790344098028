import type { MessageMeta, Message } from '@/containers/Message/message'

import { useEventBus } from '@vueuse/core'

import { CryptoService } from '../../services/index'
import { StorageService } from '../../services/storage'

const cryptoService = new CryptoService()

export interface ReportContent {
    version: String,
    meta: MessageMeta,
    messages: Array<Message>,
}

export interface ContentEncrypted {
    content: string
    iv: string
    shareKeys: Record<string, string>
    access_list?: Record<string, string>
}

export interface User {
    privateKeyJwk: JsonWebKey
    id: string
}

export interface CurrentUser {
    identifier: string
    iv: string
    private_key: string
    salt: string
}

export const bus = useEventBus<User>('currentUser')

export const generateKeys = async (password: string) => {
    const {
        publicKeyJwk,
        privateKeyJwk
    } = await cryptoService.generateUserKeyPair()

    // Salt for AES key
    const salt = window.crypto.getRandomValues(new Uint8Array(16))
    const iv = window.crypto.getRandomValues(new Uint8Array(12))

    // Generate a secure key off a password
    const key = await cryptoService.getKeyFromPassword(password)
    const aesKey = await cryptoService.getAESKeyFromPBKDF(key, salt)
    const encryptedPrivateKey = await cryptoService.encryptPrivateKey(
        privateKeyJwk,
        aesKey,
        iv
    )

    return {
        publicKey: cryptoService.jwkToBase64(publicKeyJwk),
        privateKey: cryptoService.arrayBufferToBase64(encryptedPrivateKey),
        privateKeyJwk,
        iv: cryptoService.arrayBufferToBase64(iv),
        salt: cryptoService.arrayBufferToBase64(salt)
    }
}

export const encryptContentWithoutReportKey = async (
    content: string,
    users: Record<string, string>
) => {
    // Generate AES Key to encrypt content
    const reportKey = await cryptoService.generateReportKey()
    // Export as JsonWebKey
    const reportKeyJwk = await cryptoService.cryptoKeyToJwk(reportKey)
    // Encode as UIntArray
    const reportKeyBuffer = cryptoService.jwkToBuffer(reportKeyJwk)

    // Encrypt cleartext message
    const { iv, encrypted } = await cryptoService.encrypt(
        content,
        reportKey
    )
    const shareKeys: Record<string, string> = {}

    // Encrypt the report key with each users public key and save as shareKey
    for (const user in users) {
        const id = user
        const publicKeyB64 = users[id]
        const publicKeyJwk = cryptoService.base64ToJwk(publicKeyB64)
        const publicKey = await cryptoService.jwkToCryptoKeyRSAPublic(publicKeyJwk)

        // Generate share key with user public key and encoded (as ArrayBuffer) report key
        const shareKeyCryptoKey = await cryptoService.generateShareKey(
            publicKey,
            reportKeyBuffer
        )

        shareKeys[id] = cryptoService.arrayBufferToBase64(shareKeyCryptoKey)
    }

    return {
        content: cryptoService.arrayBufferToBase64(encrypted),
        iv: cryptoService.arrayBufferToBase64(iv),
        shareKeys
    }
}

export const encryptContent = async (
    content: string,
    user: User,
    shareKeys: Record<string, string>
) => {
    const { privateKeyJwk, id } = user

    // import JsonWebKey as CryptoKey
    const privateKey = await cryptoService.jwkToCryptoKeyRSAPrivate(privateKeyJwk)

    // Get Base64 encoded shareKey
    const shareKeyB64 = shareKeys[id]

    if (!shareKeyB64) {
        throw new Error('User ID not found in key list. Access forbidden!')
    }

    // Convert Base64 key to UIntArray
    const shareKey = cryptoService.base64ToArrayBuffer(shareKeyB64)

    // Decrypt report key with users private key
    const reportKey = await cryptoService.decryptReportKey(privateKey, shareKey)
    const reportKeyJwk = cryptoService.bufferToJwk(reportKey)

    const reportCryptoKey = await cryptoService.jwkToCryptoKeyAES(reportKeyJwk)
    const plaintextContent = content
    const { iv, encrypted } = await cryptoService.encrypt(
        plaintextContent,
        reportCryptoKey
    )

    return {
        content: cryptoService.arrayBufferToBase64(encrypted),
        iv: cryptoService.arrayBufferToBase64(iv),
        shareKeys
    }
}

export const decryptContent = async (
    content: ContentEncrypted,
    user: User
): Promise<any> => {
    const { privateKeyJwk, id } = user

    // import JsonWebKey as CryptoKey
    const privateKey = await cryptoService.jwkToCryptoKeyRSAPrivate(privateKeyJwk)

    // Get Base64 encoded shareKey
    let shareKeyB64
    if (typeof content.shareKeys === 'string') {
        const s = JSON.parse(content.shareKeys)
        shareKeyB64 = s[id]
    } else {
        shareKeyB64 = content.shareKeys[id]
    }

    if (!shareKeyB64) {
        throw new Error('User ID not found in key list. Access forbidden!')
    }

    let clearText = ''
    // Convert Base64 key to UIntArray
    const shareKey = cryptoService.base64ToArrayBuffer(shareKeyB64)

    // Decrypt report key with users private key
    try {
        const reportKey = await cryptoService.decryptReportKey(privateKey, shareKey)
        const reportKeyJwk = cryptoService.bufferToJwk(reportKey)
        const reportCryptoKey = await cryptoService.jwkToCryptoKeyAES(reportKeyJwk)
        clearText = await cryptoService.decrypt(content.content, reportCryptoKey, content.iv)
    } catch (e) {
        throw new Error('Could not decrypt report key or content')
    }

    return clearText
}

/**
 * Asks user for password to decrypt private key report content
 *
 * @param user CurrentUser
 * @param store Storage
 * @returns
 */
export const decrcyptPrivateKeyPrompt = async (user: CurrentUser, store?: StorageService<User>) => {
    const password = window.prompt('Bitte geben Sie ihr Passwort ein')

    if (password) {
        await decrcyptPrivateKey(user, password, store)
    } else {
        if (confirm('Bitte geben Sie ihr Password ein, um die Mitteilung zu öffnen!')) {
            decrcyptPrivateKeyPrompt(user, store)
        }
    }
}

export const decrcyptPrivateKey = async (user: CurrentUser, password: string,  store?: StorageService<User>) => {
    const { identifier, iv, private_key, salt } = user
    let currentUser
    let error: string | null = null

    const encryptedPrivateKey = cryptoService.base64ToArrayBuffer(private_key)
    const ivBuffer = cryptoService.base64ToArrayBuffer(iv)
    const saltBuffer = cryptoService.base64ToArrayBuffer(salt)

    try {
        const privateKey = await cryptoService.decryptPrivateKey(encryptedPrivateKey, password, saltBuffer, ivBuffer)
        const privateKeyJWK = JSON.parse(privateKey)

        currentUser = {
            id: identifier,
            privateKeyJwk: privateKeyJWK
        }
        if (store) {
            store.saveState('currentUser', currentUser)
            bus.emit(currentUser)
        }
        return {
            currentUser, error: null
        }
    } catch(err) {
        error = 'Entschlüsselung fehlgeschlagen. Bitte überprüfen Sie Ihr Passwort.'
    }

    return {
        currentUser, error
    }
}

/**
 * Updates share keys of a single report
 * @param user {User}
 * @param report {ContentEncrypted}
 */
export const updateShareKeys = async (user: User, shareKeys: Record<string, string>, userList: Record<string, string>): Promise<Record<string, string>> => {
    const { privateKeyJwk, id: userId } = user
    // Import JWK as CryptoKey
    const privateKey = await cryptoService.jwkToCryptoKeyRSAPrivate(privateKeyJwk)

    let shareKeyB64

    // Get Base64 Encoded sharekey by userId
    if (typeof shareKeys === 'string') {
        const s = JSON.parse(shareKeys)
        shareKeyB64 = s[userId]
    } else {
        shareKeyB64 = shareKeys[userId]
    }

    if (!shareKeyB64) {
        throw new Error('User ID not found in key list. Access forbidden!')
    }

    const newShareKeys: Record<string, string> = {}
    const shareKey = cryptoService.base64ToArrayBuffer(shareKeyB64)
    try {
        const reportKey = await cryptoService.decryptReportKey(privateKey, shareKey)

        for (const user in userList) {
            const id = user
            const publicKeyB64 = userList[id]
            const publicKeyJwk = cryptoService.base64ToJwk(publicKeyB64)
            const publicKey = await cryptoService.jwkToCryptoKeyRSAPublic(publicKeyJwk)

            // Generate share key with user public key and encoded (as ArrayBuffer) report key
            const shareKeyCryptoKey = await cryptoService.generateShareKey(
                publicKey,
                reportKey
            )
            newShareKeys[id] = cryptoService.arrayBufferToBase64(shareKeyCryptoKey)
        }

    } catch (e) {
        throw new Error('Could not decrypt report key', { cause: e })
    }

    return newShareKeys
}
