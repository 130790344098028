<script setup lang="ts">
import type { Ref } from 'vue'
import type { User } from '@/containers/Report/reportKeys'

import { ref } from 'vue'
import { StorageService, PiniaStorage } from '@/services/storage'
import { useKeyStore } from '@/services/storage/keyStorage'
import { bus } from '@/containers/Report/reportKeys'

const keyStore = useKeyStore()

const store = new StorageService<User>({
    storage: new PiniaStorage(keyStore)
})

const getCurrentUser = () => {
    return store.restoreState('currentUser') as User
}

const currentUser: Ref<User | null> = ref(getCurrentUser())

bus.on(user => {
    currentUser.value = user
})

const reset = () => {
    store.storage.removeItem('currentUser')
    bus.emit(undefined)
}
</script>

<template>
    <div
        v-if="currentUser"
        :class="[
            'mb-4 md:mb-6 bg-gradient-to-r from-yellow-200 to-yellow-300 text-yellow-700 text-xs md:text-sm font-medium flex items-center justify-center border-b border-yellow-200 px-4 py-1 md:py-2 cursor-pointer transition-all duration-300 ease-in-out',
            'group hover:from-yellow-300 hover:to-yellow-400 hover:text-yellow-700 overflow-hidden',
            'active:from-gray-200 active:to-gray-300 active:border-gray-200 active:text-gray-700'
        ]"
        @click="reset">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-1 md:h-5 md:w-5 md:mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M8 11V7a4 4 0 118 0m-4 8v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2z" />
        </svg>
        <div class="relative w-44 h-5">
            <span class="absolute transition-all group-hover:-translate-y-8 group-hover:opacity-0">Datenzugriff aktiviert</span>
            <span class="absolute transition-all translate-y-8 opacity-0 group-hover:translate-y-0 group-hover:opacity-100">Datenzugriff sperren</span>
        </div>
    </div>
    <div
        v-else
        class="mb-4 md:mb-6 bg-gradient-to-r from-green-200 to-green-300 border-green-200 text-green-700 text-xs md:text-sm font-medium flex items-center justify-center border-b px-4 py-1 md:py-2">
        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-1 md:h-5 md:w-5 md:mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
        </svg>
        Datenzugriff geschützt
    </div>
</template>
