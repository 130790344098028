<script setup lang="ts">
import type { Ref } from 'vue'
import type { User, ContentEncrypted } from '@/containers/Report/reportKeys'

import { ref } from 'vue'

import { decryptContent } from '@/containers/Report/reportKeys'

interface ContactInformation {
    firstName: string | null
    lastName: string | null
    phone: string | null
}

const props = defineProps<{
    data: string,
    iv: string,
    submitterEmail: string,
    shareKeys: Record<string, string>
    user: User
}>()

const isDecrypting: Ref<boolean> = ref(false)
const errorMessage: Ref<string | null> = ref(null)
const decryptedData: Ref<ContactInformation> = ref({
    firstName: null,
    lastName: null,
    phone: null
})

const decryptMessageContent = async () => {
    isDecrypting.value = true

    try {
        const encryptedContent: ContentEncrypted = {
            content: props.data,
            iv: props.iv,
            shareKeys: props.shareKeys,
        }
        const clearText = await decryptContent(encryptedContent, props.user)
        decryptedData.value = JSON.parse(clearText)
    } catch(err) {
        console.error(err)
        errorMessage.value = 'Konnte Wert nicht entschlüsseln'
    }

    isDecrypting.value = false
}

decryptMessageContent()
defineExpose(props)
</script>

<template>
    <div v-if="data" class="mb-8">
        <table class="table-auto">
            <tbody>
                <tr>
                    <td><span class="text-gray-600 pr-5">{{ $t("components.contactInformation.firstName") }}</span></td>
                    <td class="font-semibold">
                        <span v-if="errorMessage" class="text-red-600 italic text-sm">{{ errorMessage }}</span>
                        <span v-else>{{ decryptedData.firstName ? decryptedData.firstName : '-' }}</span>
                    </td>
                </tr>
                <tr>
                    <td><span class="text-gray-600 pr-5">{{ $t("components.contactInformation.lastName") }}</span></td>
                    <td class="font-semibold">
                        <span v-if="errorMessage" class="text-red-600 italic text-sm">{{ errorMessage }}</span>
                        <span v-else>{{ decryptedData.lastName ? decryptedData.lastName : '-' }}</span>
                    </td>
                </tr>
                <tr>
                    <td><span class="text-gray-600 pr-5">{{ $t("components.contactInformation.email") }}</span></td>
                    <td class="font-semibold">
                        {{ submitterEmail ? submitterEmail : '-' }}
                    </td>
                </tr>
                <tr>
                    <td><span class="text-gray-600 pr-5">{{ $t("components.contactInformation.phone") }}</span></td>
                    <td class="font-semibold">
                        <span v-if="errorMessage" class="text-red-600 italic text-sm">{{ errorMessage }}</span>
                        <span v-else>{{ decryptedData.phone ? decryptedData.phone : '-' }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
