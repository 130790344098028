<script setup lang="ts">
import { computed, ref, defineExpose } from 'vue'
import type { ComputedRef } from 'vue'

interface StatusBadeConfig {
    name: string
    class: string
}

const config = {
    'NEW': { name: 'Neu' , class: 'bg-yellow-200' } ,
    'RECEIVED': { name: 'Eingegangen', class: 'bg-blue-200' },
    'UNDER_REVIEW': { name: 'In Prüfung', class: 'bg-blue-200' },
    'AWAITING_FEEDBACK': { name: 'Warte auf Rückmeldung', class: 'bg-blue-200' },
    'COMPLETED': { name: 'Abgeschlossen', class: 'bg-green-200' },
    'SUSPENDED': { name: 'Eingestellt', class: 'bg-gray-200' },
    'MARKED_FOR_DELETION': { name: 'Zur Löschung vorgemerkt', class: 'bg-gray-200' },
}

const props = defineProps<{ type: string }>()
const label: ComputedRef<StatusBadeConfig> = computed(
    (): StatusBadeConfig => typeof config[props.type] !== 'undefined' ? config[props.type] : {
        name: props.type,
        class: 'bg-gray-200',
    }
);

defineExpose(props)
</script>

<template>
    <span
        class="rounded px-2 py-1 uppercase font-bold text-xs tracking-wide"
        :class="label.class">
        {{ label.name }}
    </span>
</template>
