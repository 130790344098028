<script setup lang="ts">
import type { Ref } from 'vue'
import type { User } from '../reportKeys'

import { ref, defineProps, watch } from 'vue'
import gql from 'graphql-tag'
import { useLazyQuery } from "@vue/apollo-composable"
import Skeleton from 'primevue/skeleton'
import ProgressBar from 'primevue/progressbar'
import Password from 'primevue/password'
import Button from 'primevue/button'
import InputSwitch from 'primevue/inputswitch'

import { decrcyptPrivateKey, bus } from '../reportKeys'
import { StorageService, PiniaStorage } from '@/services/storage'
import { useKeyStore } from '@/services/storage/keyStorage'

import AppConfig from '@/config'

const keyStore = useKeyStore()

const store = new StorageService<User>({
    storage: new PiniaStorage(keyStore)
})

const meQuery = gql`
    query {
        me {
            identifier
            private_key
            salt
            iv
        }
    }
`

const props = defineProps(['encryptedReport'])

const isDecrypting: Ref<boolean> = ref(true)
const requiresPasswordPrompt: Ref<boolean> = ref(false)
const useMasterkey: Ref<boolean> = ref(false)
const password: Ref<string> = ref('')
const passwordValidationMessage: Ref<string | null> = ref(null)
const shareKeys: Ref<Record<string, string>> = ref(
    JSON.parse(props.encryptedReport.share_keys)
)

const getCurrentUser = () => {
    return store.restoreState('currentUser') as User
}

const currentUser = ref<User | null>(getCurrentUser())

const { result: userResult, loading, load: loadUser } = useLazyQuery(meQuery)

const decrcyptUserPrivateKey = async () => {
    const { error, currentUser: user } = await decrcyptPrivateKey(userResult.value.me, password.value, store)
    passwordValidationMessage.value = error
    currentUser.value = user

    if (! error) {
        requiresPasswordPrompt.value = false
        isDecrypting.value = false
    }
}

const decryptMasterKey = async () => {
    // @TODO: get masterkey and use
    const masterkeyUser = {
        identifier: 'master',
        iv: AppConfig.keys.iv,
        private_key: AppConfig.keys.privateKey,
        salt: AppConfig.keys.salt
    }

    // Don't pass a store to decryptPrivateKey so it does not get saved in the session
    const { error, currentUser: user } = await decrcyptPrivateKey(masterkeyUser, password.value)
    passwordValidationMessage.value = error
    currentUser.value = user

    if (! error) {
        requiresPasswordPrompt.value = false
        isDecrypting.value = false
    }
}

watch(userResult, async value => {
    requiresPasswordPrompt.value = true
})

bus.on(user => {
    if (!user) {
        currentUser.value = user
        requiresPasswordPrompt.value = true
        isDecrypting.value = true
        password.value = ''
    }
})

if (!currentUser.value) {
    loadUser()
} else {
    isDecrypting.value = false
}
</script>

<template>
    <div v-if="requiresPasswordPrompt || loading || isDecrypting" class="sm:flex">
        <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0 max-w-xl">
            <div v-if="loading">
                <Skeleton class="mb-2" />
                <Skeleton width="10rem" class="mb-2" />
                <Skeleton width="5rem" class="mb-2" />
                <Skeleton height="2rem" class="mb-2" />
                <Skeleton width="10rem" height="4rem" />
            </div>
            <div v-else-if="requiresPasswordPrompt">
                <form method="post" autocomplete="on" @submit.prevent="() => useMasterkey ? decryptMasterKey() : decrcyptUserPrivateKey()">
                    <h3 v-if="useMasterkey" class="text-lg font-bold text-gray-600 mb-4">Datenzugriff über das Wiederherstellungspasswort</h3>
                    <h3 v-else class="text-lg font-bold text-gray-600 mb-4">Datenzugriff über mein Benutzerpasswort</h3>
                    <div class="password-field">
                        <Password v-model="password"  :class="['block w-full mr-2', { 'p-invalid': passwordValidationMessage }]" autocomplete="on" placeholder="Password" name="password" :feedback="false" />
                        <p v-if="passwordValidationMessage" class="mt-2 text-red-600 text-sm">{{ passwordValidationMessage }}</p>
                    </div>
                    <div class="mt-4">
                        <Button type="submit" class="w-full" label="Entschlüsseln" />
                    </div>
                </form>
            </div>
            <div
                v-else-if="isDecrypting"
                class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 flex-none mr-2 text-gray-500">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                </svg>
                <ProgressBar class="flex-auto !h-2" mode="indeterminate"/>
            </div>
        </div>
        <div class="sm:w-1/2 sm:pl-4 prose prose-sm">
            <div class="bg-gray-50 p-4 md:p-6">
                <h3 class="mt-0">Warum muss ich jetzt mein Passwort angeben?</h3>
                <p>Alle Meldungen sind Ende-zu-Ende-Verschlüsselung und können nur mit ihrem Benutzerpasswort entschlüsselt werden. Da dies nur für die Laufzeit Ihrer Sitzung passieren kann, müssen Sie hierfür einmalig Ihr Passwort eingeben. Die Laufzeit endet mit dem Schließen des Browserfensters, dem Abmelden von Ihrem Benutzerkonto oder dem Wechseln der Beschäftigungsgeber.</p>
                <h3>Mit meinem Passwort erhalte ich keinen Zugriff</h3>
                <p>Falls Ihr Passwort nicht zum Entschlüsseln genutzt werden kann, können Sie zu jeder Zeit Zugriff mit dem Wiederherstellungspasswort auf Meldungen erhalten. Ihr Wiederherstellungspasswort ist das Notfall-Passwort, welches Ihnen bei Erstellung des Beschäftigungsgebers bereitgestellt wurde. Bitte fragen Sie den Inhaber des Beschäftigungsgebers, falls Ihr Benutzerpasswort nicht funktioniert oder die Neuverschlüsselung mit Ihrem privaten Passwort nicht vervollständigt wurde.</p>
                <div class="flex text-base mt-8">
                    <InputSwitch v-model="useMasterkey" class="mr-4" />
                    Datenzugriff über das Wiederherstellungspasswort
                </div>
            </div>
        </div>
    </div>
    <slot
        v-else-if="currentUser"
        :share-keys="shareKeys"
        :current-user="currentUser" />
    <div v-else>
        <p>Etwas ist schief gelaufen</p>
    </div>
</template>


<style scoped>
.password-field >>> .p-inputtext {
    width: 100%;
}
</style>
