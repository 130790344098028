<script lang="ts" setup>
import { ref, onBeforeUnmount, computed } from 'vue'
import Button from 'primevue/button'

import type { Ref } from 'vue'
import type { User, ContentEncrypted } from '@/containers/Report/reportKeys'
import type { File } from '@/containers/File/file'

import { decryptContent } from '@/containers/Report/reportKeys'

import axios from 'axios'

const props = defineProps<{
    item: File,
    user: User
    shareKeys: Record<string, string>
}>()

const isLoading = ref(false)
const isPlaying = ref(false)
const duration = ref(0)
const progress = ref(0)
const currentTime = ref(0)

const playTime = computed(() => {
    let min
    let sec
    min = Math.floor(currentTime.value / 60)
    min = (min >= 10) ? min : '0' + min
    sec =  Math.floor(currentTime.value % 60)
    sec = (sec >= 10) ? sec : '0' + sec

    return `${min}:${sec}`
})
const maxTime = computed(() => {
    let min
    let sec
    min = Math.floor(duration.value / 60)
    min = (min >= 10) ? min : '0' + min
    sec =  Math.floor(duration.value % 60)
    sec = (sec >= 10) ? sec : '0' + sec

    return `${min}:${sec}`
})

const audioPlayer: Ref<HTMLAudioElement | null>  = ref(null)

const initAudio = (audioSrc) => {
    audioPlayer.value = new Audio(audioSrc)
    audioPlayer.value.addEventListener('pause', onPause)
    audioPlayer.value.addEventListener('playing', onPlay)
    audioPlayer.value.addEventListener('ended', onEnd)
    audioPlayer.value.addEventListener('timeupdate', onProgress)
    audioPlayer.value.addEventListener('loadeddata', onLoaded)
    audioPlayer.value.addEventListener('durationchange', onLoaded)
}

/**
 * Clear Audio Listeners
 */
const clearAudio = () => {
    audioPlayer.value?.removeEventListener('pause', onPause)
    audioPlayer.value?.removeEventListener('playing', onPlay)
    audioPlayer.value?.removeEventListener('ended', onEnd)
    audioPlayer.value?.removeEventListener('timeupdate', onProgress)
    audioPlayer.value?.removeEventListener('loadeddata', onLoaded)
    audioPlayer.value?.removeEventListener('durationchange', onLoaded)
}

const onPause = () => {
    isPlaying.value = false
}

const onPlay = () => {
    isPlaying.value = true
}

const onEnd = () => {
    isPlaying.value = false
}

const onProgress = () => {
    if (audioPlayer.value && duration.value > 0) {
        progress.value = (Math.floor(audioPlayer.value?.currentTime) / duration.value) * 100
        currentTime.value = audioPlayer.value!.currentTime
    }
}

// Due to chrome bug duration is infinity for long time
const onLoaded = () => {
    if (audioPlayer.value && audioPlayer.value?.duration === Infinity || audioPlayer.value?.duration === 0) {
        audioPlayer.value.currentTime = 1e101
        audioPlayer.value.addEventListener('timeupdate', getDuration)
    } else {
        duration.value = Math.floor(audioPlayer.value!.duration)
    }
}
const getDuration = () => {
    audioPlayer.value!.currentTime = 0
    audioPlayer.value!.removeEventListener('timeupdate', getDuration)
    duration.value = Math.floor(audioPlayer.value!.duration)
}

const play = async () => {
    if (!audioPlayer.value) {
        await fetchAudio()
    }

    audioPlayer.value?.play()
}

const pause = () => {
    audioPlayer.value?.pause()
}

const fetchAudio = async () => {
    if (! props.item.download_url || isLoading.value) {
        return
    }
    isLoading.value = true

    try {
        const { data } = await axios.get(props.item.download_url, {
            method: 'GET',
            responseType: 'text'
        })

        const encryptedContent: ContentEncrypted = {
            content: data,
            iv: props.item.iv,
            shareKeys: props.shareKeys,
        }

        const content = await decryptContent(encryptedContent, props.user)
        const contentBlob = await b64toBlob(content, props.item.mime_type)

        initAudio(URL.createObjectURL(contentBlob as Blob))
    } catch (err) {
        console.error(err)
    }
    isLoading.value = false
}

const b64toBlob = (content, type = 'application/octet-stream'): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(`data:${type};base64,${content}`).then(res => resolve(res.blob())).catch(err => reject(err))
  })
}

onBeforeUnmount(() => {
    clearAudio()
})

defineExpose(props)
</script>

<template>
    <div class="border flex flex-col">
        <div class="flex justify-between items-center mr-4">
            <div class="flex items-center">
                <Button v-if="isPlaying" icon="pi pi-pause" class="p-button-text" @click="pause" />
                <Button v-else icon="pi pi-play" :loading="isLoading" class="p-button-text" @click="play" />
                <span class="text-gray-400 text-sm not-prose">{{ props.item.original_filename }}</span>
            </div>
            <span class="text-gray-400 text-sm not-prose">({{ playTime }} / {{ maxTime }})</span>
        </div>
        <progress :max="duration" :value="currentTime" class="w-full h-1"></progress>
    </div>
</template>

<style scoped>
progress[value] {
    background: var(--blue-50);
}
progress[value]::-webkit-progress-bar {
    background: var(--blue-50);
}
progress[value]::-webkit-progress-value {
    background: var(--primary-color);
}
progress[value]::-moz-progress-bar {
    background: var(--primary-color);
}
</style>
