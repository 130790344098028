import { createI18n } from 'vue-i18n'
import AppConfig from '@/config'
const locales = import.meta.glob('@/locales/*.json', { eager: true, import: 'default' })
const messages = {}

const getName = (path: string): string => {
    const parts = path.split('/')
    const partsExtension = parts[parts.length - 1]
    const localeName = partsExtension.split('.')[0]
    return localeName
}

for (const path in locales) {
    const localeName = getName(path)
    messages[localeName] = locales[path]
}
const i18n = createI18n({
    locale: AppConfig.locale,
    fallbackLocale: 'de',
    messages
})

export { i18n }
