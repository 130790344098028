<script lang="ts">
import { defineComponent } from 'vue'
import AppLogo from '@/components/AppLogo.vue'
import AppFlash from '@/containers/AppFlash.vue'

export default defineComponent({
    components: { AppLogo, AppFlash },
    props: {
        size: {
            type: String,
            required: false,
            default: 'max-w-lg'
        }
    }
})
</script>

<template>
    <div class="guest-layout min-h-screen flex justify-center items-start flex-col pt-6 md:pt-0 sm:items-center">
        <div :class="[ 'pt-4 md:pt-10', size, {'w-full': true} ]">
            <AppLogo size="lg" class="text-white mb-6 md:mb-8 flex justify-center" />
            <div class="bg-white w-full sm:rounded px-4 py-6 shadow-2xl md:px-8 md:py-10">
                <AppFlash />
                <slot></slot>
            </div>
        </div>
        <div class="text-center text-sm py-8 mt-6 px-4 text-white">
            <nav>
                <ul class="flex justify-center flex-wrap">
                    <li>
                        <a class="text-primary-50 hover:text-primary-200 active:text-primary-100" href="https://compentum.de/" target="_blank">compentum.de</a>
                    </li>
                    <li class="before:content-['•'] before:px-1.5">
                        <a class="text-primary-50 hover:text-primary-200 active:text-primary-100" href="https://compentum.de/impressum" target="_blank">Impressum</a>
                    </li>
                    <li class="before:content-['•'] before:px-1.5">
                        <a class="text-primary-50 hover:text-primary-200 active:text-primary-100" href="https://compentum.de/datenschutz/compentum-software" target="_blank">Datenschutz</a>
                    </li>
                    <li class="before:content-['•'] before:px-1.5">
                        <a class="text-primary-50 hover:text-primary-200 active:text-primary-100" href="https://nextindex.atlassian.net/servicedesk/customer/portal/6" target="_blank">Support</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<style scoped>
.guest-layout {
    background: linear-gradient(95.68deg,#18519E .11%,#1677D7 46.9%,#1561C7 99.11%);
}
</style>
