<script setup lang="ts">
import { ref, onMounted } from 'vue'
import type { Ref } from 'vue'
import gql from 'graphql-tag'
import { useQuery, useMutation } from "@vue/apollo-composable"
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import Button from 'primevue/button'
import Toast from 'primevue/toast'
import ConfirmDialog from 'primevue/confirmdialog'

const deleteMutation = gql`
    mutation deleteMessageTemplate($id: ID!) {
        deleteMessageTemplate(id: $id) {
            id, name, is_visible
        }
    }
`

const props = defineProps(['messageTemplateId', 'updateCache'])

const confirm = useConfirm();
const toast = useToast();

const confirmDeletion = () => {
    confirm.require({
        message: 'Wollen Sie die Antwortvorlage wirklich löschen?',
        group: props.messageTemplateId,
        header: 'Antwortvorlage löschen',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        acceptLabel: 'Löschen',
        rejectLabel: 'Abbrechen',
        accept: () => {
            deleteMessageTemplate()
            toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Antwortvorlage gelöscht', life: 3000 });
        },
    });
}

const { mutate: deleteMessageTemplate } = useMutation(deleteMutation, () => ({
    variables: {
        id: props.messageTemplateId
    },
    update: props.updateCache,
    refetchQueries: [
        { query: gql`query messageTemplates { messageTemplates { id, name, is_visible } }` }
    ]
}))

</script>

<template>
    <Button type="button" v-tooltip.bottom="'Löschen'" severity="danger" class="p-button-rounded p-button-text p-button-sm p-button-danger" icon="pi pi-trash" @click="confirmDeletion()" aria-haspopup="true" aria-controls="overlay_menu" />
    <Toast />
    <ConfirmDialog :group="props.messageTemplateId"/>
</template>
