<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import type { Ref } from 'vue'
import { useQuery, useLazyQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import Dropdown from 'primevue/dropdown'
import Skeleton from 'primevue/skeleton'

import NumbersWidget from '@/components/Widgets/NumbersWidget.vue'
import PieChart from '@/components/Widgets/PieChart.vue'
import BarChart from '@/components/Widgets/BarChart.vue'
import ReportsWidget from '@/components/Widgets/ReportsWidget.vue'

import AppFrame from '@/components/AppFrame.vue'

const getPortal = gql`
    query portals {
        portals {
            id
            name
        }
    }
`
const getStatistics = gql`
    query reportStatistics($id: ID) {
        reportStatistics(portal_id: $id) {
            average_processing_time
            submissions_per_month {
                month
                year
                count
            }
            open_reports_count_by_category {
                category
                count
            }
            reports_count_by_status {
                new
                received
                under_review
                awaiting_feedback
                completed
                suspended
                marked_for_deletion
            }
        }
    }
`

const statusConfig = {
    'new': 'Neu',
    'received': 'Eingegangen',
    'under_review': 'In Prüfung',
    'awaiting_feedback': 'Warte auf Rückmeldung',
    'completed': 'Abgeschlossen',
    'suspended': 'Eingestellt',
    'marked_for_deletion': 'Zur Löschung vorgemerkt',
}

const selectedPortal: Ref<string | null> = ref(null)
const portals = computed(() => {
    return [{ id: null, name: 'Alle Meldeportale' }, ...(result?.value?.portals ? result?.value?.portals : [])]
})

const { result, loading } = useQuery(getPortal)
const {
    result: resultStats,
    loading: loadingStats,
    refetch
} = useQuery(getStatistics, { id: selectedPortal.value })

watch(
    () => selectedPortal.value,
    val => {
        refetch({ id: val })
    }
)

const newSubmissions = computed(() => {
    return resultStats.value?.reportStatistics?.reports_count_by_status?.new
})

const openSubmissions = computed(() => {
    return resultStats?.value?.reportStatistics?.reports_count_by_status?.received +
        resultStats?.value?.reportStatistics?.reports_count_by_status?.under_review +
        resultStats?.value?.reportStatistics?.reports_count_by_status?.awaiting_feedback
})

const doneSubmissions = computed(() => {
    return resultStats?.value?.reportStatistics?.reports_count_by_status?.completed
})

const submissionsPerMonthLabel = computed(() => {
    return resultStats.value?.reportStatistics?.submissions_per_month?.map(item => item.month).reverse()
})

const submissionsPerMonthData = computed(() => {
    return resultStats.value?.reportStatistics?.submissions_per_month?.map(item => item.count).reverse()
})

const submissionsPerCategoryData = computed(() => {
    return resultStats.value?.reportStatistics?.open_reports_count_by_category?.map(item => item.count)
})

const submissionsPerCategoryLabels = computed(() => {
    return resultStats.value?.reportStatistics?.open_reports_count_by_category
        .map(item => item.category)
        .map(item => {
            return item ? item : 'Keine Kategorie'
        })
})

/**
 * Status statistics are given as an object with key = status, value = count
 * We need to remove all keys that are 0 and additionally remove __typename entry from gql
 * Otherwise the chart shows mulitple entries with 0
 * { new: 0, open: 3 }
 */
const nonEmptyStatus = computed(() => {
    return Object.entries(resultStats.value?.reportStatistics?.reports_count_by_status)
        .filter(([_, value]) => value as number > 0 )
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
})

const submissionsByStatusData = computed(() => {
    return Object.values(nonEmptyStatus.value)
})

const submissionsByStatusLabels = computed(() => {
    return Object.keys(nonEmptyStatus.value)
        .map(status => statusConfig[status])
})

const portalId = computed(() => {
    return selectedPortal.value ? selectedPortal.value : ''
})


</script>

<template>
    <AppFrame title="Übersicht" :boxed="false" class="max-w-7xl">
        <template #actions>
            <Dropdown
                v-model="selectedPortal"
                placeholder="Alle Meldeportale"
                :options="portals"
                optionLabel="name"
                optionValue="id"
                :loading="loading"
            />
        </template>

        <div class="relative">
            <div v-show="loadingStats" class="flex flex-row justify-between">
                <Skeleton width="100%" height="6rem" class="m-4" />
                <Skeleton width="100%" height="6rem" class="m-4" />
                <Skeleton width="100%" height="6rem" class="m-4" />
            </div>
            <div v-if="!loadingStats" class="flex">
                <div class="p-4 md:p-6 bg-white rounded shadow-lg flex-1">
                    <NumbersWidget :value="newSubmissions" label="Neue Verfahren" />
                </div>
                <div class="p-4 md:p-6 bg-white rounded shadow-lg flex-1 mx-4">
                    <NumbersWidget :value="openSubmissions" label="Offene Verfahren" />
                </div>
                <div class="p-4 md:p-6 bg-white rounded shadow-lg flex-1">
                    <NumbersWidget
                        :value="doneSubmissions"
                        label="Abgeschlossene Verfahren"
                    />
                </div>
            </div>
            <div class="my-4 flex flex-row">
                <div class="p-4 md:p-6 bg-white rounded shadow-lg h-full w-full">
                    <ReportsWidget :portal="portalId" class="w-full" />
                </div>
            </div>
            <div v-show="loadingStats" class="flex flex-row justify-between">
                <Skeleton width="33.3%" height="8rem" class="m-4" />
                <Skeleton width="60%" height="8rem" class="m-4" />
                <Skeleton width="33.3%" height="8rem" class="m-4" />
            </div>
            <div v-if="!loadingStats" class="flex flex-row my-2 flex-wrap -mx-2">
                <div class="w-1/3 p-2">
                    <div class="p-4 md:p-6 bg-white rounded shadow-lg h-full">
                        <PieChart :data="submissionsPerCategoryData" :labels="submissionsPerCategoryLabels"/>
                    </div>
                </div>
                <div class="w-2/3 p-2">
                    <div class="p-4 md:p-6 bg-white rounded shadow-lg h-full">
                        <BarChart :data="submissionsPerMonthData" :labels="submissionsPerMonthLabel" label="Neue Verfahren" />
                    </div>
                </div>
                <div class="w-1/3 p-2">
                    <div class="p-4 md:p-6 bg-white rounded shadow-lg h-full">
                        <PieChart
                            title="Verfahren nach Status"
                            :data="submissionsByStatusData"
                            :labels="submissionsByStatusLabels"
                        />
                    </div>
                </div>
                <div class="w-2/3 p-2">
                    <div class="p-4 md:p-6 bg-white rounded shadow-lg h-full">
                        <h3 class="text-left text-base text-slate-400 font-medium mb-8">compentum Neuerungen</h3>
                        <div class="prose prose-sm max-w-full">
                            <ul>
                                <li>
                                    <b>08. Januar 2024</b>
                                    <ul>
                                        <li>
                                            Einstellungen für Beschäftigungsgeber: Option zum Ändern des Wiederherstellungs-Passwort hinzugefügt <span class="text-xs bg-green-600 text-white rounded px-2 py-0.5 font-medium leading-none">Neu</span>
                                        </li>
                                        <li>
                                            Kleine Verbesserungen und Bugfixes <span class="text-xs bg-yellow-600 text-white rounded px-2 py-0.5 font-medium leading-none">Verbessert</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <b>11. Dezember 2023</b>
                                    <ul>
                                        <li>
                                            Chronologische Änderungshistorie zu Verfahren hinzugefügt <span class="text-xs bg-green-600 text-white rounded px-2 py-0.5 font-medium leading-none">Neu</span>
                                        </li>
                                        <li>
                                            Export aller Daten eines Beschäftigungsgebers <span class="text-xs bg-green-600 text-white rounded px-2 py-0.5 font-medium leading-none">Neu</span>
                                        </li>
                                        <li>
                                            Kleine Verbesserungen und Bugfixes <span class="text-xs bg-yellow-600 text-white rounded px-2 py-0.5 font-medium leading-none">Verbessert</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <b>08. November 2023</b>
                                    <ul>
                                        <li>
                                            Bessere Trennung zwischen internen Notizen und Nachrichten in Verfahren <span class="text-xs bg-yellow-600 text-white rounded px-2 py-0.5 font-medium leading-none">Verbessert</span>
                                        </li>
                                        <li>
                                            Eigene Kategorien können gelöscht werden, so lange keine verknüpften Verfahren eingereicht wurden <span class="text-xs bg-yellow-600 text-white rounded px-2 py-0.5 font-medium leading-none">Verbessert</span>
                                        </li>
                                        <li>
                                            Kleine Verbesserungen und Bugfixes <span class="text-xs bg-yellow-600 text-white rounded px-2 py-0.5 font-medium leading-none">Verbessert</span>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <b>16. Oktober 2023</b>
                                    <ul>
                                        <li>
                                            Benutzer werden über neue Anmeldungen über fremde Geräte per E-Mail informiert <span class="text-xs bg-green-600 text-white rounded px-2 py-0.5 font-medium leading-none">Neu</span>
                                        </li>
                                        <li>
                                            Mitarbeiter erhalten eine E-Mail, wenn eine neue Meldung eingereicht wird <span class="text-xs bg-yellow-600 text-white rounded px-2 py-0.5 font-medium leading-none">Verbessert</span>
                                        </li>
                                        <li>
                                            Benutzer und Portale können direkt beim Erstellen dazu gebucht werden <span class="text-xs bg-yellow-600 text-white rounded px-2 py-0.5 font-medium leading-none">Verbessert</span>
                                        </li>
                                        <li>
                                            Kleine Verbesserungen und Bugfixes <span class="text-xs bg-yellow-600 text-white rounded px-2 py-0.5 font-medium leading-none">Verbessert</span>
                                        </li>
                                    </ul>
                                </li>
                                <!-- <li>
                                    <b>Meldeportal</b>, 25.08.2023
                                    <span class="text-xs bg-yellow-600 text-white rounded px-2 py-0.5 font-medium leading-none">Verbessert</span>
                                    <p>Wir haben Mehrsprachigkeit für Meldeportale hinzugefügt.</p>
                                </li>
                                <li>
                                    <b>Sicherheit</b>, 02.07.2023
                                    <span class="text-xs bg-green-600 text-white rounded px-2 py-0.5 font-medium leading-none">Neu</span>
                                    <p>Wir haben die Möglichkeit hinzugefügt Zwei-Faktor-Authentifizierung zu aktivieren.</p>
                                </li>
                                <li>
                                    <b>Meldeportal</b>, 10.05.2023
                                    <span class="text-xs bg-green-600 text-white rounded px-2 py-0.5 font-medium leading-none">Neu</span>
                                    <p>Individualisierbare Texte und kostenlose Textvorlagen für neue Meldeportale.</p>
                                </li>
                                <li>
                                    <b>Sicherheit</b>, 11.04.2023
                                    <span class="text-xs bg-yellow-600 text-white rounded px-2 py-0.5 font-medium leading-none">Verbessert</span>
                                    <p>Das erneute verschlüsseln läuft jetzt schneller und übersichtlicher ab.</p>
                                </li>
                                <li>
                                    <b>Allgemeines</b>, 07.03.2023
                                    <span class="text-xs bg-yellow-600 text-white rounded px-2 py-0.5 font-medium leading-none">Verbessert</span>
                                    <p>Allgemeine Optimierung und Performance Verbesserungen, erweiterte Profileinstellungen.</p>
                                </li>
                                <li>
                                    <b>Fristen</b>, 06.02.2023
                                    <span class="text-xs bg-green-600 text-white rounded px-2 py-0.5 font-medium leading-none">Neu</span>
                                    <p>Wir haben die Darstellung der Fristen überarbeitet, damit diese eindeutiger sind.</p>
                                </li>
                                <li>
                                    <b>Antwortvorlagen</b>, 25.01.2023
                                    <span class="text-xs bg-green-600 text-white rounded px-2 py-0.5 font-medium leading-none">Neu</span>
                                    <p>Es können nun individuelle Antwortvorlagen erstellt und verwaltet werden.</p>
                                </li>
                                <li>
                                    <b>Filterung</b>, 20.12.2022
                                    <span class="text-xs bg-green-600 text-white rounded px-2 py-0.5 font-medium leading-none">Neu</span>
                                    <p>Sie können jetzt noch individueller nach Verfahren filtern und sortieren.</p>
                                </li>
                                <li>
                                    <b>Neuverschlüsselung</b>, 01.02.2023
                                    <span class="text-xs bg-red-600 text-white rounded px-2 py-0.5 font-medium leading-none">Behoben</span>
                                    <p>Es wurde ein Fehler behoben, wodurch der Ladebalken bei Neuverschlüsselung nicht beendet wurde.</p>
                                </li> -->
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </AppFrame>
</template>
