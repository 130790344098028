<script setup lang="ts">
import type { Ref } from 'vue'
import type { File } from './file'
import type { User, ContentEncrypted } from '@/containers/Report/reportKeys'

import { ref } from 'vue'
import axios from 'axios'
import ProgressSpinner from 'primevue/progressspinner'
import { saveAs } from 'file-saver'

import { decryptContent } from '@/containers/Report/reportKeys'

const props = defineProps<{
    item: File,
    user: User
    shareKeys: Record<string, string>
    readonly: boolean
}>()

const loading: Ref<boolean> = ref(false)
const errorMessage: Ref<string | null> = ref(null)

const downloadFile = async () => {
    if (! props.item.download_url || loading.value) {
        return
    }
    loading.value = true

    try {
        const { data } = await axios.get(props.item.download_url)

        const encryptedContent: ContentEncrypted = {
            content: data,
            iv: props.item.iv,
            shareKeys: props.shareKeys,
        }

        const content = await decryptContent(encryptedContent, props.user)
        const contentBlob = await b64toBlob(content, props.item.mime_type)
        saveAs(contentBlob, props.item.original_filename)
    } catch (err) {
        console.error(err)
        errorMessage.value = 'Konnte Datei nicht herunterladen.'
    }
    loading.value = false
}

const b64toBlob = (content, type = 'application/octet-stream'): Promise<any> => {
  return new Promise((resolve, reject) => {
    fetch(`data:${type};base64,${content}`).then(res => resolve(res.blob())).catch(err => reject(err))
  })
}

defineExpose(props)
</script>

<template>
    <button
        v-if="!readonly"
        @click.prevent="downloadFile"
        class="flex items-center">
        <span class="text-slate-700 mr-2 hover:text-black">
            {{ item.original_filename }}
        </span>
        <ProgressSpinner v-if="loading" style="width:20px;height:20px" strokeWidth="6" />
    </button>
    <span v-else class="flex items-center">
        <span class="text-slate-700 mr-2">
            {{ item.original_filename }}
        </span>
    </span>
    <div v-if="errorMessage" class="text-red-600">{{ errorMessage }}</div>
</template>
