<template>
    <div class="text-slate-400">
        <h3 class="text-center text-sm text-slate-400 font-medium mb-3">{{ props.title }}</h3>
        <Chart v-if="(data.length > 0)" type="pie" :data="chartData" :options="lightOptions" />
        <div v-else class="flex flex-row align-center items-center justify-center mt-5">
            <i class="pi pi-times-circle text-slate-400" style="font-size: 2rem"></i>
            <span class="ml-3 text-slate-400">Keine Daten verfügbar</span>
        </div>
    </div>
</template>

<script setup lang="ts">
import Chart from 'primevue/chart'
import { ref } from 'vue'

const props = defineProps({
    title: {
        type: String,
        default: 'Offene Fälle nach Kategorie'
    },
    data: {
        type: Array,
        default: () =>  []
    },
    chartColors: {
        type: Array,
        default: () => ['#004c6d','#2b5d7d','#466f8c','#5e819d','#7694ad','#8ea8be','#a6bbce','#becfe0','#d6e4f1']
    },
    chartColorsHover: {
        type: Array,
        default: () => ['#004c6d','#2b5d7d','#466f8c','#5e819d','#7694ad','#8ea8be','#a6bbce','#becfe0','#d6e4f1']
    },
    labels: {
        type: Array,
        default: () => [],
    }
})
const chartData = ref({
    labels: props.labels,
    datasets: [
        {
            data: props.data,
            backgroundColor: props.chartColors,
            hoverBackgroundColor: props.chartColorsHover
        }
    ]
});

const lightOptions = ref({
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                color: '#495057'
            }
        }
    }
});

</script>
