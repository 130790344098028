<script setup lang="ts">
import { ref, nextTick } from 'vue'
import type { Ref } from 'vue'
import gql from 'graphql-tag'
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'
import Message from 'primevue/message'
import { required, minLength, maxLength } from "@/utils/validators";
import { useVuelidate } from "@vuelidate/core";
import { useQuery } from "@vue/apollo-composable"

import GuestLayout from '@/components/Layouts/Guest.vue'
import InputGroup from '@/components/Form/InputGroup.vue'
import AppLink from '@/components/AppLink.vue'
import { CryptoService } from '../../services/index'
import { saveEmergencyKit } from '@/services/pdf'
import type { EmergencyKitOptions } from '@/services/pdf'

import AppConfig from '@/config'

const rules = {
    name: { required, minLength: minLength(3), maxLength: maxLength(128) },
    passphrase: { required, minLength: minLength(3), maxLength: maxLength(128) }
}
const success = ref(false)
const name = ref(null)
const passphrase: Ref<string> = ref('')

const v$ = useVuelidate(rules, { passphrase, name })

const privateKey: Ref<string | null> = ref(null)
const publicKey: Ref<string | null> = ref(null)
const iv: Ref<string | null> = ref(null)
const salt: Ref<string | null> = ref(null)
const isGeneratingKeyPair: Ref<boolean> = ref(false)
const isPassphraseSaved: Ref<boolean> = ref(false)
const organizationId: Ref<string> = ref('')
const isSaas: Ref<boolean> = ref(AppConfig.isSaas)
const trialSkipped: Ref<boolean> = ref(false)

const mutation = isSaas.value
    ? gql`
        mutation createOrganization ($name: String!, $publicKey: String!, $privateKey: String!, $salt: String!, $iv: String! ) {
            createOrganization (input: { name: $name, public_key: $publicKey, private_key: $privateKey, salt: $salt, iv: $iv }) {
                id
                name
                trial_skipped
            }
        }
    `
    : gql`
        mutation createOrganization ($name: String!, $publicKey: String!, $privateKey: String!, $salt: String!, $iv: String! ) {
            createOrganization (input: { name: $name, public_key: $publicKey, private_key: $privateKey, salt: $salt, iv: $iv}) {
                id
                name
            }
        }
    `

const meQuery = gql`
    query {
        me {
            email
        }
    }
`

const { result } = useQuery(meQuery)

// Crypto
const cryptoService = new CryptoService()

const generateKeys = async (password: string) => {
    const { publicKeyJwk, privateKeyJwk } = await cryptoService.generateUserKeyPair()

    // Salt for AES key
    const salt = window.crypto.getRandomValues(new Uint8Array(16));
    const iv = window.crypto.getRandomValues(new Uint8Array(12))

    // Generate a secure key off a password
    const key = await cryptoService.getKeyFromPassword(password)
    const aesKey = await cryptoService.getAESKeyFromPBKDF(key, salt)
    const encryptedPrivateKey = await cryptoService.encryptPrivateKey(privateKeyJwk, aesKey, iv)

    return {
        publicKey:btoa(JSON.stringify(publicKeyJwk)),
        privateKey: btoa(String.fromCharCode(...new Uint8Array(encryptedPrivateKey))),
        iv: btoa(String.fromCharCode(...new Uint8Array(iv))),
        salt: btoa(String.fromCharCode(...new Uint8Array(salt)))
    }
}

const beforeSubmit = async (mutate: Function) => {
    isGeneratingKeyPair.value = true
    const wallet = await generateKeys(passphrase.value)
    privateKey.value = wallet.privateKey
    publicKey.value = wallet.publicKey
    iv.value = wallet.iv
    salt.value = wallet.salt

    await nextTick()
    isGeneratingKeyPair.value = false
    await mutate()
}

const onDone = ({ data: { createOrganization } }) => {
    success.value = true
    organizationId.value = createOrganization.id

    if (isSaas.value) {
        trialSkipped.value = createOrganization.trial_skipped
    }
}

const onPressFinishDone = () => {
    document.location.href = trialSkipped.value
        ? '/' + organizationId.value + '/checkout/customer'
        : '/'
}

const savePassphrase = async () => {
    const a = document.createElement('a');

    const emergencyKit: EmergencyKitOptions = {
        companyUrl: `${location.protocol}//${location.host}/${organizationId.value}`,
        email: result.value.me?.email,
        companyMasterPassword: passphrase.value
    }

    const pdfTemplate = '/img/compentum-emergency-kit.pdf'
    const pdfBytes= await saveEmergencyKit(pdfTemplate, emergencyKit)

    a.href = window.URL.createObjectURL(new Blob([pdfBytes], {type: 'application/pdf'}))
    a.download = 'compentum-emergency-kit.pdf'
    a.click();
    isPassphraseSaved.value = true
}

</script>

<template>
    <GuestLayout size="max-w-5xl">
        <div v-if="!success" class="sm:flex">
            <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0">
                <ApolloMutation
                    :mutation="mutation"
                    :variables="{ name, publicKey, privateKey, iv, salt }"
                    @done="onDone">
                    <template v-slot="{ mutate, loading, error }">
                        <Message v-if="error" severity="error">{{ error.message }}</Message>
                        <form method="post" autocomplete="on" @submit.prevent="beforeSubmit(mutate)">
                            <h3 class="text-2xl font-bold text-gray-600 text-center mb-4">Angaben zu dem Beschäftigungsgeber</h3>
                            <InputGroup>
                                <InputText v-model="name" placeholder="Firmenname des Beschäftigungsgeber" name="name" type="text" autofocus />
                            </InputGroup>
                            <InputGroup>
                                <Password v-model="passphrase" autocomplete="off" placeholder="Wiederherstellungspasswort" name="password" :toggle-mask="true" />
                            </InputGroup>

                            <Button :disabled="v$.$invalid" :loading="loading || isGeneratingKeyPair" type="submit" class="block w-full" label="Beschäftigungsgeber erstellen" />
                        </form>
                    </template>
                </ApolloMutation>
            </div>
            <div class="sm:w-1/2 sm:pl-4 prose prose-sm">
                <h3>Benennung des Beschäftigungsgebers</h3>
                <p>Der Name des Beschäftigungsgebers dient der Übersicht und benötigt daher keine Rechtsform bei der Vergabe eines Firmennamens. In diesem Schritt wird keine Rechnungsadresse oder andere Stammdaten erfasst. Sie können später weitere Instanzen (z. B. Beschäftigungsgeber, Subunternehmen oder Abteilungen) mit einer anderen Bezeichnung hinterlegen.</p>
                <h3>Wiederherstellungspasswort für den Beschäftigungsgeber</h3>
                <p>compentum arbeitet mit einer Ende-zu-Ende-Verschlüsselung. Als Backup erhält der Beschäftigungsgeber ein Wiederherstellungspasswort. Speichern Sie Ihr Passwort an einem für Dritte unzugänglichen Speicherort. Bei Verlust dieses Passworts können wir den Zugriff auf Ihre Daten nicht mehr gewährleisten.</p>
            </div>
        </div>

        <div v-else class="prose prose-sm mx-auto">
            <Message :closable="false" severity="success">Ihre Instanz steht jetzt für Sie bereit.</Message>
            <h1>Die nächsten Schritte</h1>
            <p>Vielen Dank für Ihr Vertrauen. Sie erhalten in den nächsten Tagen Informationen zum Portal und dem Umgang hiermit. Sollten Sie Fragen haben, können Sie sich jederzeit an uns wenden. Kennen Sie schon unseren <a href="https://compentum.de/loesungen/ombudsservice" target="_blank" rel="noopener noreferrer">Ombudsservice</a>?</p>
            <h2>Aktivieren der E-Mail-Adresse</h2>
            <p>Sie erhalten in den nächsten Minuten eine E-Mail mit einem Bestätigungslink. Bitte verifizieren Sie darüber Ihr Kundenkonto.</p>
            <h2>Informiert bleiben</h2>
            <p>Unser kompakter und kostenloser Newsletter zum Thema HinSchG hält Neuerungen zur Gesetzeslage und weitere relevante Informationen zu diesem Themenbereich für Sie bereit. Diesen müssen Sie separat abonnieren. <a href="https://compentum.de/newsletter" target="_blank" rel="noopener noreferrer">Mehr erfahren</a>.</p>
            <h2>Verschlüsselung</h2>
            <p>compentum arbeitet mit einer echten Ende-zu-Ende-Verschlüsselung. Daher ist es - ebenso wie bei Ihrem Handy oder ähnlich verschlüsselten Geräten - für uns und Dritte nicht möglich, die Daten einzusehen. Mit dem Wiederherstellungspasswort können Sie notfalls Ihre Daten entschlüsseln. Es ist daher wichtig, dass Sie das sichern. Im täglichen Betrieb werden die Daten mit Ihrem Benutzerpasswort ver- und entschlüsselt.</p>
            <h2>Emergency Kit speichern</h2>
            <p>Notieren Sie Ihr persönliches Passwort sofort im Emergency Kit, um bei Verlust darauf zurückgreifen zu können. Bei Verlust Ihrer Passwörter ist die Wiederherstellung Ihrer Daten <b>nicht</b> mehr möglich.</p>
            <Message severity="warn">Solange Sie das Emergency Kit nicht gespeichert haben, können Sie die Einrichtung noch nicht abschließen.</Message>

            <div class="mt-4">
                <Button @click.prevent="savePassphrase" class="block w-full" label="Jetzt speichern" />
            </div>

            <div class="mt-6">
                <Button :disabled="!isPassphraseSaved" @click.prevent="onPressFinishDone" class="block w-full" label="Einrichtung abschließen" />
            </div>
        </div>
    </GuestLayout>

</template>
