<template>
    <div class="field mb-4">
        <slot></slot>
    </div>
</template>

<style scoped>
.field >>> .p-inputwrapper, .field >>> input {
    @apply block w-full;
}
</style>
