<script setup lang="ts">
import type { Ref } from 'vue'
import gql from 'graphql-tag'
import { ref, nextTick, onMounted, computed } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import Skeleton from 'primevue/skeleton'
import AppFrame from '@/components/AppFrame.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'
import InputGroup from '@/components/Form/InputGroup.vue'
import Password from 'primevue/password'
import Button from 'primevue/button'
import AppConfig from '@/config'
import { CryptoService } from '../../services/index'
import { decrcyptPrivateKey } from '@/containers/Report/reportKeys'

const toast = useToast();
const loading = ref(false)
const isGeneratingKeyPair: Ref<boolean> = ref(false)

const name = ref('')
const privateKey: Ref<null | string> = ref(null)
const salt: Ref<null | string> = ref(null)
const iv: Ref<null | string> = ref(null)
const currentPassword: Ref<string> = ref('')
const newPassword = ref(null)
const newPasswordConfirmation = ref(null)

const errorMessages: Ref<any> = ref(null)
const passwordValidationMessage: Ref<string | null> = ref(null)
const passwordErrorMessage: Ref<string | null> = ref(null)
const submitBlocked: Ref<boolean> = computed(() => {
    return newPassword.value === null || 
        currentPassword.value === null || 
        newPasswordConfirmation.value === null ||
        newPassword.value === '' || 
        currentPassword.value === '' || 
        newPasswordConfirmation.value == ''
})
const cryptoService = new CryptoService()


const showSuccess = () => {
    toast.add({ severity: 'success', summary: 'Erfolgreich gespeichert', detail: 'Ihre Einstellungen wurden erfolgreich aktualisiert.', life: 3000 });
    currentPassword.value = ''
    newPassword.value = null
    newPasswordConfirmation.value = null
}

const mutation = gql`
    mutation updateOrganization ($name: String, $industry: String, $private_key: String, $salt: String, $iv: String) {
        updateOrganization (input: { name: $name, industry: $industry, private_key: $private_key, salt: $salt, iv: $iv }) {
           name
        }
    }`

const onDone = () => {
    showSuccess()
}

const onError = (error) => {
    errorMessages.value = error
}

const updateRecoveryKeys = async (password: string) => {
    const masterkeyUser = {
        identifier: 'master',
        iv: AppConfig.keys.iv,
        private_key: AppConfig.keys.privateKey,
        salt: AppConfig.keys.salt
    }
    const { error, currentUser: user } = await decrcyptPrivateKey(masterkeyUser, currentPassword.value)
    passwordValidationMessage.value = error

    const { privateKeyJwk } = user
    // Salt for AES key
    const salt = window.crypto.getRandomValues(new Uint8Array(16))
    const iv = window.crypto.getRandomValues(new Uint8Array(12))

    // Generate a secure key off a password
    const key = await cryptoService.getKeyFromPassword(password)
    const aesKey = await cryptoService.getAESKeyFromPBKDF(key, salt)
    const encryptedPrivateKey = await cryptoService.encryptPrivateKey(privateKeyJwk, aesKey, iv)

    return {
        privateKey: cryptoService.arrayBufferToBase64(encryptedPrivateKey),
        iv: cryptoService.arrayBufferToBase64(iv),
        salt: cryptoService.arrayBufferToBase64(salt)
    }
}

const beforeSubmit = async (mutate: Function) => {
    passwordErrorMessage.value = null
    if (newPassword.value === null || currentPassword.value === null) {
        return
    }

    if (newPassword.value !== newPasswordConfirmation.value) {
        passwordErrorMessage.value = "Neues Passwort stimmt nicht überein."
        return
    }

    isGeneratingKeyPair.value = true
    const wallet = await updateRecoveryKeys(newPassword.value)
    privateKey.value = wallet.privateKey
    iv.value = wallet.iv
    salt.value = wallet.salt

    await nextTick()
    isGeneratingKeyPair.value = false
    await mutate()
}



</script>

<template>
    <Toast />
    <Skeleton v-if="loading" width="100%" height="23rem"/>
    <AppFrame v-else :full-width="true">
        <h3 class="text-lg font-bold text-gray-600 mb-4">Wiederherstellungspasswort ändern</h3>
        <ValidationMessage v-if="errorMessages" :response="errorMessages" />
        <div class="sm:flex">
            <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0">
                <ApolloMutation
                    :mutation="mutation"
                    :variables="{ iv, salt, private_key: privateKey }"
                    @done="onDone"
                    @error="onError"
                >
                    <template v-slot="{ mutate, loading, error }">
                        <ValidationMessage v-if="error" :response="error" />
                        <form method="post" autocomplete="on" @submit.prevent="beforeSubmit(mutate)">
                            <InputGroup>
                                <Password v-model="currentPassword" autocomplete="off" placeholder="Aktuelles Passwort" name="password" :toggle-mask="true" :class="['block mr-2', { 'p-invalid': passwordValidationMessage }]" />
                                <p class="mt-2 text-red-600 text-sm">{{ passwordValidationMessage }}</p>
                            </InputGroup>
                            <InputGroup>
                                <Password v-model="newPassword" autocomplete="off" placeholder="Neues Passwort" name="password" :toggle-mask="true" :class="['block mr-2', { 'p-invalid': passwordErrorMessage }]"/>
                                <p class="mt-2 text-red-600 text-sm">{{ passwordErrorMessage }}</p>
                            </InputGroup>
                            <InputGroup>
                                <Password v-model="newPasswordConfirmation" autocomplete="off" placeholder="Neues Passwort bestätigen" name="password_confirmation" :toggle-mask="true" :feedback="false" :class="['block mr-2', { 'p-invalid': passwordErrorMessage }]" />
                                <p class="mt-2 text-red-600 text-sm">{{ passwordErrorMessage }}</p>
                            </InputGroup>
                            <Button  :loading="loading" :disabled="submitBlocked" type="submit" class="block w-full" label="Speichern" />
                        </form>
                    </template>
                </ApolloMutation>
            </div>

            <div class="sm:w-1/2 sm:pl-4 prose prose-sm">
                <h3>Sicherheit</h3>
                <p>Notieren Sie sich das <a href="https://compentum.de/docs/hilfe/verschl%C3%BCsselung.html#wiederherstellungspasswort-2">Wiederherstellungspasswort</a> und bewahren Sie es an einem sicheren Ort auf.</p>

            </div>
  
        </div>
    </AppFrame>
</template>
!
