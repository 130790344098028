<script setup lang="ts">
import { defineProps } from 'vue'

import GuestLayout from '@/components/Layouts/Guest.vue'
import AppLogout from '@/containers/Auth/AppLogout.vue'
import AppTenantMenu from '@/components/AppTenantMenu.vue'

const props = defineProps({
    'icon': {
        type: String,
        default: ''
    },
    'title': {
        type: String,
        required: true
    },
    'message': {
        type: String,
        required: true
    },
})
</script>

<template>
    <GuestLayout>
        <div class="flex flex-col items-center max-w-sm mx-auto">
            <span v-if="props.icon !== ''" class="bg-primary-500 p-5 rounded-full mb-6">
                <i class="pi text-white" :class="props.icon" style="font-size: 4rem"></i>
            </span>
            <h1 class="text-2xl font-bold text-gray-800 mb-2 text-center">{{ props.title }}</h1>
            <p class="text-slate-600 text-center mb-4">{{ props.message }}</p>
            <div class="relative text-white w-full mb-4">
                <AppTenantMenu
                    :unselected="true"
                    :overlay="false" />
            </div>
            <AppLogout></AppLogout>
        </div>
    </GuestLayout>
</template>
