export interface UnuploadedFile {
    type : string
    objectURL?: string
    size: number
    name: string
    iv?: string
    file?: Blob
}

export interface File {
    id: string
    filename: string
    original_filename: string
    mime_type: string
    is_voice_message: boolean
    iv: string
    download_url?: string
    size?: string
    created_at?: string
}

export interface EncryptedFile {
    mime_type : string
    iv: string
    file: any
    is_voice_message?: boolean
}

export const readFileAsync = (file): Promise<any> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = reject
        reader.readAsArrayBuffer(file)
    })
}
