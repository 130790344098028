import gql from 'graphql-tag'

export const confidentialReportMutation = gql`
    mutation addConfidentialReport (
            $submitType: SubmitType!,
            $portalSlug: String!,
            $content: String!,
            $contentIv: String!,
            $contactInformation: String!,
            $reportIv: String!
            $email: String,
            $submitterPublicKey: String!,
            $submitterPrivateKey: String!,
            $submitterSalt: String!,
            $submitterIv: String!
            $shareKeys: JSON!
            $attachments: [FileInput],
            $categoryId: ID
        ) {
        addReport (
            input: {
                portal_slug: $portalSlug,
                submit_type: $submitType,
                first_message: {
                    iv: $contentIv,
                    content: $content,
                    attachments: $attachments,
                }
                contact_information: $contactInformation,
                report_iv: $reportIv,
                submitter_public_key: $submitterPublicKey,
                submitter_private_key: $submitterPrivateKey,
                submitter_salt: $submitterSalt,
                submitter_iv: $submitterIv,
                share_keys: $shareKeys,
                submitter_email: $email,
                category: { connect: $categoryId }
            }
        ) {
            identifier
        }
    }
`

export const anonymousReportMutation = gql`
    mutation addAnonymousReport ($submitType: SubmitType!,
        $portalSlug: String!,
        $content: String!,
        $contentIv: String!,
        $submitterPublicKey: String!,
        $submitterPrivateKey: String!,
        $submitterSalt: String!,
        $submitterIv: String!
        $reportIv: String!
        $shareKeys: JSON!
        $attachments: [FileInput]
        $categoryId: ID
    ) {
        addReport (
            input: {
                portal_slug: $portalSlug,
                submit_type: $submitType,
                first_message: {
                    iv: $contentIv,
                    content: $content,
                    attachments: $attachments,
                }
                submitter_public_key: $submitterPublicKey,
                submitter_private_key: $submitterPrivateKey,
                submitter_salt: $submitterSalt,
                submitter_iv: $submitterIv,
                report_iv: $reportIv,
                share_keys: $shareKeys,
                category: { connect: $categoryId }
            }
        ) {
            identifier
        }
    }
`

export const createReportMutation = gql`
    mutation createReport (
            $submitType: SubmitType!,
            $content: String!,
            $contentIv: String!,
            $contactInformation: String,
            $reportIv: String!
            $email: String,
            $submitterPublicKey: String!,
            $submitterPrivateKey: String!,
            $submitterSalt: String!,
            $submitterIv: String!
            $shareKeys: JSON!
            $attachments: [FileInput],
            $categoryId: ID
            $portalId: ID
            $internal: Boolean
            $reportingChannel: ReportingChannel
        ) {
            createReport (
            input: {
                submit_type: $submitType,
                first_message: {
                    iv: $contentIv,
                    content: $content,
                    attachments: $attachments,
                }
                contact_information: $contactInformation,
                report_iv: $reportIv,
                submitter_public_key: $submitterPublicKey,
                submitter_private_key: $submitterPrivateKey,
                submitter_salt: $submitterSalt,
                submitter_iv: $submitterIv,
                share_keys: $shareKeys,
                submitter_email: $email,
                reporting_channel: $reportingChannel
                category: { connect: $categoryId }
                portal: { connect: $portalId }
                internal: $internal
            }
        ) {
            id
            identifier
            submit_type
            created_at
            internal
            deadline {
                date
                remaining_days
            }
            completed_at {
                date
                processing_time
            }
            status
            messages {
                count
            }
            portal {
                id
                name
                url
            }
            category {
                id
                name
            }
        }
    }
`
