<script setup lang="ts">
import { ref, defineProps, defineEmits, watch } from 'vue'
import type { Ref } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import { useVuelidate } from "@vuelidate/core"
import { required, maxLength } from "@/utils/validators";
import Message from 'primevue/message'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Textarea from 'primevue/textarea'
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner'


const rules = {
    templateName: { required, maxLength: maxLength(255) },
    templateContent: { required },
    templateVisible: { required },
}

const mutation = gql`
    mutation updateMessageTemplate ($id: ID!, $name: String, $content: String, $is_visible: Boolean) {
        updateMessageTemplate (id: $id, input: { name: $name, content: $content , is_visible: $is_visible}) {
            id, name, content, is_visible
        }
    }
`

const query = gql`
    query messageTemplate ($id: ID!) {
        messageTemplate (id: $id) {
            id, name, content, is_visible
        }
    }
`

const props = defineProps(['modelValue', 'messageTemplate'])
const emit = defineEmits(['update:modelValue'])

const success: Ref<boolean> = ref(false)
const templateName: Ref<string | null> = ref(props.messageTemplate.name)
const templateContent: Ref<string> = ref('')
const templateVisible: Ref<boolean> = ref(props.messageTemplate.is_visible)
const dialogVisible = ref(props.modelValue)
const variables = ref({ id: props.messageTemplate.id })

watch(() => props.modelValue, (val) => dialogVisible.value = val)
watch(dialogVisible, (val) => emit('update:modelValue', val))

const v$ = useVuelidate(rules, { templateName, templateContent, templateVisible })

const { result, loading, error, onResult } = useQuery(query, variables, () => ({enabled: dialogVisible.value, }))

const queryLoading = ref(loading.value)

onResult(queryResult => {
    if (queryResult.data) {
        templateName.value = queryResult.data.messageTemplate.name
        templateContent.value = queryResult.data.messageTemplate.content
        templateVisible.value = queryResult.data.messageTemplate.is_visible
    }

    queryLoading.value = false
})

const onDone = ({ data: { updateMessageTemplate } }) => {
    if (updateMessageTemplate) {
        success.value = true
    }
}
</script>

<template>
    <Dialog v-model:visible="dialogVisible" :header="`Edit message template`" position="top" modal :dismissable-mask="false"
        :close-on-escape="true" :draggable="false" :style="{ width: '700px' }">
        <ApolloMutation :mutation="mutation"
            :variables="{ id: messageTemplate.id, name: templateName, content: templateContent, is_visible: templateVisible }"
            @done="onDone">
            <template v-slot="{ mutate, loading, error }">
                <Message v-if="error" severity="error">{{ error.message }}</Message>
                <Message v-if="success" severity="success">Antwortvorlage erfolgreich gespeichert.</Message>
                <form method="post" autocomplete="on" @submit.prevent="() => !v$.$invalid && mutate()">
                    <div class="p-inputgroup my-4">
                        <InputText v-model="templateName" placeholder="Name" :disabled="queryLoading" />
                    </div>
                    <div class="p-inputgroup my-4">
                        <Textarea v-model="templateContent" placeholder="Inhalt" rows="8" :disabled="queryLoading" />
                    </div>
                    <div class="p-inputgroup my-4">
                        <InputSwitch v-model="templateVisible" id="templateVisible" :disabled="queryLoading" />
                        <label for="templateVisible" class="ml-2">Sichtbar</label>
                    </div>
                    <Button type="submit" :loading="(loading || queryLoading)" :disabled="v$.$invalid">Speichern</Button>
                </form>
            </template>
        </ApolloMutation>
    </Dialog>
</template>
