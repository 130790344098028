<script setup lang="ts">
import { ref, defineProps } from 'vue'

import GuestLayout from '@/components/Layouts/Guest.vue'
import AppTenantMenu from '@/components/AppTenantMenu.vue'
import AppConfig from '../../config'

const props = defineProps({
    showTrialExpiredMessage: {
        type: Boolean,
        required: true,
    },
    showTenantMenu: {
        type: Boolean,
        required: true,
    },
    currentRole: {
        type: String,
        required: true,
    },
})
</script>

<template>
    <GuestLayout size="max-w-5xl">
        <div class="max-w-xl mx-auto text-center text-lg">
            <h1 class="text-3xl font-bold mb-4" v-if="props.showTrialExpiredMessage">Die Demo-Version ist abgelaufen</h1>
            <h1 class="text-3xl font-bold mb-4" v-else>Das Abonnement wurde beendet</h1>
            <p class="mb-4" v-if="props.currentRole === 'owner' && props.showTrialExpiredMessage">
                Die Demo-Version ist abgelaufen. Sie können jederzeit ein Abonnement abschließen, um wieder Zugriff auf alle
                Funktionen zu erhalten.
            </p>
            <p class="mb-4" v-else-if="props.currentRole === 'employee' && props.showTrialExpiredMessage">
                Die Demo-Version ist abgelaufen. Bitte wenden Sie sich an einen der Inhaber, um ein Abonnement zu erwerben.
            </p>
            <p class="mb-4" v-else-if="props.currentRole === 'owner'">
                Das Abonnement wurde beendet. Sie können es jederzeit erneuern, um weiterhin Zugriff auf alle Funktionen zu
                erhalten.
            </p>
            <p v-else class="mb-4">
                Das Abonnement wurde beendet. Bitte wenden Sie sich an einen der Inhaber, um es erneut aktivieren zu lassen.
            </p>
            <div class="mt-2 mb-4" v-if="props.currentRole === 'owner'">
                <a :href="`/${$appConfig.tenantSlug}/checkout/customer`" class="p-button p-component">
                    <span class="font-bold inline-flex items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-4 h-4 mr-1">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
                        </svg>
                        Jetzt buchen
                    </span>
                </a>
            </div>
        </div>
        <div class="mx-auto max-w-sm" v-if="props.showTenantMenu">
            <h3 class="text-lg font-bold mb-2">Zu anderen Beschäftigungsgeber wechseln:</h3>
            <div class="relative text-white mb-4 bg-primary-850 rounded-lg">
                <AppTenantMenu :unselected="true" :overlay="true" :white-label="true"/>
            </div>
        </div>
    </GuestLayout>
</template>
