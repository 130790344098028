<script setup lang="ts">
import type { Ref } from 'vue'
import gql from 'graphql-tag'
import { ref, defineProps, onUpdated } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import Skeleton from 'primevue/skeleton'
import AppFrame from '@/components/AppFrame.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'
import InputGroup from '@/components/Form/InputGroup.vue'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'


const props = defineProps({
    userResult: {
        type: Object,
        required: true,
        default: () => ({})
    }
})
const toast = useToast();
const loading = ref(true)
const firstName = ref('')
const lastName = ref('')
const errorMessages: Ref<any> = ref(null)

const showSuccess = () => {
    toast.add({ severity: 'success', summary: 'Erfolgreich gespeichert', detail: 'Ihre persönlichen Details wurden erfolgreich aktualisiert.', life: 3000 });
}

const mutation = gql`
    mutation updateMe ($firstName: String, $lastName: String) {
        updateMe (input: { first_name: $firstName, last_name: $lastName }) {
           first_name,
           last_name
        }
    }`

onUpdated(() => {
    if (props.userResult) {
        firstName.value = props.userResult?.me?.first_name
        lastName.value = props.userResult?.me?.last_name

        loading.value = false
    }
})

const onDone = () => {
    showSuccess()
}

const onError = (error) => {
    errorMessages.value = error
}


</script>

<template>
    <Toast />
    <Skeleton v-if="loading" width="100%" height="23rem"/>
    <AppFrame v-else :full-width="true">
        <h3 class="text-lg font-bold text-gray-600 mb-4">Personliche Details</h3>
        <ValidationMessage v-if="errorMessages" :response="errorMessages" />
        <div class="sm:flex">
            <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0">
                <ApolloMutation
                    :mutation="mutation"
                    :variables="{ firstName, lastName }"
                    @done="onDone"
                    @error="onError"
                >
                    <template v-slot="{ mutate, loading, error }">
                        <ValidationMessage v-if="error" :response="error" />
                        <form method="post" autocomplete="on" @submit.prevent="mutate">
                            <InputGroup>
                                <InputText v-model="firstName" placeholder="Vorname" name="first_name" />
                            </InputGroup>
                            <InputGroup>
                                <InputText v-model="lastName" placeholder="Nachname" name="last_name" />
                            </InputGroup>

                            <Button  :loading="loading" type="submit" class="block w-full" label="Speichern" />
                        </form>
                    </template>
                </ApolloMutation>
            </div>
            <div class="sm:w-1/2 sm:pl-4 prose prose-sm">
                <h3>Personliche Daten</h3>
                <p>Hier können Sie Ihren Vor- und Nachnamen ändern.</p>
            </div>
        </div>
    </AppFrame>
</template>
