<script setup lang="ts">
import { computed } from 'vue'
import type { Ref } from 'vue'
import type { User } from './reportKeys'
import type { Category } from '@/config'
import type { UnuploadedFile, EncryptedFile } from '@/containers/File/file'

import { nextTick, ref, watch } from 'vue'
import gql from 'graphql-tag'
import { email as emailValidator, required, requiredUnless, maxLength, minLength, helpers } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import InputText from 'primevue/inputtext'
import InputTextarea from 'primevue/textarea'
import InputSwitch from 'primevue/inputswitch'
import Button from 'primevue/button'
import ProgressBar from 'primevue/progressbar'
import Dropdown from 'primevue/dropdown'
import Message from 'primevue/message'

import AppConfig from '@/config'
import PasswordService from '@/services/password/PasswordService'
import CryptoService from '@/services/crypto/CryptoService'
import ValidationMessage from '@/components/ValidationMessage.vue'
import InputGroup from '@/components/Form/InputGroup.vue'
import FileUpload from '@/containers/File/FileUpload.vue'

import { readFileAsync } from '@/containers/File/file'
import { generateKeys, encryptContentWithoutReportKey, encryptContent } from './reportKeys'
import ReportConfirmation from './ReportConfirmation.vue'
import { createReportMutation } from './mutations'
import { REPORT_QUERY } from './queries'
import { buildQueryVariables } from './ReportIndexState'

const reportingChannelOptions = [
    { label: 'schriftlich (Mail, Fax, Brief)', value: 'WRITTEN' },
    { label: 'mündlich (Telefon oder persönliche Zusammenkunft)', value: 'VERBALLY' }
]

const crypto = new CryptoService()

const confirmMessage: Ref<string> = ref(AppConfig.confirmMessage)
const firstName: Ref<string | null> = ref(null)
const lastName: Ref<string | null> = ref(null)
const email: Ref<string | null> = ref(null)
const phone: Ref<string | null> = ref(null)
const content: Ref<string> = ref('')
const files: Ref<Array<UnuploadedFile>> = ref([])
const audio: Ref<Array<UnuploadedFile>> = ref([])
const contactInformation: Ref<string | null> = ref(null)
const plainText: Ref<string> = ref('')
const selectedCategory: Ref<string | null> = ref(null)
const selectedPortal: Ref<any | null> = ref(null)
const anonymous: Ref<boolean> = ref(true)
const success: Ref<boolean> = ref(false)

const submitterPrivateKey: Ref<string | null> = ref(null)
const submitterPublicKey: Ref<string | null> = ref(null)
const submitterIv: Ref<string | null> = ref(null)
const submitterSalt: Ref<string | null> = ref(null)
const identifier: Ref<string> = ref('')
const passphrase: Ref<string[]> = ref([])
const isProcessing: Ref<boolean> = ref(false)
const isEncryptFiles: Ref<boolean> = ref(false)
const progressValue: Ref<number> = ref(0)
const reportIv: Ref<string | null> = ref(null)
const contentIv: Ref<string | null> = ref(null)
const shareKeys: Ref<string | null> = ref(null)
const reportingChannel: Ref<string | null> = ref(null)
const portalUrl: Ref<string | null> = ref(null)

// Merging audio recording and normal files
const mergedFiles = computed(() => {
    return [...files.value, ...audio.value]
})

const rules = {
    firstName: {
        requiredUnlessAnonymous: helpers.withMessage('Vorname ist ein Pflichtfeld', requiredUnless(anonymous)),
        maxLength: helpers.withMessage('Bitte geben Sie maximal 128 Zeichen ein', maxLength(128))
    },
    lastName: {
        requiredUnlessAnonymous: helpers.withMessage('Nachname ist ein Pflichtfeld', requiredUnless(anonymous)),
        maxLength: helpers.withMessage('Bitte geben Sie maximal 128 Zeichen ein', maxLength(128))
    },
    phone: {
        minLength: helpers.withMessage('Bitte geben Sie eine gültige Telefonnummer (inkl. Vorwahl) an', minLength(6)),
        maxLength: helpers.withMessage('Bitte geben Sie eine gültige Telefonnummer (inkl. Vorwahl) an', maxLength(128))
    },
    email: {
        requiredUnlessAnonymous: helpers.withMessage('E-Mail-Adresse ist ein Pflichtfeld', requiredUnless(anonymous)),
        maxLength: helpers.withMessage('Bitte geben Sie maximal 256 Zeichen ein', maxLength(256)),
        email: helpers.withMessage('Bitte geben Sie eine gültige E-Mail-Adresse an', emailValidator),
    },
    selectedCategory: { required: helpers.withMessage('Kategorie ist ein Pflichtfeld', required) },
    reportingChannel: { required: helpers.withMessage('Meldekanal ist ein Pflichtfeld', required) },
    plainText: { required: helpers.withMessage('Beschreibung ist ein Pflichtfeld', required) },
}
const v$ = useVuelidate(rules, { selectedCategory, firstName, lastName, phone, email, plainText, reportingChannel })

const beforeSubmit = async (mutate: Function) => {
    if (!selectedPortal.value) return

    let assignedUsersList: Record<string, string> = JSON.parse(selectedPortal.value.access_key_list)

    isProcessing.value = true
    // Generate passphrase to encrypt private key
    passphrase.value = PasswordService.generatePassphrase(6)
    // Generate pub/priv keypair
    const whistleblower = await generateKeys(passphrase.value.join('-'))
    submitterPrivateKey.value = whistleblower.privateKey
    submitterPublicKey.value = whistleblower.publicKey
    submitterIv.value = whistleblower.iv
    submitterSalt.value = whistleblower.salt
    progressValue.value = 10

    const user: User = {
        privateKeyJwk: whistleblower.privateKeyJwk,
        id: 'wb',
    }

    // Encrypt the content
    const contactData = JSON.stringify({
        firstName: firstName.value,
        lastName: lastName.value,
        phone: phone.value,
    })
    const encryptedMessage = await encryptContentWithoutReportKey(plainText.value, {...assignedUsersList, wb: whistleblower.publicKey })
    const encryptedContactInformation = await encryptContent(contactData, user, encryptedMessage.shareKeys)
    content.value = encryptedMessage.content
    contentIv.value = encryptedMessage.iv

    contactInformation.value = anonymous.value ? null : encryptedContactInformation.content
    reportIv.value = encryptedContactInformation.iv

    const encryptedFiles: Array<EncryptedFile> = []

    if (mergedFiles.value.length > 0) {
        isEncryptFiles.value = true
        const fileEncryptionProgressSteps = Math.floor(40 / files.value.length)
        for await (const item of mergedFiles.value) {
            const contentBuffer: ArrayBuffer = await readFileAsync(item);
            const content = crypto.arrayBufferToBase64(contentBuffer)

            if (content) {
                const encryptedFile = await encryptContent(content, user, encryptedMessage.shareKeys)
                const fileContent = await new Response(encryptedFile.content).blob()
                const file = new File([ fileContent ], item.name, { type: 'application/octet-stream' })

                encryptedFiles.push({
                    mime_type: item.type,
                    iv: encryptedFile.iv,
                    file,
                })
            }
            progressValue.value = progressValue.value + fileEncryptionProgressSteps
        }
    }

    shareKeys.value = JSON.stringify(encryptedMessage.shareKeys)
    await nextTick()

    const variables = {
        submitType: anonymous.value ? 'ANONYMOUS' : 'CONFIDENTIAL',
        portalId: selectedPortal.value ? selectedPortal.value.id : undefined,
        internal: true,
        categoryId: selectedCategory.value ? selectedCategory.value : null,
        email: !anonymous.value ? email.value : undefined,
        content: content.value,
        contentIv: contentIv.value,
        reportingChannel: reportingChannel.value,
        contactInformation: contactInformation.value,
        reportIv: reportIv.value,
        submitterIv: submitterIv.value,
        submitterSalt: submitterSalt.value,
        submitterPublicKey: submitterPublicKey.value,
        submitterPrivateKey: submitterPrivateKey.value,
        shareKeys: shareKeys.value,
        attachments: encryptedFiles.length > 0 ? encryptedFiles : undefined
    }

    isEncryptFiles.value = false
    progressValue.value = 50
    const interval = setInterval(() => {
        if (progressValue.value < 95) {
            progressValue.value = progressValue.value + 1
        }
    }, 150)

    await mutate({
        variables,
        context: {
          hasUpload: true
        }
    })

    clearInterval(interval)
    isProcessing.value = false
    progressValue.value = 100
}

watch(anonymous, async (val) => {
    if (val === true) {
        firstName.value = null
        lastName.value = null
        phone.value = null
        email.value = null
    }
})

const updateCache = (cache, { data: { createReport } }) => {
    const query = {
        query: REPORT_QUERY,
        variables: buildQueryVariables()
    }
    let data = cache.readQuery(query)
    data = {
        ...data,
        reports: {
            data: {
                ...createReport
            }
        }
    }
    cache.writeQuery({ ...query, data })
}

const onDone = ({ data: { createReport } }) => {
    if (createReport) {
        success.value = true
        identifier.value = createReport.identifier
        portalUrl.value = createReport.portal.url
    }
}

const onFilesChanged = (newFiles) => {
    files.value = newFiles
}

const replaceStr = (val, search, replace) => {
    const mapping = {
        firstName: 'Vorname',
        lastname: 'Nachname',
        email: 'E-Mail',
        phone: 'Telefon',
        plainText: 'Description',
    }
    return val.replace(search, mapping[replace] ? mapping[replace] : replace)
}
</script>

<template>
    <ReportConfirmation v-if="success" :identifier="identifier" :passphrase="passphrase" :confirm-message="confirmMessage" :portal-url="portalUrl">
        <template #successMessage>{{ 'Die Meldung wurde erstellt.' }}</template>
        <template #description>
            <p>Sie können dem Hinweisgeber das Verfahrenspasswort zur Verfügung stellen. Mit diesem Passwort kann der Hinweisgeber die Meldung nachträglich lesen und auf Rückfragen antworten.</p>
            <h3>Verfahrens-Passwort:</h3>
        </template>
    </ReportConfirmation>
    <ApolloMutation
        v-else
        :mutation="createReportMutation"
        :update="updateCache"
        :refetchQueries="() => [
            {
                query: gql`
                    query reportStatistics($id: ID) {
                        reportStatistics(portal_id: $id) {
                            average_processing_time
                            submissions_per_month {
                                month
                                year
                                count
                            }
                            open_reports_count_by_category {
                                category
                                count
                            }
                            reports_count_by_status {
                                new
                                received
                                under_review
                                awaiting_feedback
                                completed
                                suspended
                                marked_for_deletion
                            }
                        }
                    }
                `
            }
        ]"
        @done="onDone">
        <template v-slot="{ mutate, loading, error }">
            <ValidationMessage v-if="error" :response="error" />
            <form v-if="!isProcessing" class="flex-none w-full py-8 pt-4" @submit.prevent="beforeSubmit(mutate)">
                <div class="mb-10">
                    <h3 class="text-xl text-gray-600 font-bold mb-4">Zuordnung zu einem Meldeportal</h3>
                    <ApolloQuery :query="gql => gql`query portals { portals(getAll: false) { id, name, access_key_list }}`">
                        <template v-slot="{ result: { error, data }, isLoading }">
                            <Message v-if="error" severity="error">{{ error.message }}</Message>
                            <Dropdown :loading="isLoading > 0" v-model="selectedPortal" :options="data?.portals" optionLabel="name" placeholder="Meldeportal auswählen" class="w-full" />
                        </template>
                    </ApolloQuery>
                </div>
                <div class="mb-10">
                    <h3 class="text-xl text-gray-600 font-bold mb-4">Meldekanal</h3>
                    <Dropdown v-model="reportingChannel" :options="reportingChannelOptions" optionLabel="label" optionValue="value" placeholder="Meldekanal auswählen" class="w-full" />
                </div>
                <template v-if="selectedPortal">
                    <div class="mb-10">
                        <h3 class="text-xl text-gray-600 font-bold mb-4">Kategorie</h3>
                        <ApolloQuery :query="gql => gql`query categories { categories(first: 999) { data { id, name }}}`">
                            <template v-slot="{ result: { error, data }, isLoading }">
                                <Message v-if="error" severity="error">{{ error.message }}</Message>
                                <Dropdown :loading="isLoading > 0"  v-model="selectedCategory" :options="data?.categories.data" optionLabel="name" option-value="id" placeholder="Kategorie auswählen" class="w-full" />
                            </template>
                        </ApolloQuery>
                    </div>
                    <InputGroup class="mb-10">
                        <span class="flex items-center">
                            <InputSwitch v-model="anonymous" id="type" class="mr-2" />
                            <label for="type" class="text-gray-600">Meldung soll anonym abgeben werden.</label>
                        </span>
                    </InputGroup>
                    <div v-if="!anonymous" class="bg-gray-100 p-4 mb-8 pt-8">
                        <div class="flex mb-4">
                            <InputGroup class="md:w-1/2 md:mr-2">
                                <span class="p-float-label">
                                    <InputText v-model="firstName" :class="{ 'p-invalid': v$.firstName.$error }" id="first_name" type="text" @blur="v$.firstName.$touch()" />
                                    <label for="first_name" :class="{ 'p-error':v$.firstName.$invalid }">Vorname *</label>
                                </span>
                                <div v-for="error of v$.firstName.$errors" :key="error.$uid">
                                    <small class="p-error">{{ replaceStr(error.$message, 'Value', firstName) }}</small>
                                </div>
                            </InputGroup>

                            <InputGroup class="md:w-1/2 md:ml-2">
                                <span class="p-float-label">
                                    <InputText v-model="lastName" :class="{ 'p-invalid': v$.lastName.$error }" id="last_name" type="text" @blur="v$.lastName.$touch()" />
                                    <label for="last_name" :class="{ 'p-error':v$.lastName.$invalid }">Nachname *</label>
                                </span>
                                <div v-for="error of v$.lastName.$errors" :key="error.$uid">
                                    <small class="p-error">{{ replaceStr(error.$message, 'Value', lastName) }}</small>
                                </div>
                            </InputGroup>
                        </div>
                        <div class="flex mb-4">
                            <InputGroup class="md:w-1/2 md:mr-2">
                                <span class="p-float-label">
                                    <InputText v-model="email" id="email" type="email" :class="{ 'p-invalid': v$.email.$error }" @blur="v$.email.$touch()" />
                                    <label for="email" :class="{ 'p-error':v$.email.$invalid }">E-Mail-Adresse *</label>
                                </span>
                                <div v-for="error of v$.email.$errors" :key="error.$uid">
                                    <small class="p-error">{{ replaceStr(error.$message, 'Value', 'email') }}</small>
                                </div>
                            </InputGroup>
                            <InputGroup class="md:w-1/2 md:ml-2">
                                <span class="p-float-label">
                                    <InputText v-model="phone" :class="{ 'p-invalid': v$.phone.$error }" id="phone" @blur="v$.phone.$touch()" />
                                    <label for="phone" :class="{ 'p-error':v$.phone.$invalid }">Telefon</label>
                                </span>
                                <div v-for="error of v$.phone.$errors" :key="error.$uid">
                                    <small class="p-error">{{ replaceStr(error.$message, 'Value', phone) }}</small>
                                </div>
                            </InputGroup>
                        </div>
                        <div class="text-right text-sm text-gray-400">
                            * Pflichtangaben
                        </div>
                    </div>
                    <div class="prose-sm md:prose mb-8">
                        <h3>Beschreibung</h3>
                        <p>Es ist hilfreich, wenn Sie bei Ihrer Meldung nachfolgende W-Fragen berücksichtigen:</p>
                    <ul class="list-disc">
                        <li>Wer hat den Verstoß begangen?</li>
                        <li>Was ist passiert?</li>
                        <li>Wo ist es passiert?</li>
                        <li>Wann ist es passiert?</li>
                        <li>Wie lässt sich der Verstoß belegen?</li>
                    </ul>
                    </div>
                    <InputGroup class="mb-10">
                        <span class="p-float-label">
                            <InputTextarea v-model="plainText" :class="{ 'p-invalid': v$.plainText.$error }" :autoResize="true" rows="20" class="w-full" id="report" @blur="v$.plainText.$touch()">Beschreibung</InputTextarea>
                            <label for="report" :class="{ 'p-error':v$.plainText.$invalid }">Beschreibung</label>
                        </span>
                        <div v-for="error of v$.plainText.$errors" :key="error.$uid">
                            <small class="p-error">{{ replaceStr(error.$message, 'Value', 'plainText') }}</small>
                        </div>
                    </InputGroup>

                    <FileUpload @changed="onFilesChanged" />
                    <div class="prose-sm md:prose my-6">
                        <p><strong>Hinweis zum Versand von Anhängen:</strong> Dateien können versteckte personenbezogene Daten enthalten, die Ihre Anonymität gefährden. Entfernen Sie diese Daten vor dem Versenden.</p>
                    </div>

                    <div v-if="v$.$errors && v$.$errors.length > 0" class="my-6">
                        <ul class="list-disc pl-6 text-red-600">
                            <li v-for="error in v$.$errors">
                                {{ replaceStr(error.$message, 'Value', error.$propertyPath) }}
                            </li>
                        </ul>
                    </div>
                    <Button :loading="loading" :disabled="loading || v$.$invalid" type="submit" label="Meldung einreichen" />
                </template>
            </form>
            <div v-else>
                <h3 class="text-lg mb-4 font-medium">Ihe Meldung wird verschlüsselt und übertragen. Dies kann eine Weile in Anspruch nehmen.</h3>
                <ProgressBar  :value="progressValue" />
            </div>
        </template>
    </ApolloMutation>
</template>
