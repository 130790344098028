<template>
    <div class="bg-white">
        <h3 class="text-center text-sm text-slate-400 font-medium mb-3">{{ props.title }}</h3>
        <Chart type="bar" :data="chartData" :options="lightOptions" />
    </div>
</template>

<script setup lang="ts">
import Chart from 'primevue/chart'
import { ref } from 'vue'

const props = defineProps({
    title: {
        type: String,
        default: 'Einreichungen pro Monat'
    },
    chartColors: {
        type: Array,
        default: () => ['#004c6d','#2b5d7d','#466f8c','#5e819d','#7694ad','#8ea8be','#a6bbce','#becfe0','#d6e4f1']
    },
    chartColorsHover: {
        type: Array,
        default: () => ['#004c6d','#2b5d7d','#466f8c','#5e819d','#7694ad','#8ea8be','#a6bbce','#becfe0','#d6e4f1']
    },
    data: {
        type: Array,
        default: () => []
    },
    labels: {
        type: Array,
        default: () => []
    },
    label: {
        type: String,
        default: 'Neue Fälle'
    }

})

const chartData = ref({
    labels: props.labels,
    datasets: [
        {
            label: props.label,
            data: props.data,
            backgroundColor: props.chartColors,
            hoverBackgroundColor: props.chartColorsHover
        }
    ]
});

const lightOptions = ref({
    plugins: {
        legend: {
            position: 'bottom',
            labels: {
                color: '#495057'
            }
        }
    }
});

</script>
