<script setup lang="ts">
import type { ComputedRef } from 'vue'
import { ref, computed, inject } from 'vue'
import Button from "primevue/button"

import PortalIndex from '@/containers/Portal/PortalIndex.vue'
import RecryptDialog from '@/containers/Recrypt/RecryptDialog.vue'
import AppFrame from '@/components/AppFrame.vue'
import AppConfig from '../config'

import type { Healthcheck } from '@/types'
const getHealthcheck: Function | undefined = inject('getHealthcheck')
const healthcheck: ComputedRef<Healthcheck | null> = computed(() => getHealthcheck ? getHealthcheck() : null)

const currentUserRole = ref(AppConfig.currentUserRole)
const hasPermission = computed(() => currentUserRole.value === 'owner')

const showRecryptModal = ref(false)
</script>

<template>
    <AppFrame title="Meldeportale">
        <template #actions>
            <Button v-if="hasPermission" label="Neu verschlüsseln" class="p-button-outlined p-button-sm !mr-2" @click="showRecryptModal = true" />
            <Button v-if="hasPermission" label="Meldeportal erstellen" class="p-button-sm" @click="$router.push('/portals/new')" />
        </template>
        <RecryptDialog v-if="showRecryptModal" v-model="showRecryptModal" />
        <PortalIndex :portal-limit="healthcheck?.portal_count_limit || 0" />
    </AppFrame>
</template>
