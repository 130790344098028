<script setup lang="ts">
import type { ComputedRef } from 'vue'
import type { Healthcheck } from '@/types'
import { computed, ref, inject } from 'vue'
import Button from "primevue/button"

import InviteContainer from '@/containers/User/Invite.vue'
import UsersIndex from '@/containers/User/UsersIndex.vue'
import AppFrame from '@/components/AppFrame.vue'

const showInviteModal = ref(false)
const getHealthcheck: Function | undefined = inject('getHealthcheck')
const healthcheck: ComputedRef<Healthcheck | null> = computed(() => getHealthcheck ? getHealthcheck() : null)
const maxUserLimit = computed(() => healthcheck.value?.user_count_limit || 3)
const currentUserLimit = computed(() => healthcheck.value?.user_and_invite_count || 1)
const maxReached = computed(() => currentUserLimit.value >= maxUserLimit.value)
</script>

<template>
    <AppFrame title="Benutzer">
        <template #actions>
            <Button label="Benutzer einladen" class="p-button-sm" @click="showInviteModal = true" />
        </template>
        <InviteContainer v-model="showInviteModal" />
        <UsersIndex />
    </AppFrame>
</template>
