<script setup lang="ts">
import { ref, defineProps, defineEmits, computed, watch } from 'vue'
import type { Ref, ComputedRef } from 'vue'
import gql from 'graphql-tag'
import { useVuelidate } from "@vuelidate/core"
import { required, maxLength } from "@/utils/validators";
import Message from 'primevue/message'
import Dialog from 'primevue/dialog'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'

const rules = {
    categoryName: { required, maxLength: maxLength(255) },
}

const mutation = gql`
    mutation createCategory ($name: String) {
        createCategory (input: { name: $name }) {
            id, name, is_visible, is_custom, created_at
        }
    }
`

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const success: Ref<boolean> = ref(false)
const categoryName: Ref<string | null> = ref(null)
const visible = ref(props.modelValue)

watch(() => props.modelValue, (val) => visible.value = val)
watch(visible, (val) => emit('update:modelValue', val))

const v$ = useVuelidate(rules, { categoryName })

const onDone = ({ data: { createCategory } }) => {
    if (createCategory) {
        categoryName.value = null
        success.value = true
    }
}

const updateCache = (store, { data: { createCategory } }) => {
    const query = {
        query: gql`
            query categories {
                categories(first: 999, with_hidden: true) {
                    data {
                        id
                        name
                        is_visible
                        is_custom
                        created_at
                    }
                }
            }
        `,
    }
    const data = store.readQuery(query)

    store.writeQuery({
        ...query,
        data: { categories: { data: [createCategory, ...data.categories.data] } }
    })

    const categorySelectQuery = { query: gql`query categories { categories(first: 999) { data { id, name }}}` }
    const categorySelectData = store.readQuery(categorySelectQuery)
    store.writeQuery({
        ...categorySelectQuery,
        data: { categories: { data: [createCategory, ...categorySelectData.categories.data] } }
    })
}
</script>

<template>
    <Dialog
        v-model:visible="visible"
        :header="`Neue Kategorie erstellen`"
        position="top"
        modal
        :dismissable-mask="true"
        :close-on-escape="true"
        :draggable="false"
        :style="{ width: '512px' }">
        <ApolloMutation
            :mutation="mutation"
            :variables="{ name: categoryName }"
            :update="updateCache"
            @done="onDone"
        >
            <template v-slot="{ mutate, loading, error }">
                <Message v-if="error" severity="error">{{ error.message }}</Message>
                <Message v-if="success" severity="success">Kategorie erfolgreich angelegt.</Message>
                <small>Um die Datenintegrität zu gewährleisten, kann der Kategoriename später nicht mehr geändert werden.</small>
                <form method="post" autocomplete="on" @submit.prevent="() => !v$.$invalid && mutate()">
                    <div class="p-inputgroup my-4">
                        <InputText v-model="categoryName"  placeholder="Name" />
                    </div>
                    <Button type="submit" :loading="loading" :disabled="v$.$invalid">Erstellen</Button>
                </form>
            </template>
        </ApolloMutation>
    </Dialog>
</template>
