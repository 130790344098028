export interface KeyPairJwt {
    publicKeyJwk: JsonWebKey
    privateKeyJwk: JsonWebKey
}

export interface EcryptedData {
    iv: Uint8Array
    encrypted: ArrayBuffer
}

export default class CryptoService {
    /**
     * Generates a public / private keypair for the user
     * @returns {CryptoKey} JWK Keypair
     */
    async generateUserKeyPair (): Promise<KeyPairJwt> {
        const keyPair = await window.crypto.subtle.generateKey(
            {
                name: 'RSA-OAEP',
                modulusLength: 4096,
                publicExponent: new Uint8Array([1, 0, 1]),
                hash: 'SHA-256'
            },
            true,
            ['encrypt', 'decrypt']
        )

        const publicKeyJwk = await window.crypto.subtle.exportKey(
            'jwk',
            keyPair.publicKey
        )

        const privateKeyJwk = await window.crypto.subtle.exportKey(
            'jwk',
            keyPair.privateKey
        )

        return {
            publicKeyJwk,
            privateKeyJwk
        }
    }

    /**
     * Generates a share-key based on users public key and the report key
     *
     * @param {CryptoKey} publicKey
     * @param {String} reportKey
     * @returns
     */
    async generateShareKey (
        publicKey: CryptoKey,
        reportKey: BufferSource
    ): Promise<ArrayBuffer> {
        return await window.crypto.subtle.encrypt(
            { name: 'RSA-OAEP' },
            publicKey,
            reportKey
        )
    }

    /**
     * Generates a secure key with PBKDF2 off a low entropy input..
     *
     * @param password string
     * @returns {CryptoKey} AESKey
     */
    async getKeyFromPassword (password: string): Promise<CryptoKey> {
        const encoder = new TextEncoder()
        return await window.crypto.subtle.importKey(
            'raw',
            encoder.encode(password),
            'PBKDF2',
            false,
            ['deriveBits', 'deriveKey']
        )
    }

    async getAESKeyFromPBKDF (
        key: CryptoKey,
        salt: BufferSource
    ): Promise<CryptoKey> {
        return await window.crypto.subtle.deriveKey(
            {
                name: 'PBKDF2',
                salt,
                iterations: 100000,
                hash: 'SHA-256'
            },
            key,
            { name: 'AES-GCM', length: 256 },
            true,
            ['encrypt', 'decrypt']
        )
    }

    async encryptPrivateKey (
        privateKey: JsonWebKey,
        aesKey: CryptoKey,
        iv: BufferSource
    ) {
        const encodedPrivateKey = new TextEncoder().encode(
            JSON.stringify(privateKey)
        )
        return await window.crypto.subtle.encrypt(
            { name: 'AES-GCM', iv: iv },
            aesKey,
            encodedPrivateKey
        )
    }

    async decryptPrivateKey (
        encryptedPrivateKey: BufferSource,
        password: string,
        salt: BufferSource,
        iv: BufferSource
    ): Promise<string> {
        const PBKDFSecret = await this.getKeyFromPassword(password)
        const aesKey = await this.getAESKeyFromPBKDF(PBKDFSecret, salt)
        let decryptedKey

        try {
            decryptedKey = await window.crypto.subtle.decrypt(
                { name: 'AES-GCM', iv: iv },
                aesKey,
                encryptedPrivateKey
            )
        } catch (err) {
            throw new Error('Password wrong')
        }

        return new TextDecoder().decode(decryptedKey)
    }

    /**
     * Genreates the report key
     */
    async generateReportKey (): Promise<CryptoKey> {
        return await window.crypto.subtle.generateKey(
            {
                name: 'AES-GCM',
                length: 256
            },
            true,
            ['encrypt', 'decrypt']
        )
    }

    async decryptReportKey (
        privateKey: CryptoKey,
        shareKey: BufferSource
    ): Promise<ArrayBuffer> {
        return await window.crypto.subtle.decrypt(
            {
                name: 'RSA-OAEP'
            },
            privateKey,
            shareKey
        )
    }

    async encrypt (data: string, aesKey: CryptoKey): Promise<EcryptedData> {
        const encodedData = new TextEncoder().encode(data)
        const iv = window.crypto.getRandomValues(new Uint8Array(12))
        // Encrypt with AES key
        const encryptedData = await window.crypto.subtle.encrypt(
            { name: 'AES-GCM', iv: iv },
            aesKey,
            encodedData
        )

        return {
            iv: iv,
            encrypted: encryptedData
        }
    }

    async decrypt (data: string, key: CryptoKey, iv: string): Promise<string> {
        const ivBytes = this.base64ToArrayBuffer(iv)
        const dataBytes = this.base64ToArrayBuffer(data)

        const decryptedData = await window.crypto.subtle.decrypt(
            { name: 'AES-GCM', iv: ivBytes },
            key,
            dataBytes
        )
        return new TextDecoder().decode(decryptedData)
    }

    arrayBufferToBase64 (buffer: ArrayBuffer): string {
        let binary = ''
        const bytes = new Uint8Array(buffer)
        const byteLength = bytes.byteLength

        for (var i = 0; i < byteLength; i++) {
            binary += String.fromCharCode(bytes[i])
        }

        return btoa(binary)
    }

    base64ToArrayBuffer (base64: string): ArrayBuffer {
        return Uint8Array.from(atob(base64), c => c.charCodeAt(0))
    }

    async jwkToCryptoKeyRSAPublic (key: JsonWebKey): Promise<CryptoKey> {
        return await window.crypto.subtle.importKey(
            'jwk',
            key,
            {
                name: 'RSA-OAEP',
                hash: 'SHA-256'
            },
            true,
            ['encrypt']
        )
    }

    async jwkToCryptoKeyRSAPrivate (key: JsonWebKey): Promise<CryptoKey> {
        return await window.crypto.subtle.importKey(
            'jwk',
            key,
            {
                name: 'RSA-OAEP',
                hash: 'SHA-256'
            },
            true,
            ['decrypt']
        )
    }

    async jwkToCryptoKeyAES (key: JsonWebKey): Promise<CryptoKey> {
        const { key_ops } = key
        if (key_ops && key_ops.length < 2) {
            throw new Error('Faulty AES key, as it has only 1 key usage (needs 2).')
        }

        return await window.crypto.subtle.importKey(
            'jwk',
            key,
            {
                name: 'AES-GCM',
                length: 256
            },
            true,
            ['encrypt', 'decrypt']
        )
    }

    async cryptoKeyToJwk (key: CryptoKey): Promise<JsonWebKey> {
        return await window.crypto.subtle.exportKey('jwk', key)
    }

    jwkToBuffer (key: JsonWebKey): ArrayBuffer {
        return new TextEncoder().encode(JSON.stringify(key))
    }

    jwkToBase64 (key: JsonWebKey): string {
        return btoa(JSON.stringify(key))
    }

    bufferToJwk (key: ArrayBuffer): JsonWebKey {
        return JSON.parse(new TextDecoder().decode(key))
    }

    base64ToJwk (key: string): JsonWebKey {
        return JSON.parse(atob(key))
    }
}
