<script setup lang="ts">
import type { Ref } from 'vue'
import gql from 'graphql-tag'
import { ref, defineProps, onUpdated } from 'vue'
import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import Skeleton from 'primevue/skeleton'
import AppFrame from '@/components/AppFrame.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'
import InputGroup from '@/components/Form/InputGroup.vue'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'


const props = defineProps({
    userResult: {
        type: Object,
        required: true,
        default: () => ({})
    }
})
const toast = useToast();
const loading = ref(true)
const email = ref('')
const oldEmailOtp = ref('')
const newEmailOtp = ref('')
const errorMessages: Ref<any> = ref(null)
const currentPage:Ref<number> = ref(1)

const generateOTPsMutation = gql`
    mutation generateEmailChangeOtp($newEmail: String, $for: String) {
        generateEmailChangeOtp(input: { new_email: $newEmail, for: $for })
    }`

const updateEmailMutation = gql`
    mutation updateEmail($newEmail: String, $newEmailOtp: String, $oldEmailOtp: String) {
        updateEmail(input: { new_email: $newEmail, new_email_otp: $newEmailOtp, old_email_otp: $oldEmailOtp }) {
            email
        }
    }`

onUpdated(() => {
    if (props.userResult) {
        email.value = props.userResult?.me?.email

        loading.value = false
    }
})

const showSuccess = () => {
    toast.add({ severity: 'success', summary: 'Erfolgreich gespeichert', detail: 'Ihre E-Mail-Adresse wurde erfolgreich aktualisiert.', life: 3000 });
}

const onGenerateOTPsDone = () => {
    currentPage.value = currentPage.value + 1
}

const onUpdateEmailDone = () => {
    showSuccess()
    currentPage.value = 1
}

const onError = (error) => {
    errorMessages.value = error
}


</script>

<template>
    <Toast />
    <Skeleton v-if="loading" width="100%" height="23rem"/>
    <AppFrame v-else :full-width="true">
        <h2 class="text-2xl font-bold mb-4">E-Mail-Adresse ändern</h2>
        <ValidationMessage v-if="errorMessages" :response="errorMessages" />
        <div class="sm:flex">
            <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0">
                <div v-if="currentPage === 1">
                    <ApolloMutation
                        :mutation="generateOTPsMutation"
                        :variables="{ newEmail: email, for: 'old' }"
                        @done="onGenerateOTPsDone"
                        @error="onError"
                    >
                        <template #default="{ mutate, loading }">
                            <InputGroup label="E-Mail-Adresse">
                                <InputText v-model="email" placeholder="E-Mail-Adresse" name="email"/>
                            </InputGroup>
                            <p class="mb-4">Geben Sie Ihre neue E-Mail-Adresse ein und klicken Sie auf "Weiter". Sie erhalten nacheinander zwei E-Mails mit jeweils einem Einmalpasswort, die Sie in den nächsten Schritten eingeben müssen.</p>
                            <Button
                                label="Weiter"
                                :loading="loading"
                                :disabled="loading"
                                @click="mutate"
                            />
                        </template>
                    </ApolloMutation>
                </div>
                <div v-if="currentPage === 2">
                    <ApolloMutation
                        :mutation="generateOTPsMutation"
                        :variables="{ newEmail: email, for: 'new' }"
                        @done="onGenerateOTPsDone"
                        @error="onError"
                    >
                        <template #default="{ mutate, loading }">
                            <InputGroup label="E-Mail-Adresse">
                                <InputText v-model="email" placeholder="E-Mail-Adresse" name="email" disabled/>
                            </InputGroup>
                            <InputGroup label="Einmalpasswort alte E-Mail-Adresse">
                                <InputText v-model="oldEmailOtp" placeholder="Einmalpasswort alte E-Mail-Adresse" name="oldEmailOtp"/>
                            </InputGroup>
                            <p class="mb-4">Wir haben Ihnen einen Einmalpasswort an <strong>{{ props.userResult?.me?.email }}</strong> versendet, bitte geben Sie ihn hier ein und klicken Sie auf "Weiter".</p>
                            <Button
                                label="Weiter"
                                :loading="loading"
                                :disabled="loading"
                                @click="mutate"
                            />
                        </template>
                    </ApolloMutation>
                </div>
                <div v-if="currentPage === 3">
                        <ApolloMutation
                            :mutation="updateEmailMutation"
                            :variables="{ newEmail: email, newEmailOtp: newEmailOtp, oldEmailOtp: oldEmailOtp }"
                            @done="onUpdateEmailDone"
                            @error="onError"
                        >
                            <template #default="{ mutate, loading }">
                                <InputGroup label="E-Mail-Adresse">
                                    <InputText v-model="email" placeholder="E-Mail-Adresse" name="email" disabled/>
                                </InputGroup>
                                <InputGroup label="Einmalpasswort alte E-Mail-Adresse">
                                    <InputText v-model="oldEmailOtp"  placeholder="Einmalpasswort alte E-Mail-Adresse" name="oldEmailOtp"/>
                                </InputGroup>
                                <InputGroup label="Einmalpasswort neue E-Mail-Adresse">
                                    <InputText v-model="newEmailOtp"  placeholder="Einmalpasswort neue E-Mail-Adresse" name="newEmailOtp"/>
                                </InputGroup>
                                <p class="mb-4">Wir haben Ihnen einen Einmalpasswort an <strong>{{ email }}</strong> versendet, bitte geben Sie ihn hier ein und klicken Sie auf "E-Mail-Adresse".</p>
                                <Button
                                    label="E-Mail-Adresse ändern"
                                    :loading="loading"
                                    :disabled="loading"
                                    @click="mutate"
                                />
                            </template>
                        </ApolloMutation>
                    </div>

            </div>
            <div class="sm:w-1/2 sm:pl-4 prose prose-sm">

            </div>
        </div>

    </AppFrame>
</template>
