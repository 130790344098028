<script setup lang="ts">
import type { Ref } from 'vue'
import type { UnuploadedFile } from '@/containers/File/file'

import { ref, defineEmits } from 'vue'

import PrimeFileUpload from 'primevue/fileupload'
import Button from 'primevue/button'

const emit = defineEmits(['changed'])

const files: Ref<Array<UnuploadedFile>> = ref([])
const acceptedTypes: Ref<string> = ref('image/*,audio/*,video/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain')

const onSelect = (e) => {
    const newFiles: Array<UnuploadedFile> = []
    e.files.forEach((file) => {
        newFiles.push(file)
    })

    files.value = newFiles
    emit('changed', files.value)
}

const onRemove = (file, index, removeFn) => {
    files.value.splice(index, 1);
    removeFn(index)
    emit('changed', files.value)
}

const formatSize = (bytes) => {
    if (bytes === 0) {
        return '0 B';
    }

    let k = 1000,
        dm = 3,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
</script>

<template>
    <div class="my-8">
        <div class="prose-sm md:prose mb-4">
            <h3>{{ $t("components.fileUpload.header") }}</h3>
            <p>{{ $t("components.fileUpload.info") }}</p>
        </div>
        <PrimeFileUpload
            name="files[]"
            url="./upload.php"
            :multiple="true"
            :showUploadButton="false"
            :showCancelButton="false"
            :accept="acceptedTypes"
            :max-file-size="20000000"
            :fileLimit="10"
            :previewWidth="100"
            :choose-label="$t('components.fileUpload.chooseLabel')"
            @select="onSelect"
            @remove="onSelect">
            <template #content="{ files, removeFileCallback }">
                <div v-if="files.length > 0">
                    <div
                        v-for="(file, index) of files"
                        :key="file.name + file.type + file.size"
                        class="mb-4 flex flex-column items-center flex-wrap">
                        <span class="font-semibold">{{ file.name }}</span>
                        <div class="ml-4 mr-4 text-slate-500">{{ formatSize(file.size) }}</div>
                        <div class="ml-auto">
                            <Button icon="pi pi-times" @click="onRemove(file, index, removeFileCallback)" class="p-button-rounded p-button-text p-button-danger" />
                        </div>
                    </div>
                </div>
            </template>
            <template #empty>
                <p>{{ $t("components.fileUpload.dropInfo") }}</p>
            </template>
        </PrimeFileUpload>
    </div>
</template>
