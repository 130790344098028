<script setup lang="ts">
import { ref } from 'vue'
import { email as emailValidator, required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Message from 'primevue/message'

import GuestLayout from '@/components/Layouts/Guest.vue'
import InputGroup from '@/components/Form/InputGroup.vue'

const rules = {
    email: { required, emailValidator },
}
const email = ref(null)
const success = ref(false)
const v$ = useVuelidate(rules, { email })

const onDone = ({ data: { forgetPassword } }) => {
    if (forgetPassword.status === 'success') {
        success.value = true
    }
}
</script>

<template>
    <GuestLayout>
        <ApolloMutation
            :mutation="gql => gql`
                mutation forgetPassword ($email: String!) {
                    forgetPassword (input: { email: $email }) {
                        status
                        message
                    }
                }
            `"
            :variables="{ email }"
            @done="onDone"
        >
            <template v-slot="{ mutate, loading, error }">
                <Message v-if="error" severity="error">{{ error.message }}</Message>
                <Message v-if="success" severity="success" :closable="false">{{
                    'Sie sollten in Kürze eine E-Mail mit einem Link zum Zurücksetzen Ihres Passworts erhalten.'
                }}</Message>
                <form v-if="!success" method="post" autocomplete="on" @submit.prevent="() => !v$.$invalid && mutate()">
                    <h3 class="text-2xl font-bold text-gray-600 text-center mb-4">Passwort zurücksetzen</h3>
                    <p class="text-slate-600 mb-8">Nach dem Absenden wird Ihnen ein Link zum Zurücksetzen Ihres Passworts zugeschickt.</p>
                    <InputGroup>
                        <InputText v-model="email" placeholder="E-Mail" name="email" type="email" autofocus />
                    </InputGroup>

                    <Button :disabled="v$.$invalid" :loading="loading" type="submit" class="block w-full" label="Absenden" />
                </form>
            </template>
        </ApolloMutation>
    </GuestLayout>
</template>
