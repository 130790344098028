<script setup lang="ts">
import { Ref, ref } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import gql from 'graphql-tag'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputSwitch from 'primevue/inputswitch'
import Toast from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import Button from 'primevue/button'

import ValidationMessage from '@/components/ValidationMessage.vue'

const editingRows = ref([])
const toast = useToast()

const deleteCategory: Ref<any> = ref(null)

const onRowEditSave = async ({ newData }) => {
    await mutate({
        id: newData.id,
        is_visible: newData.is_visible
    }, {
        optimisticResponse: {
            updateCategory: {
                __typename: "Category",
                id: newData.id,
                is_visible: newData.is_visible
            }
        }
    })

    toast.add({
        severity: 'success',
        summary: 'Erfolgreich gespeichert',
        group: 'response',
        life: 3000
    })
}

const { mutate, loading: updating } = useMutation(gql`
    mutation updateCategory ($id: ID!, $is_visible: Boolean!) {
        updateCategory (input: { id: $id, is_visible: $is_visible }) { id, is_visible }
    }
`)

const notDeletable = (category) => {
    return category.is_custom === false || category.reports_count > 0
}

const showDeleteConfirmation = (category) => {
    deleteCategory.value = category
    toast.add({
        severity: 'warn',
        summary: `Sind Sie sicher, dass Sie ${deleteCategory.value.name} dauerhaft löschen möchten?`,
        group: 'rm-category',
        closable: false,
    });
}

const onSuccessfullyDeletingCategory = () => {
    toast.removeGroup('rm-category')
    deleteCategory.value = null
}

const onRejectDeletingCategory = () => {
    deleteCategory.value = null
    toast.removeGroup('rm-category')
}
</script>

<template>
    <div class="prose mb-8">
        <p>Bestimmen Sie, welche Verfahrens-Kategorien einem Meldeportal zur Verfügung stehen. Zum Zweck der Revisionssicherheit können Kategorien nicht gelöscht oder umbenannt werden. Sie können aber jederzeit eigene Kategorien ergänzen und die Sichtbarkeit von bestehenden Kategorien bestimmen.</p>
    </div>
    <ApolloQuery
        :query="gql => gql`
            query categories {
                categories(first: 999, with_hidden: true) {
                    data {
                        id
                        name
                        is_visible
                        is_custom
                        reports_count
                        created_at
                    }
                }
            }
        `"
    >
        <template v-slot="{ result: { data }, isLoading }">
            <DataTable
                :value="data?.categories?.data"
                :loading="isLoading > 0 || updating"
                editMode="row"
                dataKey="id"
                v-model:editingRows="editingRows"
                @row-edit-save="onRowEditSave"
                responsiveLayout="scroll">
                <Column field="name" header="Name" style="width:20%">
                    <template #body="{ data }">
                        {{ data.name }}
                        <span v-if="!data.is_custom" class="text-gray-400 ml-1">(system)</span>
                    </template>
                </Column>
                <Column field="reports_count" header="Verknüpfte Meldungen" bodyStyle="text-align:right" headerClass="column-header-right">
                    <template #body="{ data }">
                        {{ data.reports_count }}
                    </template>
                </Column>
                <Column field="is_visible" header="Sichtbar" bodyStyle="text-align:right" headerClass="column-header-right" style="width:10%;">
                    <template #body="{ data }">
                        <InputSwitch :modelValue="data.is_visible" disabled />
                    </template>
                    <template #editor="{ data: modelData, field }">
                        <InputSwitch v-model="modelData[field]" />
                    </template>
                </Column>
                <Column header="Aktion" bodyStyle="text-align:right" headerClass="column-header-right">
                    <template #body="{ data }">
                        <div
                            v-tooltip.bottom="notDeletable(data)
                                ? data.is_custom
                                    ? 'Kategorie kann nicht gelöscht werden, da sie bereits Meldungen enthält'
                                    : 'System-Kategorien können nicht gelöscht werden'
                                : null"
                            >
                            <Button
                                type="button"
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-text"
                                v-tooltip.bottom="'Kategorie löschen'"
                                severity="danger"
                                :disabled="notDeletable(data)"
                                @click="() => { showDeleteConfirmation(data) }" />
                        </div>
                    </template>
                </Column>
                <Column :rowEditor="true" style="width:5%; min-width:3rem" bodyStyle="text-align:right">
                </Column>
            </DataTable>
        </template>
    </ApolloQuery>
    <Toast position="bottom-right" group="response"/>
    <Toast position="bottom-center" group="rm-category">
        <template #message="slotProps">
            <div class="flex flex-col w-full">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{ slotProps.message.summary }}</h4>
                    <p>{{ slotProps.message.detail }}</p>
                </div>
                <ApolloMutation
                    :mutation="gql`
                    mutation DeleteCategory ($id: ID!) {
                        deleteCategory(id: $id) { id }
                    }
                `"
                    :variables="{ id: deleteCategory.id }"
                    :refetchQueries="() => [
                        { query: gql`
                            query categories {
                                categories(first: 999, with_hidden: true) {
                                    data {
                                        id
                                        name
                                        is_visible
                                        is_custom
                                        reports_count
                                        created_at
                                    }
                                }
                            }`
                        },
                        { query: gql`query categories { categories(first: 999) { data { id, name }}}` }
                    ]"
                    @done="async () => {
                        onSuccessfullyDeletingCategory()
                    }"
                >
                    <template v-slot="{ mutate, loading, error }">
                        <ValidationMessage v-if="error" :response="error" />
                        <div class="flex w-full mt-4 space-x-2">
                            <div class="w-1/2">
                                <Button
                                    class="p-button-secondary w-full block"
                                    label="Nein"
                                    @click="onRejectDeletingCategory" />
                            </div>
                            <div class="w-1/2">
                                <Button
                                    class="p-button-danger w-full block"
                                    label="Ja"
                                    :loading="loading"
                                    @click="mutate" />
                            </div>
                        </div>
                    </template>
                </ApolloMutation>
            </div>
        </template>
    </Toast>
</template>
