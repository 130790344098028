import wordlist from './wordlist/wordlist.json'
import { Buffer } from 'buffer'

const MAX_PASSPHRASE_SIZE = 1024

const bytesToPassphrase = (bytes: Buffer): Array<string> => {
    if (bytes.length % 2 === 1) {
        throw new Error('Buffer needs to be even sized')
    }

    const passphraseWords: Array<string> = []

    for (const entry of bytes.entries()) {
        const index = entry[0]
        const byte = entry[1]
        const next = bytes[index + 1]
        if (index % 2 === 0) {
            const wordIndex = byte * 256 + next
            const word: string = wordlist[wordIndex]
            if (!word) {
                throw new Error('Invalid byte index')
            } else {
                passphraseWords.push(word.toLowerCase())
            }
        }
    }
    return passphraseWords
}

const generatePassphrase = (size: number): Array<string> => {
    if (size < 0 || size > MAX_PASSPHRASE_SIZE) {
        throw new Error(`Size must be between 0 and ${MAX_PASSPHRASE_SIZE}.`)
    }

    const bytes_size = Buffer.allocUnsafe(size)
    const bytes = window.crypto.getRandomValues(bytes_size)
    return bytesToPassphrase(bytes)
}


export default {
    bytesToPassphrase,
    generatePassphrase
}
