<script setup lang="ts">
import type { Ref } from 'vue'
import type { User } from '@/containers/Report/reportKeys'
import type { Status } from '@/utils/status'
import { Options as StatusOption } from '@/utils/status'

import { ref, defineEmits, nextTick } from 'vue'
import gql from 'graphql-tag'
import Dialog from 'primevue/dialog'
import Toast from 'primevue/toast'
import StatusDropdown from '@/components/StatusDropdown.vue'
import { useToast } from 'primevue/usetoast'

import MessageForm from '@/containers/Message/MessageForm.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'

const props = defineProps<{
    id?: string,
    reportId: string,
    value: string
    shareKeys: Record<string, string>,
    currentUser: User,
}>()

const selectedStatus: Ref<String> = ref(props.value)
const showApproveDialog: Ref<boolean> = ref(false)
const emit = defineEmits(['done'])
const options: Ref<Array<Status>> = ref(StatusOption)

const toast = useToast()

const onDone = () => {
    toast.add({
        severity:'success',
        summary: 'Erfolgreich gespeichert',
        life: 3000
    });
    emit('done')
}

defineExpose(props)
</script>

<template>
    <ApolloMutation
        :mutation="gql`
            mutation updateReportStatus ($id: ID!, $status: ReportStatus!) {
                updateReportStatus (input: { id: $id, status: $status }) {
                    id
                    status
                }
            }
        `"
        :variables="{
            id: reportId,
            status: selectedStatus
        }"
        @done="onDone"
    >
        <template v-slot="{ mutate, loading, error }">
            <ValidationMessage v-if="error" :response="error" :toast="true" />
            <StatusDropdown
                v-model="selectedStatus"
                :id="id"
                :options="options"
                :loading="loading"
                optionLabel="name"
                optionValue="id"
                placeholder="Status wählen"
                class="w-full"
                @change="async () => {
                    await nextTick()
                    await mutate()
                    showApproveDialog = true
                }"
            />
            <Dialog
                v-model:visible="showApproveDialog"
                :header="`Hinweisgeber Status informieren`"
                position="top"
                modal
                :dismissable-mask="true"
                :close-on-escape="true"
                :draggable="false"
                :style="{ width: '512px' }">
                <MessageForm
                    :allowInternal="true"
                    :header="false"
                    :frameless="true"
                    :report-id="reportId"
                    :share-keys="shareKeys"
                    :current-user="currentUser"
                    @responded="async () => {
                        await nextTick()
                        showApproveDialog = false
                    }" />
            </Dialog>
        </template>
    </ApolloMutation>
    <Toast position="bottom-right" />
</template>
