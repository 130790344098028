<script setup lang="ts">
import { ref, computed } from 'vue'
import Button from "primevue/button"

import AppFrame from '@/components/AppFrame.vue'
import AppConfig from '../config'
import MessageTemplateIndex from '@/containers/MessageTemplate/MessageTemplateIndex.vue';
import CreateMessageTemplateDialog from '@/containers/MessageTemplate/CreateMessageTemplateDialog.vue';


const showCreateModal = ref(false)
</script>

<template>
    <AppFrame title="Antwortvorlagen">
        <template #actions>
            <Button  label="Antwortvorlage erstellen" class="p-button-sm" @click="showCreateModal = true" />
        </template>
        <CreateMessageTemplateDialog v-model="showCreateModal" />
        <MessageTemplateIndex />
    </AppFrame>
</template>
