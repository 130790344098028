<script setup lang="ts">
import type { Ref } from 'vue'
import InputSwitch from 'primevue/inputswitch'
import gql from 'graphql-tag'
import { ref, defineProps, nextTick, onUpdated } from 'vue'
import { useMutation } from '@vue/apollo-composable'
import { useToast } from "primevue/usetoast";
import Toast from 'primevue/toast';
import Skeleton from 'primevue/skeleton'

import AppFrame from '@/components/AppFrame.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'

const props = defineProps({
    userResult: {
        type: Object,
        required: true
    }
})
const toast = useToast();

const sendReportsPending: Ref<boolean> = ref(true)
const sendMemberJoined: Ref<boolean> = ref(true)
const sendMemberInviteExpired: Ref<boolean> = ref(true)
const sendReportCreated: Ref<boolean> = ref(true)
const sendReportUpdated: Ref<boolean> = ref(true)
const errorMessages: Ref<any> = ref(null)
const loading = ref(true)

onUpdated(() => {
    if (props.userResult) {
        sendReportsPending.value = props.userResult.me.settings.send_reports_pending
        sendMemberJoined.value = props.userResult.me.settings.send_member_joined
        sendMemberInviteExpired.value = props.userResult.me.settings.send_member_invite_expired
        sendReportCreated.value = props.userResult.me.settings.send_report_created
        sendReportUpdated.value = props.userResult.me.settings.send_report_updated

        loading.value = false
    }
})

const showSuccess = () => {
    toast.add({ severity: 'success', summary: 'Erfolgreich gespeichert', detail: 'Ihre Präferenzen zum Mailversand wurden erfolgreich aktualisiert.', life: 3000 });
}

const mutation = gql`
    mutation updateSettings ($send_reports_pending: Boolean!, $send_member_joined: Boolean!, $send_member_invite_expired: Boolean!, $send_report_created: Boolean!, $send_report_updated: Boolean!) {
        updateSettings (input: { send_reports_pending: $send_reports_pending, send_member_joined: $send_member_joined, send_member_invite_expired: $send_member_invite_expired, send_report_created: $send_report_created, send_report_updated: $send_report_updated }) {
            id
            settings {
                send_reports_pending
                send_member_joined
                send_member_invite_expired
                send_report_created
                send_report_updated
            }
        }
    }`

const {mutate: updateSettings, onDone, onError} = useMutation(mutation)

onDone(result => {
    showSuccess()
})

onError(error => {
    errorMessages.value = error
})

const updateMailSettings = () => {
    nextTick(() => {
        updateSettings({ send_reports_pending: sendReportsPending.value, send_member_joined: sendMemberJoined.value, send_member_invite_expired: sendMemberInviteExpired.value, send_report_created: sendReportCreated.value, send_report_updated: sendReportUpdated.value })
    })
}

</script>

<template>
    <Toast />
    <Skeleton v-if="loading" width="100%" height="23rem"/>
    <AppFrame v-else :full-width="true">
        <h3 class="text-lg font-bold text-gray-600 mb-4">E-Mail-Benachrichtigungen</h3>
        <ValidationMessage v-if="errorMessages" :response="errorMessages" />
        <div class="sm:flex">
            <div class="sm:w-1/2 sm:pr-6 mb-8 sm:mb-0">
                <h4 class="text-md font-bold mb-2">Meldungen</h4>
                <div class="flex items-center mt-2">
                    <InputSwitch v-model="sendReportsPending" class="shrink-0" @click="() => updateMailSettings()"/>
                    <span class="ml-3">Ich möchte über <strong>ablaufende Rückmeldefristen</strong> benachrichtigt werden</span>
                </div>
                <div class="flex items-center mt-2">
                    <InputSwitch v-model="sendReportCreated" class="shrink-0" @click="() => updateMailSettings()"/>
                    <span class="ml-3">Ich möchte darüber benachrichtigt werden, wenn eine <strong>neue Meldung</strong> in einem Meldeportal eingereicht wird, dem ich zugewiesen bin.</span>
                </div>
                <div class="flex items-center mt-2">
                    <InputSwitch v-model="sendReportUpdated" class="shrink-0" @click="() => updateMailSettings()"/>
                    <span class="ml-3">Ich möchte darüber benachrichtigt werden, wenn ein <strong>Hinweisgeber sich in einem Verfahren zurückmeldet</strong>, falls ich dem Meldeportal zugewiesen bin.</span>
                </div>
                <h4 class="text-md font-bold mt-5 mb-2">Einladungen</h4>
                <div class="flex items-center mt-2">
                    <InputSwitch v-model="sendMemberJoined" class="shrink-0" @click="() => updateMailSettings()"/>
                    <span class="ml-3">Ich möchte darüber benachrichtigt werden, wenn ein Benutzer meine <strong>Einladung angenommen</strong> hat</span>
                </div>
                <div class="flex items-center mt-2">
                    <InputSwitch v-model="sendMemberInviteExpired" class="shrink-0" @click="() => updateMailSettings()"/>
                    <span class="ml-3">Ich möchte darüber benachrichtigt werden, wenn eine <strong>Einladung abgelaufen</strong> ist.</span>
                </div>
            </div>
            <div class="sm:w-1/2 sm:pl-4 prose prose-sm">
                <h3>Ablaufende Rückmeldefristen</h3>
                <p>Wenn Sie diese Option aktivieren, werden Sie per E-Mail benachrichtigt, wenn die Rückmeldefrist einer Meldung</p>
                <ul>
                    <li>in 30 Tagen,</li>
                    <li>in 14 Tagen,</li>
                    <li>oder in 7 Tagen abläuft</li>
                    - oder -
                    <li>Heute abgelaufen und damit überfällig ist.</li>
                </ul>
                <p>Die E-Mail enthält eine Übersicht aller offenen und überfälligen Meldungen. Falls mehrere Meldungen am selben Tag betroffen sind, erhalten Sie die Informationen gebündelt in einer E-Mail.</p>
                <h3>Benachrichtigungen zu neuen Meldungen und Rückmeldungen</h3>
                <p>Hier können Sie Einstellen, ob sie über neue Meldungen bzw. Rückmeldungen durch Hinweisgebern per E-Mail benachrichtigt werden sollen. Bitte beachten Sie, dass sie einem Meldeportal zugewiesen sein müssen, um Benachrichtigungen zu den eingereichten Meldung zu erhalten.</p>
                <h3>Einladungen</h3>
                <p>Wenn Sie diese Optionen aktivieren, werden Sie per E-Mail benachrichtigt, wenn eine Einladung, die Sie verschickt haben, angenommen wurde oder abgelaufen ist.</p>
            </div>
        </div>
    </AppFrame>
</template>
