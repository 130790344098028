<script setup lang="ts">
import type { User } from '@/containers/Report/reportKeys'

import { StorageService, PiniaStorage } from '@/services/storage'
import { useKeyStore } from '@/services/storage/keyStorage'

const keyStore = useKeyStore()

const store = new StorageService<User>({
    storage: new PiniaStorage(keyStore)
})


const onDone = () => {
    store.storage.clear()
    document.location.href = '/login'
    // store.saveState('currentUser', currentUser)
}
</script>

<template>
    <ApolloMutation
        :mutation="gql => gql`
            mutation logout {
                logout {
                    status
                    message
                }
            }
        `"
        @done="onDone"
    >
        <template v-slot="{ mutate, loading, error }">
            <button @click.prevent="mutate" class="w-full text-gray-700 flex items-center p-2 rounded hover:bg-black/5 active:bg-black/10">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
                Logout
            </button>
        </template>
    </ApolloMutation>
</template>
