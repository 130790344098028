<script setup lang="ts">
import { computed, ref, defineProps } from 'vue'
import { email as emailValidator, required } from "@/utils/validators";
import { useVuelidate } from "@vuelidate/core";
import InputText from 'primevue/inputtext'
import Password from 'primevue/password'
import Button from 'primevue/button'
import Message from 'primevue/message'

import GuestLayout from '@/components/Layouts/Guest.vue'
import InputGroup from '@/components/Form/InputGroup.vue'
import AppLink from '@/components/AppLink.vue'

const props = defineProps({
    inviteToken:  String,
    organizationName: String
})

const rules = {
    email: { required, emailValidator },
    password: { required },
}
const email = ref(null)
const password = ref(null)
const success = ref(false)
const tfaCode = ref(null)
const requireTfa = ref(false)
const v$ = useVuelidate(rules, { email, password })

const onDone = ({ data: { login } }) => {
    if (login.status === 'success') {
        success.value = true
        setTimeout(() => document.location.href = login.redirectTo + window.location.search, 200)
    }

    if (login.status === 'tfa_required') {
        requireTfa.value = true
    }
}
</script>

<template>
    <GuestLayout>
        <ApolloMutation
            :mutation="gql => gql`
                mutation login ($email: String!, $password: String!, $tfaCode: String) {
                    login (input: { email: $email, password: $password, remember: false, tfa_code: $tfaCode}) {
                        status
                        message
                        user {
                            id
                            first_name
                            last_name
                        }
                        redirectTo
                    }
                }
            `"
            :variables="{
                email, password, tfaCode
            }"
            @done="onDone"
        >
            <template v-slot="{ mutate, loading, error }">
                <div
                    v-if="props.inviteToken"
                    class="p-4 bg-blue-100 font-bold text-blue-600">
                    Nach erfolgreicher Anmeldung, werden Sie Teil des Beschäftigungsgebers von {{ props.organizationName }}.
                </div>
                <Message v-if="error" severity="error">{{ error.message }}</Message>
                <Message v-if="success" severity="success">{{
                    'Sie werden jeden Moment weitergeleitet...'
                }}</Message>
                <form v-if="!success" method="post" autocomplete="on" @submit.prevent="() => !v$.$invalid && mutate()">
                    <h3 class="text-2xl font-bold text-gray-600 text-center mb-4">Anmelden</h3>
                    <p class="mb-8 text-slate-600 text-center">Sie haben kein Konto? <AppLink href="/create-account">Konto erstellen</AppLink></p>
                    <InputGroup>
                        <InputText v-model="email" placeholder="E-Mail" name="email" type="email" autofocus />
                    </InputGroup>
                    <InputGroup>
                        <Password v-model="password" placeholder="Passwort" name="password" :feedback="false" />
                    </InputGroup>
                    <InputGroup v-if="requireTfa">
                        <InputText v-model="tfaCode" placeholder="Zweiter-Faktor Code" name="tfaCode" :feedback="false" />
                    </InputGroup>

                    <Button :disabled="v$.$invalid" :loading="loading" type="submit" class="block w-full" label="Anmelden" />
                </form>
            </template>
        </ApolloMutation>

        <p class="mt-8 text-gray-800 text-center text-sm"><AppLink href="/reset-password">Passwort vergessen?</AppLink></p>
    </GuestLayout>
</template>
