<script setup lang="ts">
import { Ref } from 'vue'
import type { User } from '@/containers/Report/reportKeys'

import { ref, withDefaults, defineEmits, defineExpose, nextTick, isProxy, toRaw, onMounted } from 'vue'
import type { UnuploadedFile, EncryptedFile } from '@/containers/File/file'
import Textarea from 'primevue/textarea'
import Button from 'primevue/button'
import InputSwitch from 'primevue/inputswitch'
import Dropdown from 'primevue/dropdown';
import ConfirmDialog from 'primevue/confirmdialog'
import Toast from 'primevue/toast'
import { required, minLength } from '@/utils/validators'
import { useVuelidate } from '@vuelidate/core'
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import { readFileAsync } from '@/containers/File/file'
import FileUpload from '@/containers/File/FileUpload.vue'
import ValidationMessage from '@/components/ValidationMessage.vue'
import { encryptContent } from '@/containers/Report/reportKeys'
import gql from 'graphql-tag'
import { useQuery } from '@vue/apollo-composable'
import CryptoService from '@/services/crypto/CryptoService'

interface Props {
    reportId: string,
    shareKeys: Record<string, string>,
    currentUser: User,
    allowInternal?: boolean,
    frameless?: boolean,
    header?: boolean,
    headerTitle?: string,
    setInternal?: boolean
}

const templatesQuery = gql`
    query messageTemplates {
        messageTemplates { id, name, is_visible }
    }
`

const templateQuery = gql`
    query messageTemplate ($id: ID!) {
        messageTemplate (id: $id) {
            id, content
        }
    }
`

const props = withDefaults(defineProps<Props>(), {
    allowInternal: true,
    frameless: false,
    header: true,
    setInternal: false,
    headerTitle: 'Hinweisgeber antworten',
})


const rules = {
    response: { required, maxLength: minLength(3) },
}

const emit = defineEmits(['responded', 'close'])

const response: Ref<string> = ref('')
const encryptedResponse: Ref<string | null> = ref(null)
const newIv: Ref<string | null> = ref(null)
const recrypting: Ref<boolean> = ref(false)
const isInternal: Ref<boolean> = ref(false)
const selectedTemplate: Ref<string | null> = ref(null)
const templateQueryVariables = ref({ id: null })
const queryEnabled = ref(false)
const v$ = useVuelidate(rules, { response })
const confirmMessageReplacement = useConfirm();
const confirmDialogGroup = ref(Math.floor(Math.random() * Date.now()) + '')
const templateLoading = ref(false)
const toast = useToast();
const files: Ref<Array<UnuploadedFile>> = ref([])
const withFiles: Ref<boolean> = ref(false)
const attachments: Ref<Array<EncryptedFile> | undefined> = ref([])

const crypto = new CryptoService()

onMounted(() => {
    if (props.setInternal) {
        isInternal.value = true
    }
})

const beforeSubmit = async (mutate) => {
    recrypting.value = true
    const shareKeys: Record<string, string> = isProxy(props.shareKeys) ? toRaw(props.shareKeys) : props.shareKeys
    const encryptedContent = await encryptContent(
        response.value,
        props.currentUser,
        shareKeys
    )

    encryptedResponse.value = encryptedContent.content
    newIv.value = encryptedContent.iv
    const encryptedFiles: Array<EncryptedFile> = []

    if (files.value.length > 0 && withFiles.value) {
        console.log('Encrypting files')
        for await (const item of files.value) {
            const contentBuffer: ArrayBuffer = await readFileAsync(item);
            const content = crypto.arrayBufferToBase64(contentBuffer)

            if (content) {
                const encryptedFile = await encryptContent(content, props.currentUser, encryptedContent.shareKeys)
                const fileContent = await new Response(encryptedFile.content).blob()
                const file = new File([ fileContent ], item.name, { type: 'application/octet-stream' })

                encryptedFiles.push({
                    mime_type: item.type,
                    iv: encryptedFile.iv,
                    file,
                })
            }
        }
    }

    attachments.value = encryptedFiles.length > 0 && withFiles.value ? encryptedFiles : undefined,
    await nextTick()
    recrypting.value = false
    await mutate()
}

const onDone = () => {
    response.value = ''
    newIv.value = null
    encryptedResponse.value = null
    files.value = []
    attachments.value = []
    withFiles.value = false
    emit('responded')
}

const templateQueryResult = useQuery(templateQuery, templateQueryVariables, () => ({
        enabled: queryEnabled.value
    })
)

templateQueryResult.onResult((result) => {
    response.value = result?.data?.messageTemplate?.content ?? ''

    if (response.value === result?.data?.messageTemplate?.content)
    templateLoading.value = false
})

templateQueryResult.onError((error) => {
    console.error(error)
    toast.add({ severity: 'error', summary: 'Ein Fehler ist beim Laden der Antwortvorlage aufgetreten', detail: error, life: 3000 });
    templateLoading.value = false
})

const onTemplateChange = (templateId) => {
    if (response.value !== '') {
        confirmMessageReplacement.require({
            message: 'Sind Sie sicher, dass Sie die aktuelle Nachricht mit der ausgewählten Vorlage überschreiben möchten?',
            header: 'Aktuelle Nachricht überschreiben?',
            group: confirmDialogGroup.value,
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Ja',
            rejectLabel: 'Nein',
            accept: () => {
                fetchTemplate(templateId)
            },
            reject: () => {
                selectedTemplate.value = null
            }
        });
    } else {
        fetchTemplate(templateId)
    }
}

const onFilesChanged = (newFiles) => {
    files.value = newFiles
}

const fetchTemplate = (templateId) => {
    templateLoading.value = true
    queryEnabled.value = true
    templateQueryVariables.value = { id: templateId.value }
    templateQueryResult.refetch()
}

defineExpose(props)
</script>

<template>
    <div :class="{'p-4 bg-slate-50 rounded-lg border border-slate-200': !frameless}">
        <ApolloMutation
            :mutation="gql => gql`
                mutation updateReportAddMessage ($id: ID!, $content: String!, $iv: String!, $isInternal: Boolean!, $attachments: [FileInput]) {
                    updateReportAddMessage (input: { id: $id, messages: { create: { content: $content, iv: $iv, is_internal: $isInternal, attachments: $attachments }}}) {
                        id
                        identifier
                        status
                        messages {
                            count
                            items {
                                id
                                content
                                is_internal
                                is_first_message
                                from_submitter
                                iv
                                author { id full_name is_me }
                                created_at
                            }
                        }
                    }
                }
            `"
            :variables="{
                id: reportId,
                content: encryptedResponse,
                iv: newIv,
                isInternal,
                attachments: attachments
            }"
            @done="onDone"
        >
            <template v-slot="{ mutate, loading, error }">
                <ValidationMessage v-if="error" :response="error" />
                <form method="post" autocomplete="on" @submit.prevent="() => !v$.$invalid && !recrypting && beforeSubmit(mutate)">
                    <div v-if="header" class="flex justify-between items-center mb-3">
                        <h3 class="mb-0 text-base text-slate-600 font-medium">{{ headerTitle }}</h3>
                        <Button icon="pi pi-times" class="p-button-rounded p-button-sm p-button-text" aria-label="close" @click.prevent="() => emit('close')" />
                    </div>
                    <ApolloQuery :query="templatesQuery" fetch-policy="cache-and-network">
                        <template v-slot="{ result, loading }">
                            <h3 class="mb-3 text-base text-slate-600 font-small">Aus Antwortvorlage übernehmen (optional)</h3>
                            <Dropdown v-model="selectedTemplate" :loading="loading" placeholder="Antwortvorlage auswählen..."
                                :options="result?.data?.messageTemplates ?? []" optionLabel="name" optionValue="id" :filter="true"
                                style="width: 100%" class="mb-3" @change="onTemplateChange"/>
                        </template>
                    </ApolloQuery>
                    <div class="relative">
                        <h3 class="mb-3 text-base text-slate-600 font-small">Nachricht</h3>
                        <Textarea v-model="response" auto-resize class="w-full min-h-[96px] max-w-3xl mt-2" :disabled="templateLoading"/>
                        <i v-if="templateLoading" class="pi pi-spin pi-spinner absolute left-1/2 top-1/2 text-primary-500"
                            style="font-size: 2rem"></i>
                    </div>
                    <div class="mt-4 flex items-center">
                        <div class="mr-auto">
                            <span
                                v-if="allowInternal"
                                class="flex items-center">
                                <InputSwitch v-model="isInternal" id="internalMessage" class="mr-2" :disabled="setInternal"/>
                                <label
                                    for="internalMessage"
                                    class="text-gray-600 font-medium inline-flex items-center">
                                    Interne Notiz
                                    <span class="text-primary-600" v-tooltip.top="'Hinweisgeber können interne Notizen nicht sehen, nur Sie und Ombudspersonen in diesem Portal.'">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="ml-2 w-5 h-5">
                                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM8.94 6.94a.75.75 0 11-1.061-1.061 3 3 0 112.871 5.026v.345a.75.75 0 01-1.5 0v-.5c0-.72.57-1.172 1.081-1.287A1.5 1.5 0 108.94 6.94zM10 15a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                                        </svg>
                                    </span>
                                </label>
                            </span>
                        </div>
                        <Button
                            :disabled="v$.$invalid || recrypting || loading"
                            :loading="recrypting || loading"
                            type="submit"
                            icon="pi pi-send"
                            label="verschlüsselt antworten" />
                    </div>
                    <FileUpload v-if="withFiles" @changed="onFilesChanged" class="mb-12" />
                    <div class="mr-auto">
                            <span
                                class="flex items-center">
                                <InputSwitch v-model="withFiles" id="withFiles" class="mr-2" />
                                <label
                                    for="withFiles"
                                    class="text-gray-600 font-medium inline-flex items-center">
                                    {{ $t("components.messages.form.fileAttachLabel") }}
                                </label>
                            </span>
                        </div>
                </form>
            </template>
        </ApolloMutation>
        <ConfirmDialog :group="confirmDialogGroup"/>
        <Toast />
    </div>
</template>
