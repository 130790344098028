<script setup lang="ts">
import { ref } from 'vue'
import { onClickOutside } from '@vueuse/core'

import AppLogout from '@/containers/Auth/AppLogout.vue'
import AppConfig from '../config'

const active = ref(false)
const target = ref(null)

onClickOutside(target, (event) => active.value = false)
</script>

<template>
    <div class="relative" ref="target">
        <button
            @click.prevent="active = !active"
            :class="{
                'relative z-10 flex items-center justify-center xl:hidden md:h-14 md:w-14 text-gray-200 bg-white/10 p-2 rounded hover:bg-white/20 active:bg-white/10': true,
                'bg-white/100 text-gray-700 rounded-bl-none rounded-br-none md:rounded-bl md:rounded-br md:rounded-tr-none md:rounded-tl-none hover:bg-white/90 active:bg-white/80': active
            }">
            <svg v-if="active" xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6 xl:h-5 xl:w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 md:h-6 md:w-6 xl:h-5 xl:w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
        </button>
        <div
            v-show="active"
            class="z-20 xl:!flex absolute text-sm w-72 right-0 top-9 rounded-tr-none md:rounded-tr-lg md:rounded-bl-none xl:rounded-bl-lg md:text-base md:right-auto md:top-auto md:left-0 md:bottom-14 shadow-2xl xl:w-auto xl:static xl:shadow-none p-4 bg-white rounded-lg text-black justify-start items-center">
            <div class="w-full">
                <div class="font-semibold mb-2">
                    {{ $appConfig.currentUserName }}
                </div>
                <nav>
                    <ul>
                        <li>
                            <router-link
                                to="/user-settings"
                                class="text-gray-700 flex items-center p-2 rounded hover:bg-black/5 active:bg-black/10"
                                active-class="bg-black/10 hover:bg-black/15 active:bg-black/10">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4" />
                                </svg>
                                Persönliche Einstellungen
                            </router-link>
                        </li>
                        <li>
                            <a
                                href="https://nextindex.atlassian.net/servicedesk/customer/portal/6/group/-1"
                                target="_blank"
                                class="text-gray-700 flex items-center p-2 rounded hover:bg-black/5 active:bg-black/10"
                                active-class="bg-black/10 hover:bg-black/15 active:bg-black/10">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M16.712 4.33a9.027 9.027 0 011.652 1.306c.51.51.944 1.064 1.306 1.652M16.712 4.33l-3.448 4.138m3.448-4.138a9.014 9.014 0 00-9.424 0M19.67 7.288l-4.138 3.448m4.138-3.448a9.014 9.014 0 010 9.424m-4.138-5.976a3.736 3.736 0 00-.88-1.388 3.737 3.737 0 00-1.388-.88m2.268 2.268a3.765 3.765 0 010 2.528m-2.268-4.796a3.765 3.765 0 00-2.528 0m4.796 4.796c-.181.506-.475.982-.88 1.388a3.736 3.736 0 01-1.388.88m2.268-2.268l4.138 3.448m0 0a9.027 9.027 0 01-1.306 1.652c-.51.51-1.064.944-1.652 1.306m0 0l-3.448-4.138m3.448 4.138a9.014 9.014 0 01-9.424 0m5.976-4.138a3.765 3.765 0 01-2.528 0m0 0a3.736 3.736 0 01-1.388-.88 3.737 3.737 0 01-.88-1.388m2.268 2.268L7.288 19.67m0 0a9.024 9.024 0 01-1.652-1.306 9.027 9.027 0 01-1.306-1.652m0 0l4.138-3.448M4.33 16.712a9.014 9.014 0 010-9.424m4.138 5.976a3.765 3.765 0 010-2.528m0 0c.181-.506.475-.982.88-1.388a3.736 3.736 0 011.388-.88m-2.268 2.268L4.33 7.288m6.406 1.18L7.288 4.33m0 0a9.024 9.024 0 00-1.652 1.306A9.025 9.025 0 004.33 7.288" />
                                </svg>
                                Feedback & Support
                            </a>
                        </li>
                        <li>
                            <a
                                href="https://compentum.de/docs"
                                target="_blank"
                                class="text-gray-700 flex items-center p-2 rounded hover:bg-black/5 active:bg-black/10"
                                active-class="bg-black/10 hover:bg-black/15 active:bg-black/10">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5  mr-2">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z" />
                                </svg>
                                Hilfe
                            </a>
                        </li>
                        <li>
                            <AppLogout class="block" />
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    </div>
</template>
