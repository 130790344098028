import gql from 'graphql-tag'

export const REPORT_QUERY = gql`
    query reports(
        $status: [ReportStatus!]!
        $page: Int!
        $first: Int!
        $portal_id: ID
        $search: String
        $hasCategory: QueryReportsHasCategoryWhereHasConditions
        $created_at: DateRange
        $sortField: QueryReportsOrderByColumn!
        $sortOrder: SortOrder!
    ) {
        portals(
            getAll: false
        ) {
            id
            name
        }
        categories(first: 999, with_hidden: true) {
            data {
                id
                name
            }
        }
        reports(
            status: $status
            page: $page
            first: $first
            portal_id: $portal_id
            search: $search
            created_at: $created_at
            hasCategory: $hasCategory
            orderBy: { column: $sortField, order: $sortOrder }
        ) {
            paginatorInfo {
                count
                currentPage
                hasMorePages
                total
            }
            data {
                id
                identifier
                submit_type
                created_at
                internal
                deadline {
                    date
                    remaining_days
                }
                completed_at {
                    date
                    processing_time
                }
                status
                messages {
                    count
                }
                portal {
                    id
                    name
                }
                category {
                    id
                    name
                }
            }
        }
    }
`
