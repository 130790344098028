<script setup lang="ts">
import { ref, defineProps, defineEmits, watch, onMounted, onBeforeUnmount } from 'vue'
import Dialog from 'primevue/dialog'
import Button from 'primevue/button'

import RecryptAllReports from '@/containers/Recrypt/RecryptAllReports.vue'

const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue'])

const visible = ref(props.modelValue)
const dismissable = ref(true)
const confirmExit = () => 'You have attempted to leave this page. Are you sure?'

watch(() => props.modelValue, (val) => visible.value = val)
watch(visible, (val) => emit('update:modelValue', val))
watch(dismissable, (val) => val ? (window.onbeforeunload = null) : null)

onMounted(() => {
    window.onbeforeunload = confirmExit
})

onBeforeUnmount(() => {
    window.onbeforeunload = null
})

const onFinishedRecryptAllReports = (): void => {
    dismissable.value = true
}
</script>

<template>
    <Dialog
        v-model:visible="visible"
        :header="`Neuverschlüsselung der Meldungen`"
        position="top"
        modal
        :closable="dismissable"
        :dismissable-mask="dismissable"
        :close-on-escape="dismissable"
        :draggable="false"
        :style="{ width: '512px' }">
        <RecryptAllReports
            @processing="() => dismissable = false"
            @finished="() => onFinishedRecryptAllReports()" />
        <div v-if="dismissable" class="mt-8 flex justify-end">
            <Button label="Schließen" severity="secondary" class="p-button-outlined" @click.prevent="() => visible = false" />
        </div>
    </Dialog>
</template>
