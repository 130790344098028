<script setup lang="ts">
import type { ComputedRef } from 'vue'
import type { Message as MessageType } from '@/containers/Message/message'

import { computed } from 'vue'
import { useRoute } from 'vue-router'
import gql from 'graphql-tag'
import { useQuery } from "@vue/apollo-composable"
import Skeleton from 'primevue/skeleton'
import Message from 'primevue/message'

import DecryptReport from './DecryptReport.vue'

const route = useRoute()
const reportQuery = gql`
    query report($id: ID!) {
        report (id: $id) {
            id
            identifier
            internal
            submit_type
            status
            reporting_channel
            internalSubmitter {
                full_name
                is_trashed
            }
            messages {
                count
                items {
                    id
                    content
                    is_internal
                    is_first_message
                    from_submitter
                    iv
                    author { id full_name is_me is_trashed }
                    attachments {
                        id
                        filename
                        original_filename
                        download_url
                        mime_type
                        iv
                        is_voice_message
                    }
                    created_at
                }
            }
            report_iv
            contact_information
            share_keys
            submitter_email
            submitter_iv
            deadline {
                date
                remaining_days
            }
            completed_at {
                date
                processing_time
            }
            category {
                id
                name
            }
            portal {
                id
                name
            }
            activities {
                date
                description
            }
            created_at
            updated_at
        }
    }
`

const publicMessages: ComputedRef<Array<MessageType>> = computed(
    () => result.value?.report?.messages.items.slice().reverse().filter(i => !i.is_internal)
)

const internalMessages: ComputedRef<Array<MessageType>> = computed(
    () => result.value?.report?.messages.items.slice().reverse().filter(i => i.is_internal)
)

const reversedMessages: ComputedRef<Array<MessageType>> = computed(
    () => result.value?.report?.messages.items.slice().reverse()
)


const { result, loading, error, refetch: refetchReport } = useQuery(reportQuery, {
    id: route.params.id
})
</script>

<template>
    <Message v-if="error && !loading" severity="warn" :closable="false">
        {{ error }}
    </Message>
    <DecryptReport
        v-else-if="result && !loading"
        :encrypted-report="result.report">
        <template v-slot="{ shareKeys, currentUser }">
            <slot
                :result="result"
                :messages="result.value?.report?.messages.items"
                :public-messages="publicMessages"
                :internal-messages="internalMessages"
                :mixed-messages="reversedMessages"
                :share-keys="shareKeys"
                :current-user="currentUser"
                :refetch-report="refetchReport" />
        </template>
    </DecryptReport>
    <div v-else>
        <Skeleton class="mb-2" />
        <Skeleton width="10rem" class="mb-2" />
        <Skeleton width="5rem" class="mb-2" />
        <Skeleton height="2rem" class="mb-2" />
        <Skeleton width="10rem" height="4rem" />
    </div>
</template>
